<!-- --------------------------------------------------------------------------------
                      LoginLogo1.vue

DESCRIPTIONS
  - In login page, display AED and software name info
  - 

---------------------------------------------------------------------------------- -->
<template>
  <v-card width="500px" height="100px" class="d-flex flex-row transparent border-0 elevation-0" >

      <v-card width="15%" class="mt-2 transparent border-0 elevation-0">
        <v-img :src="AedIcon"></v-img>
      </v-card>

      <v-card class="transparent border-0 elevation-0">
        <!-- <v-sheet class="ml-4 text-h6 green--text transparent"> </v-sheet>  -->
        <v-sheet class="ml-4 mt-3 text-h4 transparent text-white-border fontArialB" :class="colorTitle1">{{title1}}™{{ version }}</v-sheet>
        <v-sheet class="ml-4 text-body1 transparent fontArialB" :class="colorTitle2">{{ title2 }}</v-sheet>
      </v-card>
  </v-card>
</template>

<script>
import * as Ut from '@/js/ut.js'

  export default {
    name: 'LoginLogo1',

    data () {
      return {
        title1: Ut.SysPara.loginTitle1,
        title2: Ut.SysPara.loginTitle2,
        colorTitle1: Ut.SysPara.loginTitleColor1,
        colorTitle2: Ut.SysPara.loginTitleColor2,
        version: Ut.SysPara.loginVer,
        AedIcon: require("@/assets/Images/Background/AED symbol.jpg")
      }
    },


  }
</script>

<style>
.text-white-border{
    -webkit-text-stroke: 0.5px rgb(139, 87, 37);

}

.fontArialN {
  font-family: Arial; 
  font-style: normal; 
  font-weight: normal;   
  color: black;
}

.fontArialB {
  font-family: Arial; 
  font-style: normal; 
  font-weight: bold;   
  color: black;
}


</style>
