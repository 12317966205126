<!-- --------------------------------------------------------------------------------
                      DevTables.vue

DESCRIPTIONS
  - Table component, called from DevInfoDetail.vue
  - filter away hidden components by creating a new array for the table

Parameters:
  - tblHeaders (array), tblItems (array)
---------------------------------------------------------------------------------- -->
<template>
  <div id="idCard3" class="pt-0" :height="tblHeight" >

    <!------------------------- Table ------------------------------->
    <!-- Table https://v2.vuetifyjs.com/en/components/data-tables/ -->
    <v-card width="auto" fixed-header class="mx-0 pa-0" color="transparent"> 
      <!-- <v-data-table :headers="tblHeaders" :items="tblItems" 
          :item-class="filterItems">
        <template v-slot:[`item.devSn`]="{ item }">
          <v-btn color="blue--text transparent" height="100%" class="pa-0 elevation-0" @click="onClickDevSn(item)">{{ item.devSn }}</v-btn>
        </template>
      </v-data-table>  -->

      <!-- <v-data-table :headers="tblHeaders" :items="tblItems" 
          :item-class="filterItems" :height="tblHeight" :fixed-header="true">
      </v-data-table>   -->

      <v-data-table :height="tblHeight" :fixed-header="true" :headers="tblHeaders" :items="vItems"
      :footer-props="{ 'items-per-page-options': [30, 50, 100, -1]}" sort-by="receiveTime" :sort-desc="true">
      </v-data-table>

    </v-card>

  </div>
</template>

<script>
// import * as Samples from "@/js/samples/data.js"
// import * as Tbl from "@/assets/tables.js"
import moment from 'moment';
import * as Ut from '@/js/ut.js';

  export default {
    name: 'DevTables',

    props: {
      tblHeaders: {type: Array},
      tblItems: {type: Array},
      tblHeight: {type: String},
      ppSn: {type: String, default: ''},
      ppDate1: {type: String, default: ''},
      ppDate2: {type: String, default: ''},
      ppType: {type: String, default: ''},
      trigger2: {type: Number, default: 0}
    },

    data () {
      return {
        vItems: [],
        strSearch: '',
        lastStatus: false,
        strDummy: 'X1Y2Z3!!@',
      }
    },

    methods: {

      // DESCRIPTION:  filter datetime in table
      custFilter (value, search, item) {
        // console.log("##", value, item);           //debug*

        return true;
      },

      // DESCRIPTION:  
      /* filterItems (xobj) {
        let vDate2 = "", vDate1 = "";
        if (this.ppDate2 !== undefined && this.ppDate2 !== "" && this.ppDate1 !== undefined && this.ppDate1 !== "") {
          //vDate2 = this.ppDate2 + " 23:59:59";
          //vDate1 = this.ppDate1 + " 00:00:00";
          vDate2 = this.ppDate2;
          vDate1 = this.ppDate1;
        }
              
        // (2) Hide if Sn not matched
        if (this.ppSn !== "" && xobj.devSn.indexOf(this.ppSn) < 0) return "d-none";  

        // (3) Hide if date range not matched
        if (vDate2 !== "") {
          if (Ut.compareDates(xobj.receiveTime, vDate2) < 0) return "d-none";
        }
        if (vDate1 !== "") {
          if (Ut.compareDates(vDate1, xobj.receiveTime) < 0) return "d-none";
        } 
        return "";
      }, */ 

      // DESCRIPTION:  chk if string is empty (or default value 'All')
      isFieldEmpty (xstr) {
        let str = xstr.toString();
        if (str === "" || str === "All") return true;
        return false;
      },

      // DESCRIPTION:  reload table and filter data (within time range)
      refreshTable() {
        if (this.tblItems === undefined) return;
        if (this.vItems !== undefined && this.vItems.length > 0) Ut.clearArray(this.vItems);

        let vDate2 = "", vDate1 = "";
        if (this.ppDate2 !== undefined && this.ppDate2 !== "" && this.ppDate1 !== undefined && this.ppDate1 !== "") {
          //vDate2 = this.ppDate2 + " 23:59:59";
          //vDate1 = this.ppDate1 + " 00:00:00";
          vDate2 = this.ppDate2;
          vDate1 = this.ppDate1;
        }
        for(let idx=0; idx < this.tblItems.length; idx++) {
          let vObj = this.tblItems[idx];           

          if (vObj.hidden) {
            //console.log(vObj.devSn, vObj.errorCode, vObj.offlineDays, vObj.hidden);    //debug*
            continue;
          }
          if (this.ppSn !== "" && vObj.devSn.indexOf(this.ppSn) < 0) continue;  

          //(5) filter time range
          let vRxTime = vObj.receiveTime.substring(0, 10);
          if (vDate2 !== "") {
            //if (Ut.compareDates(vObj.receiveTime, vDate2) = 0) continue;
            if (moment(new Date(vDate2)).diff(moment(new Date(vRxTime)), 'days') < 0) continue;
          }
          if (vDate1 !== "") {
            //if (Ut.compareDates(vDate1, vObj.receiveTime) < 0) continue;
            if (moment(new Date(vRxTime)).diff(moment(new Date(vDate1)), 'days') < 0) continue;
          }            
          this.vItems.push(vObj);
        }

      }
      //end of refreshTable()

    },

    // DESCRIPTION:  when refresh button is pressed
    watch: {
      trigger2: function(newVal) {
        this.refreshTable();
      }
    },

    // DESCRIPTION:  activate when program starts, or Device page is selected
    mounted () {
      this.refreshTable();
    }
    

  }
</script>

<style scoped>
.cBorder {
  border: 1px solid grey;
  border-radius: 100;
  background-color: #E3F2FD;
  margin-left: 2px;
  padding-left: 2px;
}

.v-data-table >>> .v-data-table-header {
  background-color: #FF8F00 !important;
  font-family: Arial !important; 
}

>>> table td {
  font-family: Arial !important; 
  color: black;
}


.some-other-style {
  background: blue;
}
.tbl-header1 {
  background-color: #FF8F00;
  color: white !important;
  font-size: 0.9em !important;
}

.tbl-header2 {
  border-top-left-radius: 10px;
}

.tbl-header3 {
  border-top-right-radius: 10px;
}

#idCard3 {
  margin-top: 0;
  margin-left: 0;
  margin-right: 0;
  padding: 0;
  /* overflow: hidden; */
  background-color: #37474F;
  /* overflow-y: scroll; */
  /* -ms-overflow-style: none; 
  scrollbar-width: none;   */
}
/*
#idCard3::-webkit-scrollbar {
    display: none;
} */

.fontArialN {
  font-family: Arial; 
  font-style: normal; 
  font-weight: normal;   
  color: black;
}

.fontArialB {
  font-family: Arial; 
  font-style: normal; 
  font-weight: bold;   
  color: black;
}

</style>
