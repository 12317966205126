<!-- --------------------------------------------------------------------------------
                      DialogKeyValue.vue

DESCRIPTIONS
  - Display Messages:  error message, warning message, ...

USAGE:
  <DialogKeyValue :message="sMessage" :bActivate="bAct" @DialogClose="onMsgClose"/>
  open:    bAct = true
  close:   at onMsgClose() { bAct=false }
---------------------------------------------------------------------------------- -->
<template>
  <div class="text-center">
    <v-dialog v-model="bDialog" width="500px" height="540px" color="transparent">
      <div width="98%" height="500px" class="transparent">

        <v-card width="99%" height="40px" class="d-flex flex-row ma-0 py-auto rounded-t-lg rounded-b-0 transparent">
          <!-- <v-sheet width="100%" height="100%" class="my-auto text-center text-h6 grey lighten-2">  {{title}}</v-sheet>  -->
          <v-sheet width="100%" height="100%" class="my-auto text-center text-h6 white--text fontArialB" 
            style="background-color: #546E7A;">{{ title }}</v-sheet>
          <v-btn icon small color="white" class="mt-1 fontArialB" @click="onBtnClose" style="position: absolute; right: 0;">
            <svg-icon type="mdi" :path="pathClose"></svg-icon></v-btn>
        </v-card>

        <v-divider></v-divider>

        <v-card width="99%" height="100%" class="ml-0 mt-0 py-1 border-0 elevation-0 rounded-t-0 roundded-b-lg">
          <v-card class="pa-0 ma-1 elevation-0" height="23px" v-for="data, i in Object.entries(messages)" :key="i">
            <v-row class="pl-2 py-auto ma-0 d-flex black--text fontArialN" style="border-bottom: 1px solid #E0E0E0;">
              <v-col cols="5" class="pa-0 ma-0 fontArialB">{{ data[0] }}</v-col>
              <v-col cols="7" class="pa-0 ma-0 fontArialN">{{ data[1] }}</v-col>
            </v-row>
          </v-card>
        </v-card>

      </div>
    </v-dialog>
  </div>
</template>

<script>
import SvgIcon from '@jamescoyle/vue-icon';
import { mdiClose } from '@mdi/js';

  export default {
    name: 'DialogKeyValue',

    components: {
      SvgIcon
    },

    props: {
      title: {type: String, default: ''},
      messages: {type: Object, default: null},
      pAct: {type: Number, default: 0}
    },

    data () {
      return {
        bDialog: false,
        pathClose: mdiClose
      }
    },

    methods: {
      onBtnClose () {
        // console.log('DialogKeyValue','onBtnClose');
        this.$emit('DialogKeyValueClose');
        this.bDialog = false;
      },

      onClickOutside () {
        this.onBtnClose();
      }
    },

    watch: {
      pAct (newVal) {
        this.bDialog = true; 
      },

      bDialog: function(newVal) {
        if (!newVal) {
          this.$emit('DialogKeyValueClose');
        }
      }
    }

  }
</script>

<style scoped>


.fontArialN {
  font-family: Arial; 
  font-style: normal; 
  font-weight: normal;   
  color: black;
}

.fontArialB {
  font-family: Arial; 
  font-style: normal; 
  font-weight: bold;   
  color: black;
}

.cBorder {
  border: 1px solid grey;
}
</style>
