<!-- --------------------------------------------------------------------------------
                      dialogDevOptions.vue (OBSELETED)

DESCRIPTIONS
  - set table column options
---------------------------------------------------------------------------------- -->
<template>
  <div class="text-center">

      <v-dialog v-model="bDialog" content-class="my-custom-dialog" width="900px" height="780px">
        <template  v-slot:activator="{ on, attrs }">
          <!-- <v-btn width="30px" color="transparent" v-bind="attrs" v-on="on" @click="btnOnDialog">
            <v-app-bar-nav-icon color="#37474F"></v-app-bar-nav-icon>
          </v-btn>  -->
          <v-btn width="110px" height="22px" color="#37474F" :class="btnClass" v-bind="attrs" v-on="on" @click="btnOnDialog">Add Device</v-btn>
        </template>

        <v-card width="100%" height="100%" class="rounded-lg">
          <!------------------------------- Header ------------------------------------>
          <v-card height="40px" style="background-color: #546E7A;">
            <v-layout justify-center class="text-h6 white--text font-weight-bold">Add Device</v-layout>
          </v-card>
          <v-divider></v-divider>

          <!------------------------------- Content ----------------------------------->
          <v-card width="100%" class="d-flex flex-row border-0 elevation-0 rounded-0">
            <!---------------- Left ----------------------->
            <v-card width="50%" height="100%" class="mt-1 border-0 elevation-0">
              <v-card width="95%" height="40px" :class="cardClass1">
                <inputBox2 label="Device SN: *" message="Enter" width1="30%" width2="70%" id="1" @msgInputBox1="onInputBox1" capital="true"/>
              </v-card>

              <v-card width="95%" height="40px" :class="cardClass1">
                <inputBox2 label="Device Model:" message="Enter" width1="30%" width2="70%" id="2" @msgInputBox1="onInputBox1"/>
              </v-card>

              <v-card width="95%" height="40px" :class="cardClass1">
                <inputBox2 label="Inst. Time:" message="Enter" width1="30%" width2="70%" id="3" @msgInputBox1="onInputBox1"/>
              </v-card>              

              <v-card width="95%" height="40px" :class="cardClass1">
                <inputBox2 label="Customer Name:" message="Enter" width1="30%" width2="70%" id="4" @msgInputBox1="onInputBox1"/>
              </v-card>

              <v-card width="95%" height="40px" :class="cardClass1">
                <inputBox2 label="Expiry Date: *" message="Enter" width1="30%" width2="70%" id="13" @msgInputBox1="onInputBox1"/>
              </v-card>  
            </v-card>

            <!---------------- Right ----------------------->
            <v-card width="49%" height="100%" class="mt-1 border-0 elevation-0">
              <v-card width="95%" height="40px" :class="cardClass1">
                <inputBox2 label="Asset SN:" message="Enter" width1="30%" width2="70%" id="5" @msgInputBox1="onInputBox1"/>
              </v-card>

              <v-card width="95%" height="40px" :class="cardClass1">
                <inputBox2 label="Networking:" message="Enter" width1="30%" width2="70%" id="6" @msgInputBox1="onInputBox1"/>
              </v-card>

              <v-card width="95%" height="40px" :class="cardClass1">
                <inputBox2 label="Asset Owner:" message="Enter" width1="30%" width2="70%" id="7" @msgInputBox1="onInputBox1"/>
              </v-card>              

              <v-card width="95%" height="40px" :class="cardClass1">
                <inputBox2 label="Device Admin:" message="Enter" width1="30%" width2="70%" id="8" @msgInputBox1="onInputBox1"/>
              </v-card>         
            </v-card>
          </v-card>
          <!---------------- Bottom ----------------------->
          <v-card class="ml-2 mt-5 border-0 elevation-0">
            <v-card width="97%" height="40px" :class="cardClass1">
              <inputBox2 label="Notes:" message="Enter" width1="20%" width2="75%" id="9" @msgInputBox1="onInputBox1"/>
            </v-card>

            <v-card width="97%" height="40px" :class="cardClass1">
              <inputBox2 label="Live Photo:" message="Enter" width1="20%" width2="75%" id="10" @msgInputBox1="onInputBox1"/>
            </v-card>            

            <v-card width="97%" height="40px" :class="cardClass1">
              <inputBox2 label="Installation location:" message="Enter" width1="20%" width2="75%" id="11" @msgInputBox1="onInputBox1"/>
            </v-card> 
  
            <v-card width="97%" height="40px" :class="cardClass1">
              <inputBox2 label="Detailed Address:" message="Enter" width1="20%" width2="75%" id="12" @msgInputBox1="onInputBox1"/>
            </v-card> 
          </v-card>          

          <!--------------------------- Exit Buttons ---------------------------------->
          <v-layout justify-center class="border-0 elevation-0">
          <v-card-actions>
            <v-btn small color="primary" width="80px" @click="onBtnSave">Save</v-btn>
            <v-btn small color="primary" width="80px" @click="bDialog = false">Cancel</v-btn>
          </v-card-actions>
          </v-layout>

        </v-card>
      </v-dialog>

      <DialogMessage :message="sMessage" :pAct="bAct" @DialogMessageClose="onMsgClose"/>

  </div>
</template>

<script>
import inputBox2 from '@/components/Common/InputBox2'
import DialogMessage from '@/components/Common/DialogMessage'
// import * as Sql from '@/js/sql.js'

  export default {
    data () {
      return {
        bDialog: false,
        btnClass: 'mt-1 ml-1 white--text text-capitalize',
        cardClass1: 'ml-1 mt-1 white border-0 elevation-0',

        // to avoid error, needed: warrantyexpire
        // DevObj: Sql.createDevObj(),
        DevObj: {
          "sn": "",
          "model": "",
          "insttime": "",
          "instloc": "",
          "customer": "",
          "customerid": 1,
          "admin": "",
          "devstatus": "",
          "testperiod": 1,
          "reportperiod": 1,
          "warranty": "",
          // "warrantyexpire": "2024-08-01 00:00:00",
          "warrantyexpire": "",
          "address": "",
          "lastloc": "",
          "locattr": "",
          "networktype": "",
          "assetsn": "",
          "iccid": "",
          "padstatus": 1,
          "battstatus": 0,
          "user": "",
          "engineer": "",
          "imgUrl": ""
        },

        sMessage: 'ABC',
        bAct: false
      }
    },

    components: {
      inputBox2, DialogMessage
    },

    methods: {
      // DESCRIPTION:  first function to call when entering this component
      btnOnDialog () {

      },

      onInputBox1 (xobj) {
        switch(xobj.id) {
          case '1'  :  this.DevObj.sn = xobj.text; break;
          case '2'  :  this.DevObj.model = xobj.text; break;
          case '3'  :  this.DevObj.insttime = xobj.text; break;
          case '4'  :  this.DevObj.customer = xobj.text; break;
          case '5'  :  this.DevObj.assetsn = xobj.text; break;
          case '6'  :  this.DevObj.networktype = xobj.text; break;
          case '7'  :  this.DevObj.user = xobj.text; break;
          case '8'  :  this.DevObj.admin = xobj.text; break;
          case '9'  :  this.DevObj.Notes = xobj.text; break;
          case '10'  :  this.DevObj.imgUrl = xobj.text; break;
          case '11'  :  this.DevObj.instloc = xobj.text; break;
          case '12'  :  this.DevObj.address = xobj.text; break;
          case '13'  :  this.DevObj.warrantyexpire = xobj.text; break;
          default: break;
        }  
      },

      onMsgClose () {
        this.bAct = false;
        // console.log('onMsgClose');
      },

      onBtnSave () {
        // Validity check before saving
        if (this.DevObj.sn.trim() === '') {
          this.sMessage = "Have not entered Device SN";
          this.bAct = true;
        } else if (this.DevObj.warrantyexpire.trim() === '') {
          this.sMessage = "Have not entered Warranty expiry date";
          this.bAct = true;
        } else if  (this.DevObj.insttime.trim() === '') {
          this.sMessage = "Have not entered Installation Time";
          this.bAct = true;
        } else {
          // save & exit
          this.bAct = false;
          this.$emit('dialogDevAdd', this.DevObj);
          this.bDialog = false;
        }
      }

    }
  }
</script>

<style scoped>


.cBorder {
  border: 1px solid grey;
}
</style>
