<template>
  <v-card id="idCard1" width="100%" min-width="900px" class="ma-0 pa-0">

   <!------------------------- 1st row:  Search filter ------------------>
    <v-card width="auto" :height="hExpand" class="mx-1 mt-1 rounded" color="transparent">
      <!-- row: w=auto h=47px / card w=305px h=40px -->
      <v-card width="auto" height="52px" class="mx-0 d-flex flex-row rounded-r-0 rounded-l-0">
        <v-card width="30%" height="40px" class="ml-1 mt-1 transparent border-0">
          <inputBox1 label="Device SN" message="Enter" :capitalize="true" :init="vRstInput" @msgInputBox1="onInputBox1" id="devsn"/>
        </v-card>

        <v-card width="30%" height="40px" class="ml-2 mt-1 transparent border-0">
          <inputBox1 label="Customer" message="Enter" :init="vRstInput" @msgInputBox1="onInputBox1" id="cust"/>
        </v-card>

        <v-card width="30%" height="40px" class="ml-2 mt-1 transparent border-0">
          <comboBox1 label="Dev Status" :items="aryDevStatus" data="All" @msgComboBox1="onComboBox1" 
          id="devst" :reset="resetDevice"/>
        </v-card>

        <!-- <v-btn icon color="#37474F" class="mt-2 mr-0 elevation-0 " @click="onBtnResetFilter">
          <svg-icon type="mdi" :path="pathRefreshCircle" size="32"></svg-icon>
        </v-btn> -->
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon color="#37474F" class="mt-2 mr-0 elevation-0" v-bind="attrs" v-on="on" @click="onBtnResetFilter">
              <svg-icon type="mdi" :path="pathRefreshCircle" size="32"></svg-icon>
            </v-btn>
          </template>
          <span class="yellow--text">Reset Search Options</span>
        </v-tooltip>

        <!-- mdi-chevron-down, mdi-chevron-up -->
        <!-- <v-icon color="white"> {{ `${vIcon}` }}</v-icon>  -->
        <!-- <v-btn icon color="#37474F" class="mt-2 mr-1 ml-0 elevation-0 cAlignRight" @click="onBtnExpand">          
          <svg-icon type="mdi" :path="pathChevronDown" size="32"></svg-icon>
        </v-btn>  -->

        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon color="#37474F" class="mt-2 mr-1 ml-0 elevation-0" v-bind="attrs" v-on="on" @click="onBtnExpand">
              <svg-icon type="mdi" :path="pathChevronDown" size="32"></svg-icon>
            </v-btn>
          </template>
          <span class="yellow--text">More Search Options</span>
        </v-tooltip>
      </v-card>

      <v-card width="auto" :height="vSeperator1" color="transparent" class="mx-1 pa-0 rounded-r-0 rounded-l-0">
      </v-card>

      <v-card width="auto" height="52px" class="mx-0 mt-n1 d-flex flex-row rounded-r-0 rounded-l-0">        
        <v-card width="30%" height="40px" class="ml-1 mt-1 white">
          <inputBox1 label="Location" message="Enter" :init="vRstInput" @msgInputBox1="onInputBox1" id="loc"/>
        </v-card>

        <v-card width="30%" height="40px" class="ml-2 mt-1 white">
          <comboBox1 label="Batt Status" :items="aryBattStatus" message="Enter" data="All" 
          @msgComboBox1="onComboBox1" id="battst" :reset="resetBatt"/>
        </v-card>

        <v-card width="30%" height="40px" class="ml-2 mt-1 white">
          <comboBox1 label="Pads Status" :items="aryPadsStatus" data="All" 
          @msgComboBox1="onComboBox1" id="padst" :reset="resetPads"/>
        </v-card>
      </v-card>

      <v-card width="auto" height="52px" class="mx-0 mt-n1 d-flex flex-row rounded-r-0 rounded-l-0">        
        <!-- <v-card width="30%" height="40px" class="ml-1 mt-1 white">
          <inputBox1 label="Inst. Time" message="Enter" :init="vRstInput" @msgInputBox1="onInputBox1" id="insttime"/>
        </v-card>  -->

        <v-card width="30%" height="40px" class="ml-1 mt-2 white">
          <comboBox1 label="Models" :items="aryModels" message="Enter" data="All" 
          @msgComboBox1="onComboBox1" id="model" :reset="resetModel"/>
        </v-card>

        <v-card width="200px" height="40px" class="ml-2 mt-2 px-1 blue-grey lighten-5 rounded-lg elevation-0 border-0 text-h4">
          <MonthPicker class="ma-0 pa-0" :pReset="resetMonth" pTitle="Select a Month"
          @MonthPick="onMonthChange"/>
        </v-card>  

      </v-card>

    </v-card>
    <!---------------------- 2nd Row Search Filter ------------------------>
      
    <!---------------------- Button Bar --------------------------->
    <v-card width="100%"  class="mt-1 ml-0" color="#37474F">
      <v-card width="99%" height="30px" class="ma-1 d-flex flex-row" style="background-color: #FF8F00;">
        <!-- <v-btn width="110px" height="22px" color="#37474F" class="mt-1 ml-1 white--text text-capitalize" @click="onBtnSearch"><v-icon>mdi-magnify</v-icon>Search</v-btn> -->
        <v-spacer></v-spacer>
        <v-progress-circular v-if="showProgress" color="blue" :size="30" indeterminate class="mt-0 mr-1"></v-progress-circular>

        <!-- Add Device Button -->
        <!-- <dialogDevAdd activator="parent" class="ml-5" @dialogDevAdd="onBtnAddDevice"/>  -->
        
        <!-- <v-btn width="110px" height="22px" color="#37474F" class="mt-1 ml-1 white--text text-capitalize" @click="onBtnExport">Export</v-btn> -->

        <!-- (1) Excel export button -->
        <ToExcel :tbl="aeditems2" :selectedSn="selected" />

        <!-- (2) Send Device Info -->
        <!-- <dialogDevSendStatus activator="parent" :aryDev="selected" /> -->

        <!-- (3) Option button -->
        <dialogOptions activator="parent" class="ml-5 mt-n1" @dialogEmit="onEmitDialogOptions"/>
      </v-card>

    <!------------------------- Table ------------------------------->
    <!-- Table https://v2.vuetifyjs.com/en/components/data-tables/ -->
    <v-card  width="99%" fixed-header class="mt-1 ml-1 pa-0" color="transparent"> 

      <!------ selected rows(obj) are stored in selected[] ------->
      <!-- <v-data-table dense v-model="selected" :headers="headers" :items="aeditems2" :single-select="singleSelect"
        item-key="devSn" show-select :item-class="filterItems" color="transparent" height="100%" fixed-header> -->

      <v-data-table dense v-model="selected" :headers="headers" :items="aeditems2" :single-select="singleSelect"
        item-key="devSn" show-select color="transparent" height="100%" fixed-header
        :search="strSearch" :custom-filter="custFilter" 
        :footer-props="{ 'items-per-page-options': [30, 50, 100, -1]}">

        <!-- style="height: calc(100vh - 145px);">   -->
       
        <!-- <template v-slot:header="{ props: { headers } }">
          <thead>
          <tr>
            <th v-for="h in headers" :key="h.value" :class="h.class">
              <span>{{h.text}}</span>
            </th>
          </tr>
          </thead>
        </template> -->

        <template v-slot:[`item.devSn`]="{ item }">
          <v-btn color="blue--text transparent" height="100%" class="pa-0 elevation-0" @click="onClickDevSn(item)">{{ item.devSn }}</v-btn>
        </template>

        <template v-slot:[`item.Map`]="{ item }">
          <!-- <dialogMap activator="parent" :location="item.InstLoc" newWidth="700" newHeight="500"/> -->
          <dialogMap activator="parent" :location="getLocation(item)" newWidth="700" newHeight="500"/>
        </template>

      </v-data-table>
    </v-card>

    <v-snackbar right v-model="snackBar.on" :color="snackBar.color">{{ snackBar.message }}</v-snackbar>

  </v-card>

  </v-card>
</template>

<script>
import axios from 'axios'
import MonthPicker from '../components/Common/MonthPicker'
import SvgIcon from '@jamescoyle/vue-icon';
import { mdiReload, mdiChevronDownCircle, mdiChevronUpCircle, mdiRefreshCircle } from '@mdi/js';
import cookies from '@/js/Cookie.js'
import moment from 'moment'

import ToExcel from '@/components/Common/exportExcel'
import dialogOptions from '@/components/DevInfoPage/dialogDevOptions'
import dialogDevAdd from '@/components/DevInfoPage/dialogDevAdd'
import dialogDevSendStatus from '@/components/DevInfoPage/dialogDevSendStatus'
import dialogMap from '@/components/Common/dialogMap'
import inputBox1 from '@/components/Common/InputBox1'
import comboBox1 from '@/components/Common/ComboBox1'

import * as Tbl from '@/js/tables.js'
import * as Ut from '@/js/ut.js'


export default {
  name: 'DevInfo',

  components: {
    MonthPicker, ToExcel, dialogOptions, dialogDevAdd, dialogDevSendStatus, dialogMap, inputBox1, comboBox1, SvgIcon
  }, 

  data () {
    return {
      axiosPathGetDevInfoV1: '/client/api/v1/aedDeviceRecord',
      axiosPathPostDevInfo:  '/client/api/v1/aedDeviceRecord',
      axiosPathLatestTestReport: '/api/v1/latesttestreports',
      axiosPathLatestEmergency:   '/api/v1/latestemergreports',
      axiosPathTestReports:       '/api/v1/testreports',

      pathReload: mdiReload,
      pathChevronDown: mdiChevronDownCircle,
      pathRefreshCircle: mdiRefreshCircle,
      Params: {"type": "", "data": 0},

      aryModels: Tbl.ModelData,
      aryDevStatus:  Tbl.DeviceStatusData,
      aryBattStatus: Tbl.BattStatusData,
      aryPadsStatus: Tbl.PadsStatusData,
      hExpandDef: {'min': '50px', 'max': '170px'},
      hExpand: this.hExpandDef,
      strSearch: '',
      lastSn: '',
      lastStatus: false,
      strDummy: 'X1Y2Z3!!@',

      vIcon: 'mdi-chevron-down',
      vSeperator1: '10px',
      vRstInput: 0,
      axiosFlags: {"device": false, "testinfo": false},
      showProgress: false,
      snackBar: {"on": false, "color": "", "message": ""},
      snackTimeout: 3000,
      snackMsgLogout: "Login expired, please login again",
      monthPick: "",
      tmrObj: {},      

      aeditems2: this.aeditems,
      singleSelect: false,
      // selected[] contains array of selected objects (exclude dup items)
      selected: [],
      tblHeaders: Tbl.TblHeaders,
      dialogOptionsDef: Tbl.DialogOptionsDef,
      dialogOptions: {},
      // headers: [{ text: 'Device Sn', align: 'start', sortable: false, value: 'sn', class: 'tbl-header1 tbl-header2'}],
      headers: [],
      // axios data on aeditems[], filtered data & display on tblitems[]
      //aeditems: [],

      filters: Tbl.createFilters(),
      resetMonth: 0,
      resetDevice: 0,
      resetBatt: 0,
      resetPads: 0,
      resetModel: 0,
      // v-data-table itemClass

      sMap: 'Hong Kong',

      snackbarStart: { message: '', open: false, color: 'primary' },
      // 8x testinfo array of obj at  assets/samples/testinfo.js
      rptTestInfo: null,
    }
  },

  methods: {

    // ----------------------------------------------------------------------
    //              [handlers]
    // ----------------------------------------------------------------------

    // DESCRIPTION:  DevInfo --> click Sn --> DevInfoDetail
    onClickDevSn (xobj) {
      if (xobj !== null && "devSn" in xobj) {
        this.$router.push({name: "DevInfoDetail", params: {xobj}, query:{sn:xobj.devSn}}); // xobj=DevInfo
      }
    },

    onClickDevMap (xloc) {
      //console.log(xloc);
      Ut.noop();
    },

    // DESCRIPTION:  get options obj from dialogOptions and build new table header & body
    onEmitDialogOptions (xobj) {
      this.dialogOptions = xobj;
      this.buildHeaders();
      // this.buildTableContent();
    },

    // DESCRIPTION:  handle the return values from InputBox component (emit from Text-field)
    onInputBox1 (xobj) {
      /* switch(xobj.id) {
          case 'devsn'  :  this.filters.devSn = xobj.text; break;
          //case 'insttime'  :  this.filters.InstTime = xobj.text; break; //changed to use MonthPicker
          case 'cust'  :  this.filters.CustName = xobj.text; break;
          case 'loc'  :  this.filters.InstLoc = xobj.text; break;
          default: break;
      }  */

      switch(xobj.id) {
          case 'devsn'  :  
            this.filters.devSn = xobj.text;
            this.triggerCustFilter(xobj.text);
            break;
          //case 'insttime'  :  this.filters.InstTime = xobj.text; break; //changed to use MonthPicker
          case 'cust'  :  
            this.filters.CustName = xobj.text; 
            this.triggerCustFilter(xobj.text);
            break;
          case 'loc'  :  
            this.filters.InstLoc = xobj.text;  
            this.triggerCustFilter(xobj.text);      
            break;
          default: break;
      }        
    },

    // DESCRIPTION:  handle the return values from v-select component (emit from v-select)
    onComboBox1 (xobj) {
      switch(xobj.id) {
        case 'devst'  :  
          this.filters.DevStatus = xobj.text; 
          this.triggerCustFilter(xobj.text);
          break;
        case 'battst' :  
          this.filters.battCap = xobj.text; 
          this.triggerCustFilter(xobj.text);
          break;
        case 'padst'  :  
          this.filters.PadStatus = xobj.text; 
          this.triggerCustFilter(xobj.text);
          break;
        case 'model'  :  
          this.filters.devModel = xobj.text;
          this.triggerCustFilter(xobj.text); 
          break;
        default: break;
      } 
    },

    // DESCRIPTION:  handler of MonthPicker, format of xDate: YYYY-MM
    onMonthChange (xDate) {
      if (xDate !== "reset") {
        this.filters.InstTime = xDate; 
      } 
      this.strSearch = xDate;
    },

    // DESCRIPTION:  trigger action of CustFilter(), for any change in v-Text-field, v-select
    // strSearch is not used for search, it is used only for triggering custFilter()
    triggerCustFilter (xstr) {
      if (xstr === "") this.strSearch = this.strDummy;
      else {
        this.strSearch = xstr;
      }
    }, 

    // ---------------------------------
    //       <Search> Button Handler
    // ---------------------------------
    onBtnResetFilter () {
      this.filters.devSn = "";
      this.filters.CustName = "";
      this.filters.InstLoc = "";
      this.filters.InstTime = "";
      // reset Input components
      if (this.vRstInput++ > 60000) this.vRstInput = 0;    
      // reset MonthPicker component
      if (this.resetMonth++ > 60000) this.resetMonth = 0;
      // reset Combo components
      if (this.resetDevice++ > 60000) this.resetDevice = 0;
      if (this.resetBatt++ > 60000) this.resetBatt = 0;
      if (this.resetPads++ > 60000) this.resetPads = 0;
      if (this.resetModel++ > 60000) this.resetModel = 0;      
      this.filters.DevStatus = "All"; 
      this.filters.battCap = "All"; 
      this.filters.PadStatus = "All"; 
      this.filters.devModel = "All";    
    },

    // DESCRIPTION:  bypass strSearch to search table. Build my custom filters to search
    custFilter (value, search, item) {
      /* return value != null && search != null && typeof value === 'string' &&
        value.toString().toLocaleUpperCase().indexOf(search) !== -1    */

      if (this.lastSn !== item.devSn) {
        this.lastSn = item.devSn;
        this.lastStatus = false;
        let mask = 0, flag = 0;
        //(1) handle v-Text-Field
        if (this.filters.devSn !== "") {
          mask = mask | 0x01;
          if (item.devSn != null && item.devSn.indexOf(this.filters.devSn) >= 0) flag |= 0x01;
        }
        if (this.filters.CustName !== "") {
          mask = mask | 0x02;
          if (item.CustName != null && item.CustName.indexOf(this.filters.CustName) >= 0) flag |= 0x02;
        }
        if (this.filters.InstLoc !== "") {
          mask = mask | 0x04;
          if (item.InstLoc != null && item.InstLoc.indexOf(this.filters.InstLoc) >= 0) flag |= 0x04;
        }
        //(2) handle v-Select
        if (this.filters.DevStatus !== "" && this.filters.DevStatus !== "All") {
          mask = mask | 0x08;
          if (item.DevStatus != null && item.DevStatus != "/" && item.DevStatus.indexOf(this.filters.DevStatus) >= 0) flag |= 0x08;
        }
        if (this.filters.battCap !== "" && this.filters.battCap !== "All") {
          mask = mask | 0x10;
          if (item.battCap !== null && item.battCap !== "/" && item.battCap !== "") {
            let str = item.battCap.replace("%", "");
            let num = parseInt(str);
            switch(this.filters.battCap) {
              case 'Low': 
                if (num < Ut.Limits.batt.value2) flag |= 0x10;   // < 35%
                break;
              case 'Fault': 
                if (num === 0) flag |= 0x10;    // display only 0% batt
                break;
              case 'Normal': 
                // Normal
                if (num >= Ut.Limits.batt.value2) flag |= 0x10;  // >= 35%
                break;
            }
          }
          //if (item.battCap != null && item.battCap.indexOf(this.filters.battCap) >= 0) flag |= 0x10;
        }
        if (this.filters.PadStatus !== "" && this.filters.PadStatus !== "All") {
          // item.PadStatus:  undefined, "/", "3", 
          mask = mask | 0x20;
          //if (item.PadStatus != null && item.PadStatus.indexOf(this.filters.PadStatus) >= 0) flag |= 0x20;
          if (item.PadStatus != null && item.PadStatus === this.filters.PadStatus) flag |= 0x20; //rev1.54
        }
        if (this.filters.devModel !== "" && this.filters.devModel !== "All") {
          mask = mask | 0x40;
          if (item.devModel != null && this.filters.devModel === item.devModel) flag |= 0x40;
        }
        //(3) DateTime:    filters.InstTime = "2024-07", item.InstTime = "2024-08-12 00:00:00" or "/" or "Invalid Time"
        if (this.filters.InstTime !== "") {
          mask = mask | 0x80;
          if (item.InstTime != null && item.InstTime != "/" && item.InstTime.indexOf("Invalid") < 0 && item.InstTime.indexOf(this.filters.InstTime) === 0) flag |= 0x80;
        }
        //(4) Before Exit
        //console.log("##", this.filters.DevStatus, item.DevStatus);  //debug filter
        if (mask === 0 || mask === flag) {
          this.lastStatus = true;
          return true;                    //all matched, show
        }
        return false;
      }

      return this.lastStatus;
    },

    // ---------------------------------
    //       <Add Device> Button Handler
    // ---------------------------------
    onBtnAddDevice (xobj) {
      //console.log('DevInfo.vue-onBtnAddDevice', xobj);
      // create Axios packet and send **
      //console.log('onBtnAddDevice',xobj);
      this.axiosPostDevInfoV1(xobj);
    },

    onBtnExport () {
      //console.log('DevInfo.vue','onBtnExport');
      Ut.noop();
    },

    onBtnSendDevInfo () {
      //console.log('DevInfo.vue','onBtnSendDevInfo');
      Ut.noop();
    },        

    onBtnExpand () {
      if (this.hExpand === this.hExpandDef.min) {
        this.hExpand = this.hExpandDef.max;
        this.vIcon = 'mdi-chevron-up';
        this.vSeperator1 = '0px';
        this.pathChevronDown = mdiChevronUpCircle;
      } else {
        this.hExpand  = this.hExpandDef.min;
        this.vIcon = 'mdi-chevron-down';
        this.vSeperator1 = '10px';
        this.pathChevronDown = mdiChevronDownCircle;
      }
    },


    // ----------------------------------------------------------------------
    //              [Ut]
    // ----------------------------------------------------------------------     
    chkEmptyAry (xary) {
      if (typeof(xary) !== Array) return null;
      if (xary.length === 1 && xary[0] === "") return null;
      return xary;
    },


    // ----------------------------------------------------------------------
    //              [Table]
    // ----------------------------------------------------------------------    
    // DESCRIPTION:  rebuild table headers by dialogOptions content
    buildHeaders () {
      if (this.headers.length > 0) {
        this.headers.splice(0);
      }
      let vObj = {};
      vObj = this.tblHeaders[0];
      //vObj['class'] = 'tbl-header2';
      this.headers.push(vObj);

      let vTblLength = this.tblHeaders.length;
      for (let idx = 1; idx < vTblLength; idx++) {
        let vfieldname = this.tblHeaders[idx].value;
        vObj = this.tblHeaders[idx];
        if (this.dialogOptions[vfieldname]) {
          this.headers.push(vObj);
        }
      }
    },

    buildTableContent () {
      if (this.aeditems.length > 0) {
        this.aeditems.splice(0);
      }
      // according to header fields, construct tbl contents, construct query
      // read data from SQL to table
    },

    // DESCRIPTION:  clear aeditems, 
    clearTable () {
      if (this.aeditems.length > 0) {
        this.aeditems.splice(0);
        this.aeditems.length = 0;        
      }
    },

    clearArray (xAry) {
      if (xAry.length > 0) {
        xAry.splice(0);
        xAry.length = 0;        
      }
    },

    // DESCRIPTION:  chk if string is empty (or default value 'All')
    isFieldEmpty (xstr) {
      if (xstr === undefined || xstr === null) return true;
      let str = xstr.toString();
      //let str = xstr;
      if (str === "" || str === "All") return true;
      return false;
    },

    getLocation (xobj) {
      if (xobj.InstLoc !== undefined && xobj.InstLoc !== null && xobj.InstLoc !== '' && xobj.InstLoc !== '/')
        return xobj.InstLoc;
      else if (xobj.DeliveryAddr !== undefined && xobj.DeliveryAddr !== null && xobj.DeliveryAddr !== '' && xobj.DeliveryAddr !== '/')
        return xobj.DeliveryAddr;
      else if (xobj.Address !== undefined && xobj.Address !== null && xobj.Address !== '' && xobj.Address !== '/')      
        return xobj.Address;
      else if (xobj.CustName !== undefined && xobj.CustName !== null && xobj.CustName !== '' && xobj.CustName !== '/')
        return xobj.CustName;      
      else 
        return "";
    },

    // ----------------------------------------------------------------------
    //              [SnackBar]
    // ----------------------------------------------------------------------
    snackStart(xmsg, xcolor) {
      if (this.snackBar.on) {
        this.snackStop();
      }
      this.snackBar.message = xmsg;
      this.snackBar.color = xcolor;
      this.snackBar.on = true;
      this.tmrObj = setTimeout(this.snackTimerEvent, this.snackTimeout);
    },

    snackTimerEvent() {
      this.snackBar.on = false;
      this.tmrObj = null;
      if (this.snackBar.message === this.snackMsgLogout) {
          this.snackBar.message = '';
          this.$root.$emit('App_Logout', "114");
      }
      this.snackBar.message = '';      
    },

    snackStop() {
      clearTimeout(this.tmrObj);
      this.snackBar.message = '';
      this.snackBar.on = false;
      this.tmrObj = null;
    },


    // ----------------------------------------------------------------------
    //              [Processing] [stat]
    // 
    // ----------------------------------------------------------------------


    // ----------------------------------------------------------------------
    //              [Axios]
    // 
    // ----------------------------------------------------------------------

    // ---------------------------------------------
    // DESCRIPTION:  Insert new device info:  
    //   <Add Device> --> table to obj --> call axiosPostDevInfoV1(obj)
    async axiosPostDevInfoV1 (xobj) {
      let vObj = {
        'method': 'POST',
        'url': this.$config.apiBaseUrl + this.axiosPathPostDevInfo,
        'headers': {
          'X-Requested-With': 'XMLHttpRequest',
          'Content-Type': 'application/json',
          //'Authorization': 'Bearer ' + this.$cookies.get('kk')
          'Authorization': 'Bearer ' + cookies.get('kk')
        },
        'data': xobj
      }
      try {
        let resp = await axios.request(vObj)
        if (resp != null) {
          // alert(JSON.stringify(resp)) // {"data":{status: xxx, message: yyy, data: [{}, {}, ...] }
          let vStatus = resp.data.status;
          if (vStatus !== 'SUCCESS') {
            console.log('#axiosPostDevInfoV1() error: axios post data request fail');
          }  
        } else {
          console.log('#axiosPostDevInfoV1() error: receive null data');
        }
      } catch (error) {
        console.error('#axiosPostDevInfoV1() error: ' + error.message);
      }
    },

    // ---------------------------------------------
    // DESCRIPTION:  Get deivce info, V1 (with token)
    async axiosGetDevInfoV1 () {
      if (this.axiosFlags.device === true) {
        //console.log("##axiosGetDevInfoV1: previous search not finished");
        return;
      }
      this.axiosFlags.device = true;
      let vObj = {
        'method': 'GET',
        'url': this.$config.apiBaseUrl + this.axiosPathGetDevInfoV1,
        //'url': 'http://196.76.0.156:7003/client/api/v1/aedDeviceRecord',
        //timeout: 3000,
        'headers': {
          //'X-Requested-With': 'XMLHttpRequest',
          'Content-Type': 'application/json',
          // 'Authorization': 'Bearer ' + this.$cookies.get('kk')
          'Authorization': 'Bearer ' + cookies.get('kk')
        }
      }
      
      try {
        let resp = await axios.request(vObj.url, vObj);
        if (resp != null) {
          //alert(JSON.stringify(resp)) // {"data":{status: xxx, message: yyy, data: [{}, {}, ...] }
          //console.log('#HomeP-111',resp.status);  //debug
          let vStatus = resp.data.status;
          if (vStatus === 'SUCCESS') {
            //console.log('##axiosGetDevInfoV1(X)', resp.data.data);  //debug
            if (resp.data.data !== null) {
              //this.storeToAedItems(resp.data.data);  //copy axios data to global aeditems[]
              //this.axiosGetEmergencyLatest(); 
              //this.axiosGetTestReportLatest();
              //if (this.axiosFlags.testinfo === false) this.showProgress = false;
              return resp.data.data;
            } 
          } else {
            console.log('#axiosGetDevInfoV1(1) error: axios data request fail');
          }  
          this.axiosFlags.device = false;
        } else {
          console.log('#axiosGetDevInfoV1(2) error: receive null data');
          this.axiosFlags.device = false;
        }
      } catch (error) {
        this.axiosFlags.device = false;
        console.error('#axiosGetDevInfoV1(3) error: ', error.message);
        if (error.message.indexOf('401') > 0) {
          this.snackStart(this.snackMsgLogout, 'error');
        } else if (error.message.indexOf('Network Error') > 0 || error.message === 'undefined') {
          this.snackStart("Cannot connect to Server", 'error');
        }
      }
      return null;
    },

    // ---------------------------------------------
    // DESCRIPTION:  Latest Test Report 
    async axiosGetTestReportLatest () {
      if (this.axiosFlags.testinfo === true) {
        //console.log("##axiosGetTestReportLatest: previous search not finished");
        return;
      }      
      this.axiosFlags.testinfo = true;
      let vObj = {
        'method': 'GET',
        'url': this.$config.apiBaseUrl + this.axiosPathLatestTestReport,
        //timeout: this.connectionTimeout,
        'headers': {
          'X-Requested-With': 'XMLHttpRequest',
          'Content-Type': 'application/json',
          //'Authorization': 'Bearer ' + this.$cookies.get('kk')
          'Authorization': 'Bearer ' + cookies.get('kk')
        }
      }

      try {
        let resp = await axios.request(vObj)
        if (resp != null) {
          //alert(JSON.stringify(resp)) // {"data":{status: xxx, message: yyy, data: [{}, {}, ...] }
          let vStatus = resp.data.status;
          if (vStatus === 'SUCCESS') {
            if (resp.data.data !== null) {
              //Ut.copyArray(this.testrpts, resp.data.data);
              //this.processTestReportLatest();
              //if (this.axiosFlags.device === false) this.showProgress = false;
              return resp.data.data;
            }
          } else {
            console.log('#axiosGetTestReportLatest(1) error: axios data request fail');
          }  
          this.axiosFlags.testinfo = false;
        } else {
          console.log('#axiosGetTestReportLatest(2) error: receive null data');
          this.axiosFlags.testinfo = false;
        }
      } catch (error) {
        this.axiosFlags.testinfo = false;
        console.error('#axiosGetTestReportLatest(3) error: ' + error.message);
        if (error.message.indexOf('401') > 0) {
          this.snackStart(this.snackMsgLogout, 'error');
        } else if (error.message.indexOf('Network Error') > 0 || error.message === 'undefined') {
          this.snackStart("Cannot connect to Server", 'error');
        }
      }
      return null;
    },

    // ---------------------------------------------
    // DESCRIPTION:  Latest Emergency report        
    async axiosGetEmergencyLatest () {            
      let vObj = {
        'method': 'GET',
        'url': this.$config.apiBaseUrl + this.axiosPathLatestEmergency,
        //timeout: this.connectionTimeout,
        'headers': {
          'X-Requested-With': 'XMLHttpRequest',
          'Content-Type': 'application/json',
          //'Authorization': 'Bearer ' + this.$cookies.get('kk')
          'Authorization': 'Bearer ' + cookies.get('kk')
        }
      }

      try {
        let resp = await axios.request(vObj)
        if (resp != null) {
          //alert(JSON.stringify(resp)) // {"data":{status: xxx, message: yyy, data: [{}, {}, ...] }
          let vStatus = resp.data.status;
          if (vStatus === 'SUCCESS') {
            if (resp.data.data !== null) {
              //Ut.copyArray(this.emergrpts, resp.data.data);
              //this.processEmergencyReportLatest();
              return resp.data.data;
            }
          } else {
            console.log('#axiosGetEmergencyLatest(1) error: axios data request fail');
          }  
        } else {
          console.log('#axiosGetEmergencyLatest(2) error: receive null data');
        }
      } catch (error) {
        console.error('#axiosGetEmergencyLatest(3) error: ' + error.message);
      }
      return null;
    },


    // ---------------------------------------------
    // DESCRIPTION:  convert axios format to devInfo format  
    storeToAedItems (xAry) {
      if (xAry === null) return;
      this.clearArray(this.aeditems);
      let str = "";
      let j2 = 0;
      for (let idx=0; idx < xAry.length; idx++) {
        let vObj2 = xAry[idx];       // get dev sn and search its most recent report
        if (vObj2.isDeleted) continue;
        let vObj = Tbl.createDevInfo();   // devinfo obj template
        vObj.id            = vObj2.id
        vObj.devSn         = vObj2.sn;
        vObj.devModel      = vObj2.model;
        vObj.battCap       = "/";
        //vObj.InstLoc       = vObj2.maploc;
        if (vObj2.instLoc !== null || vObj2.instLoc !== "") vObj.InstLoc = vObj2.instLoc;
        vObj.CustName      = vObj2.organization;
        vObj.WarrantyStatus = vObj2.warrentyMonths;
        if (vObj2.warrentyMonths === null) vObj.WarrantyStatus = "/";
        //vObj.AssetSn      = vObj2.assetsn;
        vObj.DevStatus     = vObj2.devstatus;
        if (vObj.DevStatus === null || vObj.DevStatus === "") vObj.DevStatus = "/";
        vObj.PadStatus     = "/";
        if (vObj2.instTime !== null) {
          //vObj.InstTime      = moment(vObj2.instTime).format("YYYY-MM-DD HH:mm:ss"); 
          vObj.InstTime      = moment(vObj2.instTime).format("YYYY-MM-DD"); 
        } else  vObj.InstTime  = "/";

        if (vObj2.testperiod !== null && vObj2.testperiod !== "") {
          vObj.DevSelfTestPeriod = vObj2.testperiod;
        } 
        else vObj.DevSelfTestPeriod = "/";
        if (vObj2.reportperiod !== null && vObj2.reportperiod !== "") {
          if (vObj2.reportperiod === 1) vObj.SelfTestSendPeriod = "Daily";
          else if (vObj2.reportperiod === 7) vObj.SelfTestSendPeriod = "Weekly";
          else vObj.SelfTestSendPeriod = "/";
        }
        else vObj.SelfTestSendPeriod = "/";

        vObj.WirelessStrength  = "/";
        if (vObj2.networktype === null || vObj2.networktype === "") vObj.NetworkType = "/";
        else vObj.NetworkType  = vObj2.networktype;
        if (vObj.NetworkType === "") vObj.NetworkType = "/";
        vObj.DevAdmin          = vObj2.adminName;
        //vObj.ExtendedWarranty  = '';
        vObj.LastReportUploadTime   = "/";
        //vObj.RescueTimes       = '';
        vObj.WarrantyExpireDate = moment(vObj2.warrentyDue).format("YYYY-MM-DD");  
        if (vObj.WarrantyExpireDate.indexOf("Invalid") >= 0) vObj.WarrantyExpireDate = "/";
        //vObj.ICCID             = vObj2.iccid;
        vObj.LocAttr           = vObj2.locattr;
        if (vObj.LocAttr === null || vObj.LocAttr === "") vObj.LocAttr = "/";
        //vObj.Address         = vObj2.deliveryAddress;
        if (vObj2.maploc === null || vObj2.maploc === "")  vObj.MapLoc = "/";
        else vObj.MapLoc = vObj2.maploc;
        if (vObj2.batterySN === null || vObj2.batterySN === "") vObj.battSn = "/";
        else vObj.battSn       = vObj2.batterySN;
        vObj.DeliveryAddr      = vObj2.deliveryAddress;
        if (vObj2.instLoc === null || vObj2.instLoc === "") vObj.InstLoc = "/";
        else vObj.InstLoc      = vObj2.instLoc;
        // Pads
        str = "";
        if ("pad" in vObj2 && vObj2.pad.length > 0) {
          for(j2=0; j2 < vObj2.pad.length; j2++) {
            str = str + vObj2.pad[j2].type+",";
            vObj.PadsShelfLife = vObj2.pad[j2].ExpiryDate; //last always overwrite first
          }
        }
        if (str.trim() !== "") vObj.PadType = str;
        else vObj.PadType = "/";
        
        // PIC:  error in reading PIC.length for zero length array
        if ("PIC" in vObj2 && vObj2.PIC !== undefined && vObj2.PIC !== null) {
          for(let idx=0; idx < vObj2.PIC.length; idx++) {
            vObj.Pic.push(vObj2.PIC[idx]);
          } 
        }
        vObj.show = true; 

        //if (idx === 0) console.log("##devList-1", vObj, vObj2);  //debug
        //if (vObj2.sn === "AE7-3B095116") console.log(vObj2);  //debug
        this.aeditems.push(vObj);
      }

    },

    // ---------------------------------------------
    // DESCRIPTION:  process latest test report: total count statistics   (OBSOLETED)
    // main.js\testrpts[]  contains the latest test reports
    async processTestReportLatest () {
      if (this.testrpts.length === 0) {
        //console.log('#HomeP-err001 *** testrpts[] empty ***');
        return;
      }      
      if (this.aeditems.length === 0 && this.axiosRetry > 0) {
        //console.log('#HomeP-err002 *** aeditems[] empty', this.axiosRetry);
        if (this.axiosRetry > 0) { 
          setTimeout(this.processTestReportLatest, 1000);
          this.axiosRetry--;
        }
        return;
      }

      /* console.log("##processTestReportLatest11");  //debug*
      console.log("padsSt3mon", Ut.padsSt3mon);
      console.log("padsExpire", Ut.padsExpire);
      console.log("batt35", Ut.batt35);
      console.log("aryFault", Ut.aryFault); */


      let sOffline = "", sPads = "", sDev = "Normal", str="";
      let iRescue = 0;
      let vT = {}, vDev = {};
      let expireDate = "";
      let expired = 0, battTot = 0, battRes = 0, val = 0, val2 = 0;

      // this.testrpts : Last Test Reports of each device sn, raw data
      for(let idx=0; idx < this.testrpts.length; idx++) {
        vT = this.testrpts[idx];        

        // seach corresponding device object from test report sn
        if (this.aeditems.length == 0) continue;
        vDev = this.findSnFromDeviceReport(vT.sn);   
        if (vDev === null) continue;

        // (0) Date format fix:  2024-04-03T08:07:44.000Z to YYYY-MM-DD HH:MM:SS
        this.testrpts[idx].datetime = moment(vT.datetime).format("YYYY-MM-DD HH:mm:ss");
        //this.testrpts[idx].testtime = moment(vT.testtime).format("YYYY-MM-DD HH:mm:ss");
        this.testrpts[idx].testtime = moment.parseZone(vT.testtime).local(true).format("YYYY-MM-DD HH:mm:ss");

        //(1) Fault
        sDev = "Normal";
        if (vT.testerrorcode !== "/" && vT.testerrorcode !== "") { 
          sDev = "Fault";
        } else {
          if (this.testrpts[idx].testresult === 0)  sDev = "Fault";
          if (this.testrpts[idx].relatedresult === 0)  sDev = "Fault";      
        }

        //(2) Warning, pads expired + expired in 3 months
        expireDate = vT.testitems.padsExpiringDate.result;  //return YYYY-MM-DD  or  ""
        expired = vT.testitems.padsExpiried.result;         //0 or 1
        if (expireDate === null || expireDate === "") {
          sPads = "/";
          vDev.PadsShelfLife = "/";
        }
        else {
          vDev.PadsShelfLife = expireDate;
          sPads = Ut.getPadsStatus(expired, expireDate);      //"3", "12", "Expired", "Normal"
          //if (sPads === "3" || sPads === "12") {
          if (sPads === "3") {
            sPads = "Expired Soon";
            if (sDev === "Normal") sDev = "Warning";
          } else if (sPads === "12") {
            sPads = "Expired in 12 months";
          } else if (sPads === "Expired") {
            sDev = "Fault"; sPads = "Expired";
          }
        }
        // finally check padsExpiried, padsExpireSoon flags, where expiry date is absent (MR60)
        if (sPads === "" || sPads === "Normal" || sPads === "/") {
          if (vT.testitems.padsExpiried.result === 1) {
            sPads = "Expired";
            if (sDev !== "Fault") sDev = "Fault";
          } else {
            if (vT.testitems.padsExpireSoon.result === 1) {
              sPads = "Expired Soon";
              if (sDev !== "Fault") sDev = "Warning";
            }
          }
        }

        battTot = vT.testitems.batTotalCapacity.result;
        battRes = vT.testitems.batResidualCapacity.result;
        //console.log('testrpt: ', devSn, battRes, battTot);
        if (battTot > 0) {
          val = Math.floor((battRes * 100)/battTot);
          val = Math.abs(val);
          vDev.battCap = val + "%";             
          if (val <= Ut.Limits.batt.value2 && sDev === "Normal") sDev = "Warning";
          if (val === 0) sDev = "Fault";
        }

        //(3) Offline:  datetimeNow - reportDateTime > xmit period (1 or 7)
        // sOffline
        val2 = Ut.getDateDiff(vT.datetime);    // ret < 0 if past
        if (vT.netinfo.TransmissionPeriod + val2 < 0) {
          //sOffline = (Math.abs(val)).toString();
          sOffline = "Offline";
        } 
        else sOffline = "Normal";

        //(4) Rescue:  done in processEmergencyReportLatest()
        
        // -------- find sn in aeditems[] ---------
        //if (this.aeditems.length > 0 ) {
          //vDev = this.findSnFromDeviceReport(vT.sn);   
          //if (vDev !== null) {
            vDev.WirelessStrength = vT.netinfo.SignalLevel;
            vDev.RescueTimes = iRescue;
            vDev.Offline = sOffline;
            vDev.PadStatus = sPads;
            if (sOffline === "Offline") {
              vDev.DevStatus = sDev + "/Offline";
            }
            else vDev.DevStatus = sDev;            
            vDev.DevSelfTestPeriod = vT.netinfo.TestPeriod;   // Hide
            // vDev.SelfTestSendPeriod = vT.netinfo.TransmissionPeriod; 
            if (vT.netinfo.TransmissionPeriod === 1) vDev.SelfTestSendPeriod = "Daily";
            else if (vT.netinfo.TransmissionPeriod === 7)  vDev.SelfTestSendPeriod = "Weekly";
            else vT.netinfo.TransmissionPeriod = "/";
            vDev.LastReportUploadTime = vT.datetime;
            switch(vT.netinfo.NetType) {
              case 0: vDev.NetworkType = "Wifi"; break;
              case 1: vDev.NetworkType = "4G"; break;
              default: vDev.NetworkType = "/"; break;
            }
            //console.log('##DevInfo-111', vT);  //debug
        //  }
        //}
      } // end of for

      this.processMasterRecord();

    },

    // ---------------------------------------------
    // DESCRIPTION:  process emergency report: statistics       (OBSOLETED)
    async processEmergencyReportLatest () {
      let vEmerg = {};
      let dateDiff = 0;
      for(let idx=0; idx < this.emergrpts.length; idx++) {
        vEmerg = this.emergrpts[idx];
        dateDiff = this.getDateDiff(vEmerg.datetime);
      }
    },

    // ---------------------------------------------
    // DESCRIPTION:  process Master Record info:  pads and batt
    // Prority:  testreport alsways overwrite master record device status
    //           used only if SN present in Dev list
    processMasterRecord() {
      let Sn = "", sExpDay;
      let j2;
      //(1) Warning
      //    (1a) pads3mon 
      if (Ut.padsSt3mon !== null && Ut.padsSt3mon.length > 0) {
        for(let idx=0; idx < Ut.padsSt3mon.length; idx++) {
          Sn = Ut.padsSt3mon[idx].sn;
          let vObj = this.searchSnFromTestRpts(Sn);
          if (vObj === null) {   //not existed in testreports, only in master rec
            let vDev = this.findSnFromDeviceReport(Sn);   
            if (vDev === null) continue;  // Sn not existed in device list
            vDev.PadStatus = "Expired Soon";
            vDev.PadsShelfLife = Ut.padsSt3mon[idx].padExpiry;
            vDev.DevStatus = "Warning";
          }
        }
      }
      //    (1b) padsExpire
      if (Ut.padsExpire !== null && Ut.padsExpire.length > 0) {
        for(let idx=0; idx < Ut.padsExpire.length; idx++) {
          Sn = Ut.padsExpire[idx].sn;
          let vObj = this.searchSnFromTestRpts(Sn);
          if (vObj === null) {   //not existed in testreports, only in master rec
            let vDev = this.findSnFromDeviceReport(Sn);   
            if (vDev === null) continue;  // Sn not exited in device list
            vDev.PadStatus = "Expired";   // Sn existed in device list
            vDev.PadsShelfLife = Ut.padsExpire[idx].padExpiry;
            vDev.DevStatus = "Warning";
          }
        }
      }
      //    (1c) battery level < 35%
      if (Ut.batt35 !== null && Ut.batt35.length > 0) {
        for(let idx=0; idx < Ut.batt35.length; idx++) {
          Sn = Ut.batt35[idx].sn;
          let vObj = this.searchSnFromTestRpts(Sn);
          if (vObj === null) {   //not existed in testreports, only in master rec
            let vDev = this.findSnFromDeviceReport(Sn);   
            if (vDev === null) continue;  // Sn not exited in device list
            vDev.battCap  = Math.floor(Ut.batt35[idx].batteryPercent) + "%";   // Sn existed in device list
            vDev.DevStatus = "Warning";
          }
        }
      }      

      //(2) Fault:  usually come from fault code
      if (Ut.aryFault !== null && Ut.aryFault.length > 0) {
        for(let idx=0; idx < Ut.aryFault.length; idx++) {
          Sn = Ut.aryFault[idx].sn;
          let vObj = this.searchSnFromTestRpts(Sn);
          if (vObj === null) {   //not existed in testreports, only in master rec
            let vDev = this.findSnFromDeviceReport(Sn);   
            if (vDev === null) continue;  // Sn not exited in device list            
            vDev.DevStatus = "Fault";
          }
        }
      } 
    },

    searchSnFromTestRpts (xSn) {
      if (xSn === "") return null;
      if (this.testrpts === null || this.testrpts.length === 0) return null;
      for(let idx=0; idx < this.testrpts.length; idx++) {
        if (this.testrpts[idx].sn === xSn) return this.testrpts[idx];
      }
      return null;
    },
  

    
    // ----------------------------------------------------------------------
    //              [Ut]
    // 
    // ----------------------------------------------------------------------
    // DESCRIPTION:  capitalize first letter of string
    Capitalize (xstr) {
      let str = xstr.toString().trim();
      if (str.length === 0) return "";
      if (str.length === 1) return str.toUpperCase();
      return str.charAt(0).toUpperCase() + str.slice(1);
    },

    // DESCRIPTION:  expiry date - date now, return <= 0 if expired
    getDateDiff (xdate) {
      return (new Date(xdate) - new Date())/(1000 * 3600 * 24);
    },
    
    findSnFromDeviceReport (xdevSn) {
      for(let idx=0; idx < this.aeditems.length; idx++) {
        if (this.aeditems[idx].devSn === xdevSn) return this.aeditems[idx];
      } 
      return null;
    }

  },
  
  mounted () {
    if (cookies.exist("aa") === false) {
      this.$router.push('/');
    }    

    this.$root.$emit('App_TopBar', true);
    this.hExpand = this.hExpandDef.min;

    // Load data table, by selected Options
    this.onEmitDialogOptions(this.dialogOptionsDef); // set default options
    //(!) fill the Table with DevInfo
    this.showProgress = true;
    this.axiosRetry = 3;

    this.axiosGetDevInfoV1().then(xdata1 => {
      if (xdata1 !== null) {
        this.storeToAedItems(xdata1);

        this.axiosGetEmergencyLatest().then(xdata2 => {
          if (xdata2 !== null) {
            Ut.copyArray(this.emergrpts, xdata2);
            this.processEmergencyReportLatest();

            this.axiosGetTestReportLatest().then(xdata3 => {
              if (xdata3 !== null) {
                Ut.copyArray(this.testrpts, xdata3);
                this.processTestReportLatest();
              } else {
                this.processMasterRecord();  //if testinfo empty
              }
            }).catch((err3) => {
              console.log("##axiosGetTestReportLatest(0)", err3)
            });

          }
        }).catch((err2) => {
          console.log("##axiosGetEmergencyLatest(0)", err2)
        });

        //this.axiosGetTestReportLatest();
        this.showProgress = false;
      }
    }).catch((err1) => {
      console.log("##axiosGetDevInfoV1(0)", err1)
    });
    //this.axiosGetEmergencyLatest();      // load Latest Emergency data x1
    //this.axiosGetTestReportLatest();     // load Latest Test Report x3
  }
}

</script>

<style scoped>

/* table H extend to the bottom, but row H expand */
/* >>> table {
  height: calc(100vh - 210px);
}
*/

/* >>> table th {   
  background-color: #FF8F00 !important;
} */

>>> table td {
  font-family: Arial !important; 
  color: black;
}

/* >>> table th:first-child  {  */
>>> table th:nth-child(1) {   
  font-family: Arial !important; 
  position: sticky;
  left: 0;
  z-index:1;
  background-color: #FF8F00 !important;
}
>>> table th:nth-child(2) {   
  font-family: Arial !important; 
  position: sticky;
  left: 0;
  z-index:1;
  background-color: #FF8F00;
  /* font-family: Arial !important;
  font-style: normal;
  font-size: 0.9em !important;
  font-weight : border !important; */
}

/* >>> table td:first-child  { */
>>> table td:nth-child(1) {  
  font-family: Arial !important; 
  position: sticky;
  left: 0;
  z-index:1;
  background-color: #FAFAFA;
}
>>> table td:nth-child(2) {  
  position: sticky;
  left: 0;
  z-index:1;
  background-color: #FAFAFA;
  font-family: Arial !important; 
}

>>> table th:last-child {
  position: sticky;
  right: 0;
  z-index:1;
  font-family: Arial !important; 
}

>>> table td:last-child {
  position: sticky;
  right: 0;
  z-index: 1;
  background-color: #FAFAFA;
} 



/*  table body color, font color, font size  works, height not work */
/* >>> .style-1 td {
  height: 30px !important;
  font-size: 18px !important;
  background-color: lightgrey !important;
}  */

#idCard1 {
  margin-top: 0;
  margin-left: 0;
  margin-right: 0;
  background-color: #37474F;
  height: calc(100vh - 50px);
  overflow-y: scroll;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */  
}

#idCard1::-webkit-scrollbar {
    display: none;
}

.fontArialN {
  font-family: Arial; 
  font-style: normal; 
  font-weight: normal;   
  color: black;
}

.fontArialB {
  font-family: Arial; 
  font-style: normal; 
  font-weight: bold;   
  color: black;
}


</style>
