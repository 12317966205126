<!-- --------------------------------------------------------------------------------
                      GoogleMap.vue

DESCRIPTIONS
  - input location info (company name, building name, Longitude & Latitude) 
  - put marker on the destination

---------------------------------------------------------------------------------- -->
<template>
  <v-card :width="width" :height="height" class="mt-0 pa-0" color="transparent">
    <iframe ref="refMap" width="100%" height="97%" frameborder="1" style="background-color: #37474F;" 
       referrerpolicy="no-referrer-when-downgrade" allowfullscreen>
    </iframe>
  </v-card>
</template>

<script>
  export default {
    name: 'GoogleMap',

    props: {
      location: {type: String, default: ''},
      width: {type: String, default: "100%"},
      height: {type: String, default: "80%"}
    },

    data () {
      return {
        mapQueryPrefix: 'https://www.google.com/maps/embed/v1/place?key=' + this.$config.GOOGLE_MAP_API_KEY
      }
    },

    methods: {
      setMapAddr (xaddr) {
        // this.$refs.refMap.src = this.mapQueryPrefix + "&q=New+York";
        // this.$refs.refMap.src = this.mapQueryPrefix + "&q=22.34167,114.19387";
        // &zoom=22, kai tak 22.33060,114.19954 
        this.$refs.refMap.src = this.mapQueryPrefix + "&q=" + xaddr;
      }
    },

    mounted () {
      this.setMapAddr(this.location);
    }
  }
</script>

<style scoped>
.cBorder {
  border: 1px solid grey;
}

.v-text-field {
  min-height: 20px;
}

</style>
