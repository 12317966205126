<template>
  <v-card class="mt-1 pa-0">
    
    <DevTables :tblHeaders="tableHeader" :tblItems="tableItems" tblHeight="calc(100vh - 205px)"
      :ppDate1="pDate1" :ppDate2="pDate2" ppType="SelfTest" :trigger2="trigger"/>

  </v-card>
</template>

<script>
import DevTables from '@/components/DevInfoPage/DevTables'
import * as Samples from "@/js/samples/data.js"
import * as Tbl from "@/js/tables.js"

export default {
  name: 'TblSelfTest',

  components: {
    DevTables
  },

  props: {
    tableItems: {type: Array},
    pDate1: {type: String, default: ''},
    pDate2: {type: String, default: ''},
    trigger: {type: Number, default: 0}
  },

  data () {
    return {
      tableHeader: Tbl.TblSelfTestHeaders,
      // tableItems:  Samples.SelfTestData
    }
  },

  watch: {
    /* trigger: function(newVal) {
      console.log("##TblSelTest", newVal);
    } */
  },  

  mounted() {
  }
  
}

</script>

<style>
</style>
