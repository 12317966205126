// src\utils\cookie.js
import Vue from 'vue';
import VueCookies from 'vue-cookies'
Vue.use(VueCookies, { expires: '7sec' })
import CryptoJS from "crypto-js"

class cookies {
    set(key, value, time) {
        //console.log(key , value);
        VueCookies.set(
            key,
            encodeURIComponent(CryptoJS.AES.encrypt(value, process.env.VUE_APP_FRONT_CODE)).toString(),
            time
        )
    }
    get(key) {
        const value = VueCookies.get(key)
        if (value) {
            return CryptoJS.AES.decrypt(decodeURIComponent(value), process.env.VUE_APP_FRONT_CODE).toString(CryptoJS.enc.Utf8)
        }
    }
    exist(key) {
        return VueCookies.isKey(key)
    }
    delete(key) {
        VueCookies.remove(key)
    }
}

export default new cookies()

