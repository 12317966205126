<!-- --------------------------------------------------------------------------------
                      InputBox3.vue

DESCRIPTIONS
  - label & input, 
  - transparent background

Note:
  - 

---------------------------------------------------------------------------------- -->
<template>
  <v-card width="100%" height="100%" class="ma-0 pa-0 elevation-0 border-0 transparent">
    <!-- <v-text-field regular :width="width2" dense light :placeholder="message" type="input" v-model="keybuff"
       class="ma-0 pa-0 text-decloration-none elevation-0 rounded-lg" @keyup="onKeyUp" solo flat
       :spellcheck="false" :readonly="readonly" hide-details :class="inputColor">
    </v-text-field> -->

    <!-- <v-text-field type="input" regular width="width2" :placeholder="message" v-model="keybuff"
      @keyup="onKeyUp" class="ml-2 mt-2 text-decloration-none my-text-style" style="font-weight: bold;">
    </v-text-field>  -->

    <!-- <v-text-field :type="input" regular width="width2" :placeholder="message" v-model="keybuff"
      @keyup="onKeyUp" class="ml-2 mt-2 text-decloration-none" style="font-weight: bold;" 
      :spellcheck="false" hide-details="auto" :rules="[rules.required]">
    </v-text-field> -->

    <v-text-field :type="input" regular width="width2" :placeholder="message" v-model="keybuff"
      @keyup="onKeyUp" class="ml-2 mt-2 text-decloration-none" fontArialB 
      :spellcheck="false" hide-details="auto" :error-messages="pErrMsg" @blur="onBlur">
    </v-text-field>

  </v-card>
</template>

<script>
  export default {
    name: 'InputBox3',

    props: {
      label: {type: String, default: ''},
      message: {type: String, default: ''},
      readonly: {type: Boolean, default: false},
      width1: {type: String, default: '35%'},
      width2: {type: String, default: '65%'},
      value: {type: String, default: ''},
      input:{type:String, default: 'text'},
      pErrMsg: {type: String, default: 'Field'}
    },

    data () {
      return {
        keybuff: '',
        keybuffMax: 30,
        keyCode: 0,
        inputColor: 'yellow',
        /* rules: {
          required: value => !!value || this.pValid + ' is required'
        } */
      }
    },

    methods: {

      onBlur() {
        // console.log("##OnBlur()");
        this.$emit('msgInputBox1', this.keybuff, this.keyCode.toString());
      },

      // DESCRIPTION:  handler of keybuff change, CR=13, BACKSP=8
      onKeyUp (evn) {
        this.keyCode = evn.keyCode;
        if (this.keybuff.length >= this.keybuffMax + 1) {
          this.keybuff = this.keybuff.substring(0, this.keybuffMax)
          return;
        }
        //if (evn.keyCode >= 0x20 || evn.keyCode === 13 || evn.keyCode === 8) {
        if (evn.keyCode === 13) {
          this.$emit('msgInputBox1', this.keybuff, evn.keyCode.toString());
          return;
        }
        // this.keybuff = this.keybuff.toUpperCase()
      }
    },

    mounted () {
      this.keybuff = this.value;
    },

    watch: {
      value: function (newVal) {
        this.keybuff = newVal;
        this.$emit('msgInputBox1', this.keybuff);
        // console.log('keybuff', this.keybuff);
      }
    }

  }
</script>

<style scoped>

/* underline color */
.v-text-field >>> .v-input__slot::before  { 
  border-color: transparent !important; 
}

/* text color */
.my-text-style >>> .v-text-field__slot input {
    color: black;
  }

.v-text-field {
  min-height: 20px;
}

.fontArialN {
  font-family: Arial; 
  font-style: normal; 
  font-weight: normal;   
  color: black;
}

.fontArialB {
  font-family: Arial; 
  font-style: normal; 
  font-weight: bold;   
  color: black;
}

</style>
