<template>
  <v-container>
    <h2 style="text-align: center">System Information Page</h2>
  </v-container>
</template>

<script>
  export default {
    name: 'SystemInfo',

    data () {
      return {

      }
    }
  }
</script>

<style>
</style>
