<!-- --------------------------------------------------------------------------------
                      ComboBox1.vue

DESCRIPTIONS
  - label & input

Note:
  - parent container height >= 47px

---------------------------------------------------------------------------------- -->
<template>
  <v-card width="100%" height="100%" class="ma-0 pa-0 d-flex flex-row transparent elevation-5">

    <v-card width="40%" align-center class="mt-0 pa-0 grey lighten-2 rounded-r-0 rounded-l-2">
      <h5 class="ml-2 mt-2">{{ label }}</h5>
    </v-card>
    <!-- <v-combobox :dense="true" outlined width="60%" :value="data" :items="items" label=""
    class="ml-0 rounded-l-0 rounded-r-lg blue--text" @change="onChange"></v-combobox>  -->

    <v-select dense outlined width="60%" v-model="selected"  :items="items" label=""
    class="ml-0 rounded-l-0 rounded-r-lg blue--text" @change="onChange"></v-select>

  </v-card>
</template>

<script>
  export default {
    name: 'ComboBox1',

    props: {
      label: {type: String, default: ''},
      items: {type: Array},
      data: {type: String},
      id: {type: String, default: '0'},
      reset: {type: Number, default: 0}
    },

    data () {
      return {
        selected: ""
      }
    },

    methods: {
      onChange (selectedValue) {
        // this.$emit('msgComboBox1', selectedValue);
        this.$emit('msgComboBox1', { "id": this.id, "text": selectedValue} );
      }

    },

    watch: {
      reset: function (newVal) {
          //console.log('##Combo reset-',this.id, newVal);  //debug
          this.selected = "All";
      }
    },

    mounted() {
      this.selected = "All";
    }

  }
</script>

<style scoped>
.cBorder {
  border: 1px solid grey;
}


</style>
