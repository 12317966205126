<!-- --------------------------------------------------------------------------------
                      DevStatistics.vue

DESCRIPTIONS
  - display : Fault / Warning / Offline / Rescue statistics
---------------------------------------------------------------------------------- -->
<template>
  <v-card id="idCard"  :width="size.width" :height="size.height" @click="onClick" class="rounded-xl d-flex">
    <!-- <v-sheet class="text-left ml-2 mt-0 pa-0 font-weight-bold green--text text--accent-3 transparent text-h6 cFonts">{{ title }}</v-sheet>
    <v-spacer></v-spacer>
    <v-sheet class="text-right mr-3 mt-0 pa-0 white--text transparent text-h3 cFonts">{{ value }}</v-sheet>   -->

    <v-sheet class="text-left ml-2 mt-0 pa-0  transparent text-h5 fontArialB">{{ title }}</v-sheet>
    <v-spacer></v-spacer>
    <v-sheet class="text-right mr-0 mt-3 pa-0 transparent text-h3 fontArialN">{{ value }}</v-sheet>
    <v-sheet class="text-right mr-5 mt-10 pa-0  transparent text-body-2 fontArialN">{{unit}}</v-sheet>
  </v-card>
</template>

<script>
  export default {
    name: 'DevStatistics',

    props: {
      title: {type: String, default: ''},
      value: {type: Number, default: 0},
      unit:  {type: String, default: ''},
      //width: {type: String, default: '16.5%'},
      //height: {type: String, default: '100'}
      size:  {type: Object}
    },

    data () {
      return {
        myColor: 'yellow',
        colorBack: 'yellow'
      }
    },

    methods: {
      onClick () {
        this.$emit('onClickCard');
      }
    }
  }
</script>

<style scoped>
.cBorder {
  border: 1px solid grey;
}


.fontArialN {
  font-family: Arial; 
  font-style: normal; 
  font-weight: normal;   
  color: white;
}

.fontArialB {
  font-family: Arial; 
  font-style: normal; 
  font-weight: bold;   
  color: #FFB74D;
}

#idCard {
  /* background-image: linear-gradient(to top left, #37474F, #455A64, #546E7A, #78909C); */
  background-color: #282828;
}

</style>
