import Vue from 'vue'
import VueRouter from 'vue-router'

import cookies from '@/js/Cookie.js'

import LoginPage from '../views/LoginPage.vue'
import Dashboard from '../views/Dashboard.vue'
import DevInfo from '../views/DevInfo.vue'
import EventManagement from '../views/EventManagement.vue'
import UserManagement from '../views/UserManagement.vue'
import SystemInfo from '../views/SystemInfo.vue'
import DevInfoDetail from '../views/DevInfoDetail.vue'

Vue.use(VueRouter)

const routes = [
  {
    // path: '/LoginPage',
    path: '/',
    name: 'LoginPage',
    component: LoginPage,
  },  
  {
    path: '/Dashboard',
    name: 'Dashboard',
    component: Dashboard
  },  
  {
    path: '/DevInfo',
    name: 'DevInfo',
    component: DevInfo,
    params:{},
    
    //meta: {auth: true}
  },
  {
    path: '/EventManagement',
    name: 'EventManagement',
    component: EventManagement,
    params: {},
    //meta: {auth: true}    
  },
  {
    path: '/UserManagement',
    name: 'UserManagement',
    component: UserManagement
  },
  {
    path: '/SystemInfo',
    name: 'SystemInfo',
    component: SystemInfo
  },
  {
    path: '/DevInfoDetail',
    name: 'DevInfoDetail',
    component: DevInfoDetail,
    params: '',
    props: route => (
      {
        sn: route.query.sn,
      })
    //meta: {auth: true}
  }
]

const router = new VueRouter({
  mode: 'history',
  routes
})


router.beforeEach((to, from, next)=>{
  if ( to.name !== 'LoginPage'){
    next();
  } else {
      let vAuth = cookies.exist("aa");

      if (from.name === 'Dashboard' && to.name === 'LoginPage') {
        if (!vAuth) {
          next();
        }
      } else if (from.name === 'DevInfo' && to.name === 'LoginPage') {
        if (!vAuth) {
          next();
        }
      } else if (from.name === 'DevInfoDetail' && to.name === 'LoginPage') {
        if (!vAuth) {
          next();
        }
      } else if (from.name === 'EventManagement' && to.name === 'LoginPage') {
        if (!vAuth) {
          //next({ name: 'EventManagement' })
          next();
        }
      } else {
        //console.log("##2b", from.name, to.name); 
        next();
      }
    
  }
}) 

export default router
