import Vue from 'vue';
import CryptoJS from "crypto-js"

class sess {
    set(key, value) {
        //console.log(key , value);
        sessionStorage.setItem(
            key,
            encodeURIComponent(CryptoJS.AES.encrypt(value, process.env.VUE_APP_FRONT_CODE)).toString(),
        )
    }
    get(key) {
        const value = sessionStorage.getItem(key)
        if (value) {
            return CryptoJS.AES.decrypt(decodeURIComponent(value), process.env.VUE_APP_FRONT_CODE).toString(CryptoJS.enc.Utf8)
        }
    }
    delete(key) {
        sessionStorage.removeItem(key)
    }
}

export default new sess()

