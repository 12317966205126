<template>
  <div id="id_root" style="height: calc(100vh - 400px)">
    <v-app>

      <topBar/>

      <router-view></router-view>

    </v-app>
  </div>
</template>

<script>
import topBar from '@/views/TopBar'

export default {
  name: 'App',

  components: {
    topBar
  },

  data () {
    return {

    }
  },

  methods: {
    onAppBar (xflag) {
      this.showTopBar = xflag;
    }
  },

  mounted () {
    //this.$root.$on('LoginPage', (rxData) => { this.onLogin(rxData) })
    // setup reciever for emit signal
    //this.$root.$on('App_TopBar', (rxData) => { this.onAppBar(rxData) })

    /* if (this.$route.path !== '/LoginPage') {
      console.log('#App-to LoginPage');  //debug
      this.$router.push('/LoginPage');
    } */
  }
};
</script>

<style scoped>



#app {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: left;
  background-color: black;
  margin: 0;
  padding: 0;
}
</style>