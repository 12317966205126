<!-- --------------------------------------------------------------------------------
                      dialogDevOptions.vue

DESCRIPTIONS
  - set table column options
---------------------------------------------------------------------------------- -->
<template>
  <div class="text-center cRoot">

    <v-dialog v-model="bDialog" scrollable content-class="my-custom-dialog" :width="newWidth" :height="newHeight">
        <template  v-slot:activator="{ on, attrs }">
          <!-- <v-btn width="30px" color="blue--text transparent" v-bind="attrs" v-on="on"
            class="ma-0 pa-0 elevation-0"><v-icon color="blue">mdi-map-marker</v-icon>
          </v-btn>  -->
          <v-btn icon width="30px" color="black--text transparent" v-bind="attrs" v-on="on" class="ma-0 pa-0 elevation-0">
            <svg-icon type="mdi" :path="pathMap" color="blue" size="32"></svg-icon>
          </v-btn>
        </template>

        <v-card width="98" height="auto" class="overflow-hidden rounded-xl">
          <!------------------------ Title Bar ---------------------------------->
          <v-card width="100%" height="25px" class="d-flex flex-row">
            <v-row>
              <v-col cols="3"><h5 class="ml-2 mt-1 pa-0">Google Map</h5></v-col>
              <v-col cols="6"><v-sheet class="mt-1 ml-10">Location:   {{ location }} {{ sMsg }}</v-sheet></v-col>
            </v-row>
            <v-btn icon x-small @click="bDialog= false" class="ma-1" style="position: absolute; right: 0;">
              <!-- <v-icon color="blue">mdi-close</v-icon></v-btn>  -->
              <svg-icon type="mdi" :path="pathClose" size="32"></svg-icon>
            </v-btn>
          </v-card>
        
          <!--------------------------- Map ---------------------------------->
          <v-card width="100%" :height="H2">
            <GoogleMap v-if="location !== ''" width="100%" height="100%" :location="location"/>
          </v-card>
        </v-card>
      </v-dialog>

  </div>
</template>

<script>
import GoogleMap from '@/components/Common/GoogleMap'
import SvgIcon from '@jamescoyle/vue-icon';
import { mdiMapMarker, mdiClose } from '@mdi/js';

  export default {
    name: 'dialogMap',

    components: {
      GoogleMap, SvgIcon
    },

    props: {
      location: {type: String, default: ''},
      installloc: {type: String, default: ''},
      newWidth: {type: String},
      newHeight: {type: String}
    },

    data () {
      return {
        pathMap: mdiMapMarker,
        pathClose: mdiClose,
        bDialog: false,
        H2: this.newHeight - 20,
        sMsg: ''
      }
    },

    methods: {
      // DESCRIPTION:  first function to call when entering this component
      btnOnDialog () {

      },

      sendEmit () {
        this.$emit('dialogEmit');
        this.bDialog = false;
      }
    },

    mounted() {
      if (this.location === "") {
        this.sMsg = "map address absent";
      }
    }

  }
</script>

<style scoped>


.cBorder {
  border: 1px solid grey;
}

body {
  overflow: hidden;
}


</style>
