<!-- --------------------------------------------------------------------------------
                      DevStatus2BasicInfo.vue

DESCRIPTIONS
  - display Basic Info card

---------------------------------------------------------------------------------- -->
<template>
  <v-card :color="bgcolor" :width="width" height="100%" class="pt-0 rounded-xl">

    <v-sheet :color="bgcolor" height="20px" class="d-flex ma-0 pa-0">
      <v-sheet :color="bgcolor" class="ml-10 mt-1 pb-1 white--text text-body-1 text-no-wrap fontArialB">Basic Info</v-sheet>

      <!-- <dialogDevInfoDetail height="20" width="120" :devObj="DevObj" color="455A64" class="mr-2 mt-1 pa-0" /> -->
      <v-sheet :color="bgcolor" class="ml-5">
        <svg-icon type="mdi" :path="pathInfoSlabCircle" color="green" size="28"></svg-icon>
      </v-sheet>
    </v-sheet>

    <v-list dense :color="bgcolor"  style="height: calc(100% - 25px)"> 
      <v-list-item-group  color="transparent">
        <v-list-item v-for="(item, i) in levels" :key="i" class="pa-0 transparent">
            <v-list-item-content class="pa-0 ml-1 transparent">
              <v-row v-if="item.text != 'message'" no-gutters class="d-flex flex-row pa-0 ml-1 mt-1">
                <v-col cols="4"><span class="grey--text fontArialN" style="font-size: 0.8em;">{{ item.text }}</span></v-col>
                <v-col cols="7"><span :class="item.valueColor" style="font-weight: normal; font-size: 0.8em; font-family: Arial;">{{ item.value }}</span></v-col>
              </v-row>
              <v-row v-else no-gutters class="d-flex flex-row pa-0 ml-1 mt-1">
                <v-sheet :class="getMessageColor(item)" style="font-weight: normal; font-size: 0.9em; font-family: Arial;">{{ item.value }}</v-sheet>
              </v-row>
            </v-list-item-content>
        </v-list-item>
      </v-list-item-group>
    </v-list>

  </v-card>
</template>

<script>
import dialogDevInfoDetail from '@/components/DevInfoPage/dialogDevInfoDetail'
import SvgIcon from '@jamescoyle/vue-icon';
import { mdiInformationSlabCircle } from '@mdi/js';

  export default {
    name: 'DevStatus2BasicInfo',

    components: {
      SvgIcon, dialogDevInfoDetail
    },

    props: {
      title: {type: String, default: ''},
      levels: {type: Array},
      width: {type: String, default: '30%'},
      height: {type: String, default: '60'},
      bgcolor: {type: String, default: 'lime'},
      DevObj: {type: Object, default: null}
    },

    data () {
      return {
        vIcon: {icon: 'mdi-information', color:  "green"},
        pathInfoSlabCircle: mdiInformationSlabCircle
      }
    },

    methods: {
      getMessageColor (xitem) {
        return xitem.valueColor +" transparent";
      }
    }

  }
</script>

<style scoped>



.cBorder {
  border: 1px solid grey;
}

#idCard {
  background-image: linear-gradient(to top left, #37474F, #455A64, #546E7A);
}

.v-list-item {
  min-height: 20px;
}

.fontArialN {
  font-family: Arial; 
  font-style: normal; 
  font-weight: normal;   
  color: black;
}

.fontArialB {
  font-family: Arial; 
  font-style: normal; 
  font-weight: bold;   
  color: black;
}

</style>
