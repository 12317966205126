<!-- --------------------------------------------------------------------------------
                      dialogDevInfoDetail.vue (OBSLETED)

DESCRIPTIONS
  - display more detail device information in "DevInfoDetail.vue"
---------------------------------------------------------------------------------- -->
<template>
  <div class="text-center">

    <v-dialog v-model="bDialog" width="600px">
      <template v-slot:activator="{ on, attrs }">
        <v-btn :color = "color" dark x-small rounded-sm v-bind="attrs" v-on="on" @click="btnOnDialog" class="text-capitalize">More</v-btn>
      </template>

      <v-card width="99%" height="600px" color="grey lighten-4" class="rounded-lg">
        <v-card height="40px" class="border-0 rounded-b-0" style="background-color: #546E7A;">
          <v-btn icon x-small @click="bDialog= false" class="ma-0 pa-0" style="position: absolute; right:1px;">
            <svg-icon type="mdi" :path="pathClose"></svg-icon></v-btn>          
          <v-layout justify-center class="white--text">Device Detail Info</v-layout>
        </v-card>
        <v-divider></v-divider>

        <!------------------------ Content ------------------------>
        <div width="99%" height="100%" class="ml-2 pa-0 mt-1">
          <v-list dense>
          <v-list-item v-for="(item, i) in data" :key="i">
            <!-- <v-list-item-content class="text-left">
              <v-list-item-title id="idList" >{{item.text}}</v-list-item-title>
            </v-list-item-content>
            <v-list-item-content class="text-right">
              <v-list-item-title id="idList" >{{item.value}}</v-list-item-title>
            </v-list-item-content>   -->
            <v-row class = "d-flex flex-row">
              <v-col cols="6" style="border-bottom: 1px solid #E0E5E5">{{ item.text }}</v-col>
              <v-col cols="6" style="border-bottom: 1px solid #E0E5E5">{{ item.value }}</v-col>
            </v-row>
          </v-list-item>
        </v-list>
        </div>        
        
      </v-card>

    </v-dialog>

  </div>
</template>

<script>
import SvgIcon from '@jamescoyle/vue-icon';
import { mdiClose } from '@mdi/js';

  export default {

    props: {
      devObj: {type: Object},
      color: {type: String, default: 'primary'}
    },

    components: {
      SvgIcon
    },

    data () {
      return {
        bDialog: false,
        pathClose: mdiClose,
        data: []
      }
    },

    methods: {
      // DESCRIPTION:  first function to call when entering this component
      btnOnDialog () {
        this.data.length = 0;
        this.data.push({"text": "Device SN:", "value": this.devObj.devSn});
        this.data.push({"text": "Device Model:", "value": this.devObj.devModel});
        this.data.push({"text": "Asset SN:", "value": this.devObj.AssetSn});
        this.data.push({"text": "Customer Name:", "value": this.devObj.CustName});
        this.data.push({"text": "Device Admin:", "value": this.devObj.DevAdmin});
        this.data.push({"text": "Installation Time:", "value": this.devObj.InstTime});
        this.data.push({"text": "Self Test Period:", "value": this.devObj.DevSelfTestPeriod});
        this.data.push({"text": "Self Test Sending Period:", "value": this.devObj.SelfTestSendPeriod});
        this.data.push({"text": "Last Upload Time:", "value": this.devObj.LastReportUploadTime});        
        this.data.push({"text": "Location attribute:", "value": this.devObj.LocAttr});
        this.data.push({"text": "Installation Location:", "value": this.devObj.InstLoc});
        this.data.push({"text": "Current Location:", "value": ""});
      }

    }
  }
</script>

<style scoped>


.cBorder {
  border: 1px solid grey;
}
</style>
