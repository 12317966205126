<template>
  <v-card id="idCard2" class="mt-1 mx-0 pa-0">
    
    <DevTables2 v-if="tableItems === null"  tblHeight="calc(100vh - 220px)" :tblItems="aryEmpty" />
    <DevTables2 v-else :tblHeaders="tableHeader" :tblItems="tableItems" tblHeight="calc(100vh - 220px)"
      :ppSn="pSn" :ppDate1="pDate1" :ppDate2="pDate2" @tblEmit="onEmitTbl2" :trigger2="trigger"/>

  </v-card>
</template>

<script>
// DevTablesSn has clickable devSn, DevTables do not have clickable devSn
//import DevTables from '@/components/DevInfoPage/DevTablesSn'
import DevTables2 from '@/components/DevInfoPage/DevTables2'
import * as Tbl from "@/js/tables.js"

  export default {
    name: 'TblEventFault',

    components: {
      DevTables2
    },

    props: {
      tableItems: {type: Array},
      pSn: {type: String, default: ''},
      pDate1: {type: String, default: ''},
      pDate2: {type: String, default: ''},
      trigger: {type: Number, default: 0}
    },

    data () {
      return {
        tableHeader: Tbl.TblFaultHeaders2,
        aryEmpty: []
        // tableItems:  Samples.FaultData2
      }
    },

    methods: {
      onEmitTbl2(xstr) {
        //console.log("##TblEventFault-112");  //debug
        this.$emit("tblDevEmit", xstr);
      }
    },

    watch: {
    },

    mounted () {
      
    }
  }
</script>

<style>

#idCard2 {
  /* margin-top: 0;
  margin-left: 0;
  margin-right: 0;
  padding: 0;  */
  /* overflow: hidden; */
  background-color: #37474F;
  /* overflow-y: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;  */
}

/*
#idCard2::-webkit-scrollbar {
    display: none;
}
*/

</style>
