<!-- --------------------------------------------------------------------------------
                      DevStatus.vue

DESCRIPTIONS
  - display total warning signals collected

Parameters:
  - levels = [{text: x1, value: y1}, {text: x2, value: y2}, {text: x3, value: y3}]
---------------------------------------------------------------------------------- -->
<template>
  <v-card :color="bgcolor" :width="width" :height="height" class="pt-0 rounded-xl">
    <v-sheet :color="bgcolor" class="text-left ml-2 mt-1 white--text text-body-1 fontArialB">{{ title }}</v-sheet>
    <!-- <v-sheet width="40px" class="mx-auto pa-0 ma-0 transparent"><v-icon large color="green"> {{ `${vIcon}` }}</v-icon></v-sheet> -->
    <!-- <v-sheet width="30px" height="30px" class="mx-auto mt-n2 pa-0 mdi mdi-36px transparent" :class="vIcon.icon" :color="vIcon.color"></v-sheet> -->
    <v-sheet width="30px" height="30px" class="mx-auto mt-n2 pa-0 transparent">
      <v-sheet v-if="iconType===1" width="100%" height="100%" class="mt-2 mx-0 pa-0 transparent">
      <svg-icon v-if="iconLevel===0" type="mdi" :path="pathCellular0" :color="iconColor" size="40"></svg-icon>
      <svg-icon v-else-if="iconLevel===1" type="mdi" :path="pathCellular1" :color="iconColor" size="40"></svg-icon>
      <svg-icon v-else-if="iconLevel===2" type="mdi" :path="pathCellular2" :color="iconColor" size="40"></svg-icon>
      <svg-icon v-else-if="iconLevel===3" type="mdi" :path="pathCellular3" :color="iconColor" size="40"></svg-icon>
      <svg-icon v-else-if="iconLevel > 3" type="mdi" :path="pathNoSim" :color="iconColor" size="40"></svg-icon>
      </v-sheet>

      <v-sheet v-if="iconType===0" width="100%" height="100%" class="mt-2 mx-0 pa-0 transparent">
      <svg-icon v-if="iconLevel===0" type="mdi" :path="pathWifi0" :color="iconColor" size="40"></svg-icon>
      <svg-icon v-else-if="iconLevel===1" type="mdi" :path="pathWifi1" :color="iconColor" size="40"></svg-icon>
      <svg-icon v-else-if="iconLevel===2" type="mdi" :path="pathWifi2" :color="iconColor" size="40"></svg-icon>
      <svg-icon v-else-if="iconLevel===3" type="mdi" :path="pathWifi3" :color="iconColor" size="40"></svg-icon>
      <svg-icon v-else-if="iconLevel > 3" type="mdi" :path="pathWifi4" :color="iconColor" size="40"></svg-icon>
      </v-sheet>
    </v-sheet>    

      <v-list dense :color="bgcolor" class="mt-2"> 
      <v-list-item-group  color="transparent">
        <v-list-item v-for="(item, i) in levels" :key="i" class="pa-0 transparent">
            <v-list-item-content class="pa-0 ml-1 transparent">
              <v-row no-gutters class="d-flex flex-row pa-0 ml-1 mt-1">
                <v-col cols="4"><span class="grey--text fontArialN" style="font-size: 0.9em;">{{ item.text }}</span></v-col>
                <v-col cols="7"><span :class="item.valueColor" style="font-style: bold; font-size: 0.8em; font-family: Arial;">{{ item.value }}</span></v-col>
              </v-row>
            </v-list-item-content>
        </v-list-item>
      </v-list-item-group>
    </v-list>

</v-card>
</template>

<script>
import SvgIcon from '@jamescoyle/vue-icon';
import { mdiSignalCellularOutline, mdiSignalCellular1, mdiSignalCellular2 , mdiSignalCellular3, mdiSimOff, 
  mdiWifiStrengthOutline, mdiWifiStrength1, mdiWifiStrength2, mdiWifiStrength3, mdiWifiStrength4 } from '@mdi/js';

  export default {
    name: 'DevStatus2Nwk',
    components: {
      SvgIcon
    },

    props: {
      title: {type: String, default: ''},
      levels: {type: Array},
      width: {type: String, default: '30%'},
      height: {type: String, default: '60'},
      bgcolor: {type: String, default: 'lime'},
      iconType: {type: Number, default: 0},
      iconLevel: {type: Number, default: 0},
      iconColor: {type: String, default: "green"}
    },

    data () {
      return {
        pathCellular0: mdiSignalCellularOutline,
        pathCellular1: mdiSignalCellular1,
        pathCellular2: mdiSignalCellular2,
        pathCellular3: mdiSignalCellular3,
        pathNoSim: mdiSimOff,
        pathWifi0: mdiWifiStrengthOutline,
        pathWifi1: mdiWifiStrength1,
        pathWifi2: mdiWifiStrength2,
        pathWifi3: mdiWifiStrength3,
        pathWifi4: mdiWifiStrength4
      }
    },

    methods: {
      getListColor (xValue) {
        if (xValue.indexOf('Normal') >=0) {
          return 'green--text';
        } else {
          return 'red--text';
        }
      }

    },

    watch: {
      // iconLevel: function(newVal) { console.log('##iconLevel', newVal); }
    }

  }
</script>

<style scoped>
.cBorder {
  border: 1px solid grey;
}

#idCard {
  background-image: linear-gradient(to top left, #37474F, #455A64, #546E7A);
}

.v-list-item {
  min-height: 20px;
}

.fontArialN {
  font-family: Arial; 
  font-style: normal; 
  font-weight: normal;   
  color: black;
}

.fontArialB {
  font-family: Arial; 
  font-style: normal; 
  font-weight: bold;   
  color: black;
}

</style>
