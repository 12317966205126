<!-- --------------------------------------------------------------------------------
                      DevStatus.vue

DESCRIPTIONS
  - display total warning signals collected

Parameters:
  - levels = [{text: x1, value: y1}, {text: x2, value: y2}, {text: x3, value: y3}]
---------------------------------------------------------------------------------- -->
<template>
  <v-card id="idCard" :width="width" :height="height" class="pt-0 rounded-xl">
    <v-sheet :color="boxColor" class="text-left ml-2 mt-1 pa-0 white--text text-h6 text-decoration-underline fontArialN nowrap">{{ title }}</v-sheet>

      <v-list dense :color="boxColor"> 
      <v-list-item-group>
        <v-list-item  v-for="(item, i) in levels" :key="i" class="pa-0">
            <v-list-item-content class="pa-0 ml-2">
              <!-- <v-list-item-title>{{ item.text }}, {{ item.value }}</v-list-item-title>  -->
              <v-row no-gutters  class="d-flex flex-row pa-0 ml-1">
                <v-col cols="7"><v-sheet class="grey--text transparent fontArialN">{{ item.text }}</v-sheet></v-col>
                <v-col cols="5"><v-sheet :class="getListColor(i, item.value)" 
                  style="font-style: normal; font-size: 1.2em; font-family: Arial;">{{ item.value }}</v-sheet></v-col>
              </v-row>
            </v-list-item-content>
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </v-card>
</template>

<script>
  export default {
    name: 'DevStatus',

    props: {
      title: {type: String, default: ''},
      message: {type: String, default: ''},
      levels: {type: Array},
      width: {type: String, default: '30%'},
      height: {type: String, default: '60'},
      bgcolor: {type: String, default: 'lime'}
    },

    data () {
      return {
        boxColor: "#282828"
      }
    },

    methods: {
      getListColor (xIndex, xValue) {
        // console.log('DevStatus() ', xValue, typeof(xValue));
        let str = "";
        if (typeof(xValue) === Number) str = xValue.toString();
        else str = xValue;
        let ary = str.split(' ');
        if (ary.length >= 1) {
          if (xIndex === 0) {
            if (ary[0] !== "0") return 'red--text transparent cFonts';
            else return 'white--text transparent cFonts';
          //if (ary[0] === '0') return 'white--text transparent cFonts';
          }
        }
        if (xIndex === 0) {
          return 'red--text transparent cFonts';
        } else if (xIndex === 1) {
          return 'yellow--text transparent cFonts';
        } else if (xIndex === 2) {
          return 'green--text transparent cFonts';
        }
      }
    },

    watch: {
      title () {
      }
    }

  }
</script>

<style scoped>

.fontArialN {
  font-family: Arial; 
  font-style: normal; 
  font-weight: normal;   
  color: white;
}

.fontArialB {
  font-family: Arial; 
  font-style: normal; 
  font-weight: bold;   
  color: white;
}

.cBorder {
  border: 1px solid grey;
}

#idCard {
  /* background-image: linear-gradient(to top left, #37474F, #455A64, #546E7A); */
  background-color: #282828;
}

.v-list-item {
  min-height: 30px;
}

</style>
