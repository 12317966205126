import { render, staticRenderFns } from "./LoginLogo1.vue?vue&type=template&id=37cd6078"
import script from "./LoginLogo1.vue?vue&type=script&lang=js"
export * from "./LoginLogo1.vue?vue&type=script&lang=js"
import style0 from "./LoginLogo1.vue?vue&type=style&index=0&id=37cd6078&prod&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports