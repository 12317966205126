<!-- --------------------------------------------------------------------------------
                      dialogUserSetting.vue

DESCRIPTIONS - system settings
  - Email Notification enable
  - Transmission Period:   Daily / Weekly / Monthly
  - Email to:    CC to:

Mindray system settings
  - Pads Expiration Alert:     Time:   1 month    <Save>
  - Email Sending Period:      Every Day / Every Week / Every Month / Stop    <Save>
  - Device Offset Settings:    Device Offset Distance:  More than 5 km        <Save>
  - Voice Alarm Settings:      On / Off           <Save>

---------------------------------------------------------------------------------- -->
<template>
  <div class="text-center">

      <v-dialog v-model="bDialog" content-class="transparent" width="650px" height="auto" class="transparent overflow-hidden">

        <!---------------------------------- Container Dialog --------------------------->
        <v-card width="100%" :height="hPage" class="rounded-xl overflow-hidden">
          
          <!------------------------------- (1) Header ------------------------------------>         
          <v-card height="40px" class="border-0 rounded-b-0" style="background-color: #546E7A;">
            <v-btn icon small color="white" @click="bDialog= false" class="ma-1 pa-0" style="position: absolute; right:1px;">
              <svg-icon type="mdi" :path="pathClose" size="32"></svg-icon>
            </v-btn> 
            <v-layout justify-center class="pt-1 white--text text-h5 fontArialB">{{ pTitle }}</v-layout>
          </v-card>

          <v-divider></v-divider>
          
          <!------------------------------- (2) Upper Container --------------------------->   
          <v-card width="100%" class="d-flex border-0 elevation-0 rounded-0">

          <v-card width="50%" class="border-0 elevation-0">
            <!------------------------------- (2a) Email Notification --------------------->
            <!-- style="border-bottom: 1px solid #E0E0E0;" -->
            <v-card-title class="ml-2 mt-1 mb-0 pa-0 fontArialB">Email Settings</v-card-title>

            <v-sheet class="ml-6 mt-1 text-subtitle-2 fontArialB">Fault Notification</v-sheet>
            <v-row class="ml-6 ma-0 pa-0">
              <v-col cols="3" height="20px" class="ma-0 py-0 pa-0"><v-sheet class="ml-2 mt-0 text-body-2 fontArialN">Enable</v-sheet></v-col>
              <v-col cols="7" height="20px" class="pa-0">
                <v-radio-group row dense v-model="radioFault" mandatory class="ml-4 mt-0 ma-0 fontArialN">
                  <v-radio label="On" value="ON"></v-radio>
                  <v-radio label="Off" value="OFF" class="ml-4"></v-radio>
                </v-radio-group>
              </v-col>
            </v-row>

            <v-sheet class="ml-6 mt-n1 text-subtitle-2 fontArialB">Rescue Notification</v-sheet>
            <v-row class="ml-6 ma-0 pa-0">
              <v-col cols="3" height="20px" class="ma-0 py-0 pa-0"><v-sheet class="ml-2 mt-0 text-body-2 fontArialN">Enable</v-sheet></v-col>
              <v-col cols="7" height="20px" class="pa-0">
                <v-radio-group row dense v-model="radioRescue" mandatory class="ml-4 mt-0 ma-0 fontArialN">
                  <v-radio label="On" value="ON"></v-radio>
                  <v-radio label="Off" value="OFF" class="ml-4"></v-radio>
                </v-radio-group>
              </v-col>
            </v-row>
          </v-card>

          <v-card width="50%" class="border-l1 elevation-0" style="border-left: 1px solid #E0E0E0;">
            <!------------------------------- (2b) Transmission Period --------------------->
            <v-sheet class="ml-6 mt-12 text-subtitle-2 fontArialB">Email Transmission Settings</v-sheet>
            <v-sheet class="ml-8 mt-0 text-body-2 fontArialB">Transmit Period</v-sheet>
            <v-sheet width="200" class="ma-0 pa-0">
            <!-- <v-select dense height="20px" outlined v-model="selectPeriod" 
              :items="itemPeriod" label="Select period"
              class="ml-8 mt-2 rounded-lg blue--text" @change="onChangeCmb"></v-select>
            </v-sheet> -->
            <v-select dense height="20px" outlined v-model="selectPeriod" 
              :items="itemPeriod" item-color="blue" label="Select period"
              class="ml-8 mt-2 rounded-lg fontArialN"  @change="onChangeCmb">
            </v-select>
            </v-sheet>            
          </v-card>

          </v-card>

          <!------------------------------- (3) Email COntainer ---------------------------->
          <v-card class="mt-n3 pa-1 elevation-0 transparent">
            <v-sheet class="ml-5 mt-0 pa-0 text-subtitle-2 fontArialB">Email Address</v-sheet>

            <!------------------------------- (3a) Email To ---------------------------->
            <v-card class="ml-6 my-0 pa-0 elevation-0">
              <v-row height="20px" class="ma-0 pa-0">
                <v-col cols="3" height="100%" class="ml-2 mt-2 pa-0 text-body-2 fontArialN">Email To:</v-col>

                <!-- <v-col cols="6" height="100%" class="ma-0 pa-0"><v-sheet class="border-1 rounded-xl">{{ emailTo }}</v-sheet></v-col> -->
                <v-col cols="6" height="100%" class="ma-0 pa-0">
                  <v-text-field regular dense light width="100" placeholder="email address" type="input" v-model="sInputEm" hide-details
                    class="mt-1 text-decloration-none elevation-3 rounded-lg border fontArialN" outlined :spellcheck="false">
                  </v-text-field>
                </v-col>

                <v-col cols="2" height="20px" class="ma-0 pa-0">
                  <v-btn small width="100%" height="30px" class="ml-2 mt-2 pa-1 my-auto text-lowercase rounded text-body-2
                  light-blue lighten-5 font-weight-bold elevation-3 fontArialB" @click="onBtnAddEmail">add email</v-btn>
                </v-col>
              </v-row>              
            </v-card>

            <v-card v-if="showEmail===true" height="100%" class="mt-n1 pa-0 border-0 elevation-0 transparent">            
            <v-sheet width="90%" height="100px" max-height="60px" class="mx-auto mt-2 rounded-lg overflow-auto" style="border: 1px solid grey;">
              <v-list dense color="transparent" class="ma-0 pa-0 fontArialN"> 
                <v-list-item-group>
                  <v-list-item v-for="(item, i) in aryEmail" :key="i" class="pa-0" @click="getListItemEm(i)" >
                    <v-list-item-content class="pa-0 ml-2">
                      <v-sheet class="d-flex">
                      <v-sheet class="ma-0 pa-0 black--text text-body-2">{{ item }}</v-sheet>
                      <v-spacer></v-spacer>
                      <v-btn x-small height="18" color="blue" class="mt-0 mr-2 py-auto px-2 white--text text-caption
                        text-capitalize rounded fontArialN" @click="onBtnDelEmail(i)">Delete</v-btn>
                      </v-sheet>
                    </v-list-item-content>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
            </v-sheet>
            </v-card>

            <!------------------------------- (3b) CC to ---------------------------->
            <v-card class="ml-6 mt-1 pa-0 d-flex elevation-0">
              <v-col cols="3" height="100%" class="ml-2 mt-2 pa-0 text-body-2 fontArialN">cc to:</v-col>
              <v-col cols="6" height="100%" class="ma-0 pa-0">
                <v-text-field regular dense light placeholder="email address" type="input" v-model="sInputCC" hide-details
                  class="mt-1 text-decloration-none elevation-3 rounded-lg border fontArialN" outlined :spellcheck="false">
                </v-text-field>
              </v-col>
              <v-col cols="2" height="20px" class="ma-0 pa-0">
                <v-btn small width="100%" height="30px" class="ml-2 mt-2 pa-1 my-auto text-lowercase rounded-lg text-body-2
                light-blue lighten-5 font-weight-bold elevation-3 fontArialB" @click="onBtnAddCC">add cc</v-btn>
              </v-col>
            </v-card>

            <v-card v-if="showCC===true" height="100%" class="mt-n1 pa-0 border-0 elevation-0 transparent">            
              <v-sheet width="90%" height="100px" max-height="60px" class="mx-auto mt-2 rounded-lg overflow-auto" style="border: 1px solid grey;">
              <v-list dense color="transparent" class="ma-0 pa-0 fontArialN"> 
                <v-list-item-group>
                  <v-list-item v-for="(item, i) in aryCC" :key="i" class="pa-0" @click="getListItemCC(i)">
                    <v-list-item-content class="pa-0 ml-2">
                      <v-sheet class="d-flex">
                      <v-sheet class="ma-0 pa-0 black--text text-body-2">{{ item }}</v-sheet>
                      <v-spacer></v-spacer>
                      <v-btn small height="18" color="blue" class="mt-0 mr-2 py-auto px-2 white--text text-caption
                        text-capitalize rounded fontArialN" @click="onBtnDelCC(i)">Delete</v-btn>
                      </v-sheet>
                    </v-list-item-content>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
              </v-sheet>
            </v-card>

            <!------------------------------- (3c) Rescue CC to ------------------------>
            <v-card class="ml-6 mt-1 pa-0 d-flex elevation-0">
              <v-col cols="3" height="100%" class="ml-2 mt-2 pa-0 text-body-2 fontArialN">Rescue cc to:</v-col>
              <v-col cols="6" height="100%" class="ma-0 pa-0">
                <v-text-field regular dense light placeholder="email address" type="input" v-model="sInputResc" hide-details
                  class="mt-1 text-decloration-none elevation-3 rounded-lg border fontArialN" outlined :spellcheck="false">
                </v-text-field>
              </v-col>
              <v-col cols="2" height="20px" class="ma-0 pa-0">
                <v-btn small width="100%" height="30px" class="ml-2 mt-2 pa-1 my-auto text-lowercase rounded-lg text-body-2
                light-blue lighten-5 font-weight-bold elevation-3 fontArialB" @click="onBtnAddResc">add rescue</v-btn>
              </v-col>
            </v-card>

            <v-card v-if="showResc===true" height="100%" class="mt-n1 pa-0 border-0 elevation-0 transparent">            
              <v-sheet width="90%" height="100px" max-height="60px" class="mx-auto mt-2 rounded-lg overflow-auto" style="border: 1px solid grey;">
              <v-list dense color="transparent" class="ma-0 pa-0 fontArialN"> 
                <v-list-item-group>
                  <v-list-item v-for="(item, i) in aryRescue" :key="i" class="pa-0" @click="getListItemResc(i)">
                    <v-list-item-content class="pa-0 ml-2">
                      <v-sheet class="d-flex">
                      <v-sheet class="ma-0 pa-0 black--text text-body-2">{{ item }}</v-sheet>
                      <v-spacer></v-spacer>
                      <v-btn small height="18" color="blue" class="mt-0 mr-2 py-auto px-2 white--text text-caption
                        text-capitalize rounded fontArialN" @click="onBtnDelResc(i)">Delete</v-btn>
                      </v-sheet>
                    </v-list-item-content>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
              </v-sheet>
            </v-card>

          </v-card>

          <!------------------------------ Bottom ----------------------------------->
          
          <!-- <v-card width="90%" class="d-flex mx-auto mt-2 border-0 elevation-0">
            <v-card width="80%" height="40px" class="ml-0 my-auto border-1 elevation-0">
              <inputBox2 label="" message="Enter Email" width1="0%" width2="90%" @msgInputBox1="onInputBox2"/>
            </v-card>            
            <v-btn small width="100px" height="90%" class="ml-2 pa-2 my-auto text-capitalize rounded blue-grey lighten-4" @click="onBtnAddEmail">Add Email</v-btn>
          </v-card> 
            <v-btn small width="100px" height="30" class="ml-2 mr-7 pa-2 my-auto text-capitalize rounded green white--text" 
            @click="onBtnSendReport" style="position: absolute; right:0;">Send Report</v-btn>  -->

          <v-sheet class="mt-2">
            <v-btn small width="100px" height="30" class="ml-2 mr-9 pa-2 my-auto text-capitalize rounded-lg green white--text fontArialB" 
            @click="onBtnSave" style="position: absolute; right:0;">Save</v-btn> 
          </v-sheet>

        </v-card>

        <v-snackbar right v-model="snackBar.on" :color="snackBar.color" class="fontArialB">{{ snackBar.message }}</v-snackbar>
      </v-dialog>

  </div>
</template>

<script>
import axios from 'axios'
import SvgIcon from '@jamescoyle/vue-icon';
import { mdiClose } from '@mdi/js';

import cookies from '@/js/Cookie.js'
import inputBox2 from '@/components/Common/InputBox2'


  export default {
    components: {
      SvgIcon, inputBox2
    },

    props: {
      pTitle: {type: String, default: "Message"},
      pAct: {type: Boolean, default: false},
      aryDev: {type: Array}
    },

    data () {
      return {
        bDialog: false,
        hPageInit: 620,
        hPage: 620, // pagelength
        hOffset: 60,
        pathClose: mdiClose,
        urlEmail: '/client/api/scheduleEmail',
        btnClass: 'mt-1 ml-1 white--text text-capitalize',
        radioFault: "OFF",
        selectPeriod: "",
        itemPeriod: ['daily', 'weekly', 'monthly'],
        radioRescue: "OFF",

        selectedItemEm: 0,  // email to
        selectedItemCC: 0,  // CC to
        selectedItemResc: 0,
        sInputEm: "",
        sInputCC: '',
        sInputResc: '',
        emailAcc: "", // current login user email addr
        aryEmail: [],
        aryCC: [],
        aryRescue: [],
        showCC: false,
        showEmail: false,
        showResc: false,
        snackBar: {"on": false, "color": "", "message": ""}
      }
    },

    methods: {
      // DESCRIPTION:  first function to call when entering this component
      btnOnDialog () {

      },

      onChangeCmb (xstr) {
        console.log("##onChangeCmb", xstr, this.aryDev);  //debug
      },

      // DESCRIPTION:  add to email address list
      onBtnAddEmail() {
        if (this.sInputEm.length === 0) {
          return;
        }
        if (this.sInputEm.trim() !== "") {
          this.aryEmail.push(this.sInputEm);
          if (!this.showEmail) this.hPage = this.hPage + this.hOffset;
          this.showEmail = true;
        }
      },

      // DESCRIPTION: add to email cc list
      onBtnAddCC() {
        if (this.sInputCC.length === 0) {
          return;
        }
        //if (this.validateEmail(this.sInput)) {
        if (this.sInputCC.trim() !== "") {
          this.aryCC.push(this.sInputCC);
          if (!this.showCC) this.hPage = this.hPage + this.hOffset;
          this.showCC = true;
        }
      },

      // DESCRIPTION: add to email Rescue cc list
      onBtnAddResc() {
        if (this.sInputResc.length === 0) {
          return;
        }
        //if (this.validateEmail(this.sInput)) {
        if (this.sInputResc.trim() !== "") {
          this.aryRescue.push(this.sInputResc);
          if (!this.showResc) this.hPage = this.hPage + this.hOffset;
          this.showResc = true;
        }
      },      

      // DESCRIPTION:  delete selected email addr
      onBtnDelEmail(xidx) {
        //if (this.selectedItemEm >= 0 && this.selectedItemEm < this.aryEmail.length) {
        if (xidx >= 0 && xidx < this.aryEmail.length) {
          this.aryEmail.splice(xidx, 1);
          if (this.aryEmail === null) this.showEmail = false;
          else {
            if (this.aryEmail.length === 0) this.showEmail = false;
          }
          if (!this.showEmail) {
            this.hPage = this.hPage - this.hOffset;
          }          
        }
      },

      // DESCRIPTION:  delete selected email addr
      onBtnDelCC(xidx) {
        if (xidx >= 0 && xidx < this.aryCC.length) {
          this.aryCC.splice(xidx, 1);
          if (this.aryCC === null) this.showCC = false;
          else {
            if (this.aryCC.length === 0) this.showCC = false;
          }
          if (!this.showCC) {
            this.hPage = this.hPage - this.hOffset;
          }
        }
      },

      // DESCRIPTION:  delete selected email addr
      onBtnDelResc(xidx) {
        if (xidx >= 0 && xidx < this.aryRescue.length) {
          this.aryRescue.splice(xidx, 1);
          if (this.aryRescue === null) this.showResc = false;
          else {
            if (this.aryRescue.length === 0) this.showResc = false;
          }
          if (!this.showResc) {
            this.hPage = this.hPage - this.hOffset;
          }
        }
      },      

      getListItemEm(xidx) {
        this.selectedItemEm = xidx;
      },

      getListItemCC(xidx) {
        this.selectedItemCC = xidx;
      },

      getListItemResc(xidx) {
        this.selectedItemResc = xidx;
      },

      // DESCRIPTION:  save email addresses to SQL
      onBtnSave () {
        //this.axiosGetEmailInfo();
        this.axiosPostEmailInfo();
      },

      onBtnSendReport () {
        /* if (this.aryDev === null || this.aryDev.length === 0) {
          this.snackBarStart("error", "Please Select Device(s)");
          return;
        } 
        let arySn = [];
        for(let idx=0; idx < this.aryDev.length; idx++) {
          arySn.push(this.aryDev[idx].devSn);
        }
        let vObj = {
          //"sn": ["AE9-13000936","AE9-41008822T"],
          "sn": arySn,
          "email": {
            "emailName": "testUser",
            "to": this.emailTo,
            "cc": this.aryEmail.toString()
          }
        };
        if (this.aryEmail !== null && this.aryEmail.length === 0) {
          vObj.email.cc = "";
        }

        if (this.radioGroup === "1") {
          // Self Test report
          this.axiosEmailSelfTest(vObj);
        } else if (this.radioGroup === "2") {
          // Device Status report
          this.axiosEmailDevStatus(vObj);
        }  */
      },

      //--------------------------------------------------------
      //          [axios]
      //--------------------------------------------------------      
      // DESCRIPTION:  get email info from SQL, executed at mount()
      async axiosGetEmailInfo () {
        let vObj = {
          'method': 'GET',
          'url': this.$config.apiBaseUrl + this.urlEmail,
          timeout: 3000,
          'headers': {
            'X-Requested-With': 'XMLHttpRequest',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + cookies.get('kk')
          }
        }

        try {
          let resp = await axios.request(vObj.url, vObj);
          if (resp != null) {
            let vStatus = resp.data.status;
            if (vStatus === 'SUCCESS') {
              if (resp.data.data !== null) {
                let ret = resp.data.data;
                if (ret.to === "") this.aryEmail = [];
                else this.aryEmail = ret.to.split(",");
                if (ret.cc === "") this.aryCC = [];
                else this.aryCC = ret.cc.split(",");
                if (ret.rescueCC === "") this.aryRescue = [];
                else this.aryRescue = ret.rescueCC.split(",");
                if (ret.enable) this.radioFault = "ON";
                else this.radioFault = "OFF";
                if (ret.rescueEnable) this.radioRescue = "ON";
                else this.radioRescue = "OFF";
                if (ret.period !== undefined && ret.period !== "") {
                  this.itemPeriod.forEach((element) => {
                    if (element === ret.period) this.selectPeriod = element;
                  });
                }
                if (this.aryEmail.length > 0) this.showEmail = true;
                else {
                  this.showEmail = false;
                  this.hPage = this.hPage - this.hOffset;
                }
                if (this.aryCC.length > 0) this.showCC = true; 
                else {
                  this.showCC = false;
                  this.hPage = this.hPage - this.hOffset;
                }
                if (this.aryRescue.length > 0) this.showResc = true; 
                else {
                  this.showResc = false;
                  this.hPage = this.hPage - this.hOffset;
                }
              } 
            } else {
              console.log('#axiosGetEmailInfo(1) error: axios data request fail');
            }  
            this.showProgress = false;
          } else {
            console.log('#axiosGetEmailInfo(2) error: receive null data');
            this.showProgress = false;
          }
        } catch (error) {
          this.showProgress = false;
          console.error('#axiosGetEmailInfo(3) error: ', error.message);
          if (error.message.indexOf('401') > 0) {
            this.snackStart(this.snackMsgLogout, 'error');
            // this.$root.$emit('App_Logout', "115");
          } else if (error.message.indexOf('Network Error') > 0 || error.message === 'undefined') {
            this.snackStart("Cannot connect to Server", 'error');
          }
        }
      },

      // DESCRIPTION:  store email info to SQL, excuted at onBtnSave()
      async axiosPostEmailInfo () {
        let vObj = {
          'method': 'POST',
          'url': this.$config.apiBaseUrl + this.urlEmail,
          timeout: 3000,
          'headers': {
            'X-Requested-With': 'XMLHttpRequest',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + cookies.get('kk')
          },
          data: {"to": "", "cc": "", "period": "", "enable": 0, "rescueCC": "", "rescueEnable": 0 }
        }

        if (this.showEmail) vObj.data.to = this.aryEmail.toString();
        if (this.showCC) vObj.data.cc = this.aryCC.toString();
        if (this.showResc) vObj.data.rescueCC = this.aryRescue.toString();
        if (this.selectPeriod !== "") vObj.data.period = this.selectPeriod;
        if (this.radioFault === "ON") vObj.data.enable = 1;
        else vObj.data.enable = 0;
        if (this.radioRescue === "ON") vObj.data.rescueEnable = 1;
        else vObj.data.rescueEnable = 0;

        try {
          let resp = await axios.request(vObj.url, vObj);
          if (resp != null) {
            let vStatus = resp.data.status;
            if (vStatus === 'SUCCESS') {
              this.bDialog = false;   // save & close dialog
            } else {
              console.log('#axiosSendSelfTest(1) error: axios data request fail');
            }  
            this.showProgress = false;
          } else {
            console.log('#axiosSendSelfTest(2) error: receive null data');
            this.showProgress = false;
          }
        } catch (error) {
          this.showProgress = false;
          console.error('#axiosSendSelfTest(3) error: ', error.message);
          if (error.message.indexOf('401') > 0) {
            this.snackStart(this.snackMsgLogout, 'error');
            // this.$root.$emit('App_Logout', "115");
          } else if (error.message.indexOf('Network Error') > 0 || error.message === 'undefined') {
            this.snackStart("Cannot connect to Server", 'error');
          }
        }
      },


      //--------------------------------------------------------
      //          [snackBar]
      //--------------------------------------------------------
      onSnackBarTimeout() {
        this.snackBar.on = false;
      },

      snackBarStart(xcolor, xmsg) {
        this.snackBar= {"on": true, "color": xcolor, "message": xmsg};
        setTimeout(this.onSnackBarTimeout, 3000);
      }
    
    },

    watch: {
      pAct: function (xflag) {
        this.bDialog = xflag; 
      },

      bDialog: function (newValue) {
        if (!newValue) {
           this.$emit('DialogUserSettingClose');
        } else {
          //console.log("##dialogUserSetting-watch");  //debug*
          this.hPage = this.hPageInit;
          this.sInputEm = "";
          this.sInputCC = "";
          this.sInputResc = "";
          this.axiosGetEmailInfo();  // reload data at dialog startup
        }
      }
    },

    mounted() {
      //let vObj = this.$cookies.get("us");
      if (!cookies.exist('us')) return;
      let str = cookies.get('us');
      let vObj = JSON.parse(str);
      if (vObj !== null) {       
        if (vObj.email !== null) { 
          this.emailAcc = vObj.email;
          //this.aryEmail.push(vObj.email);  // CC email
        }
        else {
          if (vObj.email1 !== null) this.emailAcc = vObj.email1;
          else {
            if (vObj.email2 !== null) this.emailAcc = vObj.email2;
          }
        }
      }

      //this.emailTo = this.emailAcc;
      //this.selectPeriod = "Weekly";
      //this.selectedEmail = "Device Status";
      
    }  
  }
</script>

<style scoped>

.v-list-item { 
  min-height: 8px;
}


.fontVerdana {
  font-family: Verdana; 
  font-style: normal; 
  font-weight: bold;   
  color: #FDD035;
}

.fontArialN {
  font-family: Arial; 
  font-style: normal; 
  font-weight: normal;   
  color: black;
}

.fontArialB {
  font-family: Arial; 
  font-style: normal; 
  font-weight: bold;   
  color: black;
}


#idCard1 {
  margin-top: 0;
  margin-left: 0;
  margin-right: 0;
  overflow-y: hidden;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */  
}

#idCard1::-webkit-scrollbar {
    display: none;
}

</style>
