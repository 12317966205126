<!-- --------------------------------------------------------------------------------
                      DevStatus.vue

DESCRIPTIONS
  - display total warning signals collected

Parameters:
  - levels = [{text: x1, value: y1}, {text: x2, value: y2}, {text: x3, value: y3}]
---------------------------------------------------------------------------------- -->
<template>
  <v-card  :width="width" :height="height" :color="bgcolor" class="pt-0 rounded-xl">
    <v-sheet :color="bgcolor" class="text-left ml-2 mt-1 white--text text-body-1 elevation-0 border-0 fontArialB">{{ title }}</v-sheet>
    
    <!-- <v-sheet :color="bgcolor" width="40px" class="mx-auto pa-0 ma-0"><v-icon large :color="vIcon.color"> {{ `${vIcon.icon}` }}</v-icon></v-sheet> -->
    <v-sheet :color="bgcolor" width="40px" class="mx-auto pa-0 ma-0">

      <v-sheet v-if="iconType==='battery'" width="100%" height="100%" color="transparent">
        <svg-icon v-if="iconLevel===0" type="mdi" :path="pathBattAlert" :color="iconColor" size="40"></svg-icon>
        <svg-icon v-if="iconLevel===1" type="mdi" :path="pathBatt20" :color="iconColor" size="40"></svg-icon>
        <svg-icon v-if="iconLevel===2" type="mdi" :path="pathBatt40" :color="iconColor" size="40"></svg-icon>
        <svg-icon v-if="iconLevel===3" type="mdi" :path="pathBatt60" :color="iconColor" size="40"></svg-icon>
        <svg-icon v-if="iconLevel===4" type="mdi" :path="pathBatt80" :color="iconColor" size="40"></svg-icon>
        <svg-icon v-if="iconLevel===5" type="mdi" :path="pathBatt100" :color="iconColor" size="40"></svg-icon>
      </v-sheet>

      <!-- level: 0=fail, 1=pass, 2=hidden   -->
      <v-sheet v-else width="100%" height="100%" color="transparent">
        <svg-icon v-if="iconLevel===1" type="mdi" :path="pathCheckCircle" :color="iconColor" size="40"></svg-icon>
        <svg-icon v-else-if="iconLevel===0" type="mdi" :path="pathCloseCircle" :color="iconColor" size="40"></svg-icon>
      </v-sheet>

    </v-sheet>

      <v-list dense :color="bgcolor"> 
      <v-list-item-group>
        <v-list-item v-for="(item, i) in levels" :key="i" class="pa-0">
            <v-list-item-content class="pa-0 ml-2">
              <v-row no-gutters class="d-flex flex-row pa-0 ml-0">
                <v-col cols="7"><span class="grey--text fontArialN" style="font-size: 0.9em;">{{ item.text }}</span></v-col>
                <v-col cols="5"><span :class="item.valueColor" style="font-weight: normal; font-size: 0.8em; font-family: Arial;">{{ item.value }}</span></v-col>
              </v-row>
            </v-list-item-content>
        </v-list-item>
      </v-list-item-group>
    </v-list>

</v-card>
</template>

<script>
import SvgIcon from '@jamescoyle/vue-icon';
import { mdiCheckCircle, mdiInformation, mdiCloseCircle, mdiBatteryAlert,
  mdiBatteryOutline, mdiBattery20, mdiBattery40, mdiBattery60, mdiBattery80, mdiBattery } from '@mdi/js';

  export default {
    name: 'DevStatus',

    components: {
      SvgIcon
    },

    props: {
      title: {type: String, default: ''},
      levels: {type: Array},
      width: {type: String, default: '30%'},
      height: {type: String, default: '60'},
      bgcolor: {type: String, default: 'lime'},
      vIcon: {type: Object, default: null},
      iconType: {type: String, default: "generic"},
      iconLevel: {type: Number, default: 0},
      iconColor: {type: String, default: "green"}
    },

    data () {
      return {
        pathCheckCircle: mdiCheckCircle,
        pathCloseCircle: mdiCloseCircle,
        pathInfo: mdiInformation,
        pathBatt0: mdiBatteryOutline,
        pathBatt20: mdiBattery20,
        pathBatt40: mdiBattery40,
        pathBatt60: mdiBattery60,
        pathBatt80: mdiBattery80,
        pathBatt100: mdiBattery,
        pathBattAlert: mdiBatteryAlert
      }
    },

    methods: {
      getListColor (xValue) {
        if (xValue.indexOf('Normal') >=0) {
          return 'green--text';
        } else {
          return 'red--text';
        }
      }
    },

    watch: {
      title () {
      }
    }

  }
</script>

<style scoped>

.cBorder {
  border: 1px solid grey;
}

#idCard {
  background-image: linear-gradient(to top left, #37474F, #455A64, #546E7A);
}

.v-list-item {
  min-height: 20px;
}

.fontArialN {
  font-family: Arial; 
  font-style: normal; 
  font-weight: normal;   
  color: black;
}

.fontArialB {
  font-family: Arial; 
  font-style: normal; 
  font-weight: bold;   
  color: black;
}


</style>
