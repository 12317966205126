<!-- --------------------------------------------------------------------------------
                      dialogDevOptions.vue (OBSELETED)

DESCRIPTIONS
  - set options to send email
---------------------------------------------------------------------------------- -->
<template>
  <div class="text-center">

      <v-dialog v-model="bDialog" content-class="my-custom-dialog" width="600px" height="auto">
        <template  v-slot:activator="{ on, attrs }">
          <v-btn width="140px" height="22px" color="#37474F" :class="btnClass" v-bind="attrs" v-on="on" 
          @click="btnOnDialog">Send Device Info</v-btn>
        </template>

        <v-card width="100%" height="500px" class="rounded-lg overflow-hidden">
          <!------------------------------- Header ------------------------------------>         
          <v-card height="40px" class="border-0 rounded-b-0" style="background-color: #546E7A;">
            <v-btn icon small @click="bDialog= false" class="ma-1 pa-0" style="position: absolute; right:1px;">
              <!-- <v-icon color="black">mdi-close</v-icon>  -->
              <svg-icon type="mdi" :path="pathClose" size="32"></svg-icon>
            </v-btn> 
            <v-layout justify-center class="pt-1 white--text text-h5">Send Device Status</v-layout>
          </v-card>
          <v-divider></v-divider>
          
          <v-card width="100%" class="border-0 elevation-0 rounded-0">
            <!------------------------------- Top Button Bar ---------------------------->
            <v-card height="50px" class="d-flex mx-2 mt-1 ma-0">
              <v-radio-group row v-model="radioGroup" mandatory class="ml-4 mt-1 ma-0">
                <v-radio label="Send Self-test Report" value="1"></v-radio>
                <v-radio label="Send Device Status" value="2"></v-radio>
              </v-radio-group>

              <v-spacer></v-spacer>

              <!--
              <v-card width="150px" height="40px" class="mr-2 mt-3 blue-grey lighten-4 rounded elevation-5 text-h4">
                <DatePicker title="Start Date" :dayback=-90 @DatePicker="onDateChange1"/>
              </v-card>

              <v-card width="150px" height="40px" class="mr-2 mt-3 blue-grey lighten-4 rounded elevation-5 text-h4">
                <DatePicker title="End Date" @DatePicker="onDateChange2"/>
              </v-card>  -->
            </v-card>

            <!------------------------------- Content ---------------------------------->
            <v-card class="pa-1 border-0 elevation-0 transparent">
              
              <v-card width="90%" class="d-flex flex-row ml-2 mt-0 border-0 elevation-0">
                <h4 class="ml-0 mt-4">To:</h4>
                <v-sheet class="ml-10 mt-4">{{ emailTo }}</v-sheet>
              </v-card>

              <h4 class="ml-2 mt-2">CC To:</h4>
              <v-sheet width="90%" height="200px" max-height="200px" class="mx-auto mt-2 rounded-lg overflow-y-auto" style="border: 1px solid grey;">
                <v-list dense color="transparent"> 
                  <v-list-item-group>
                    <v-list-item v-for="(item, i) in aryEmail" :key="i" class="pa-0" @click="getListItem(i)" style="min-height: 25px;">
                      <v-list-item-content class="pa-0 ml-2">
                        <v-sheet class="d-flex">
                        <v-sheet class="ma-0 pa-0">{{ item }}</v-sheet>
                        <v-spacer></v-spacer>
                        <v-btn x-small color="blue-grey lighten-4" class="mr-2 black--text text-body-2 text-capitalize rounded" 
                          @click="onBtnDelEmail2(i)">Delete</v-btn>
                        </v-sheet>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list-item-group>
                </v-list>
              </v-sheet>
            </v-card>

            <!------------------------------ Bottom ----------------------------------->
            <v-card width="90%" class="d-flex mx-auto mt-2 border-0 elevation-0">
              <v-card width="80%" height="40px" class="ml-0 my-auto border-1 elevation-0">
                <inputBox2 label="" message="Enter Email" width1="0%" width2="90%" @msgInputBox1="onInputBox2"/>
              </v-card>            
              <v-btn small width="100px" height="90%" class="ml-2 pa-2 my-auto text-capitalize rounded blue-grey lighten-4" @click="onBtnAddEmail">Add Email</v-btn>
            </v-card> 
              <v-btn small width="100px" height="30" class="ml-2 mr-7 pa-2 my-auto text-capitalize rounded green white--text" 
              @click="onBtnSendReport" style="position: absolute; right:0;">Send Report</v-btn>

          </v-card>

        </v-card>

        <v-snackbar right v-model="snackBar.on" :color="snackBar.color">{{ snackBar.message }}</v-snackbar>
      </v-dialog>

  </div>
</template>

<script>
import axios from 'axios'
import cookies from '@/js/Cookie.js'
import inputBox2 from '@/components/Common/InputBox2'
import DatePicker from '@/components/Common/DatePicker'

import SvgIcon from '@jamescoyle/vue-icon'
import { mdiClose } from '@mdi/js';

  export default {
    components: {
      SvgIcon, inputBox2, DatePicker
    },

    props: {
      aryDev: {type: Array}
    },

    data () {
      return {
        bDialog: false,
        axiosPathEmailSelfTest: '/smtp/sendSelfTest',
        axiosPathEmailDevStatus: '/smtp/sendDeviceStatus',
        pathClose: mdiClose,
        btnClass: 'mt-1 ml-1 white--text text-capitalize',
        radioGroup: "1",
        selectedItem: 0,
        sInput: '',
        snackBar: {"on": false, "color": "", "message": ""},
        emailTo: '',
        //aryEmail: ['calvin@medisource-asia.com', 'gary@medisource-asia.com']
        aryEmail: []
      }
    },

    methods: {
      // DESCRIPTION:  first function to call when entering this component
      btnOnDialog () {

      },

      onInputBox2 (xobj) {
        this.sInput = xobj.text.trim();
      },

      onBtnAddEmail() {
        if (this.sInput.length === 0) {
          return;
        }
        //if (this.validateEmail(this.sInput)) {
        if (this.sInput.trim() !== "") {
          this.aryEmail.push(this.sInput);
        }
      },

      // DESCRIPTION:  delete selected email (obsoleted)
      onBtnDelEmail2(xidx) {
        if (this.selectedItem >= 0 && this.selectedItem < this.aryEmail.length) {
          this.aryEmail.splice(this.selectedItem, 1);
        }
      },

      getListItem(xidx) {
        this.selectedItem = xidx;
      },

      onBtnSendReport () {
        if (this.aryDev === null || this.aryDev.length === 0) {
          this.snackBarStart("error", "Please Select Device(s)");
          return;
        } 
        let arySn = [];
        for(let idx=0; idx < this.aryDev.length; idx++) {
          arySn.push(this.aryDev[idx].devSn);
        }
        let vObj = {
          //"sn": ["AE9-13000936","AE9-41008822T"],
          "sn": arySn,
          "email": {
            "emailName": "testUser",
            "to": this.emailTo,
            "cc": this.aryEmail.toString()
          }
        };
        if (this.aryEmail !== null && this.aryEmail.length === 0) {
          vObj.email.cc = "";
        }

        if (this.radioGroup === "1") {
          // Self Test report
          this.axiosEmailSelfTest(vObj);
        } else if (this.radioGroup === "2") {
          // Device Status report
          this.axiosEmailDevStatus(vObj);
        }
      },

      onDateChange1 (xDate) {
        this.DateRange.start = xDate;
        //console.log(this.DateRange.start);
      },

      onDateChange2 (xDate) {
        this.DateRange.end = xDate;
        //console.log(this.DateRange.end);
      },

      validateEmail(xemail) {
        //console.log('validateEmail', xemail);
        return true;
      },

      onSnackBarTimeout() {
        this.snackBar.on = false;
      },

      snackBarStart(xcolor, xmsg) {
        this.snackBar= {"on": true, "color": xcolor, "message": xmsg};
        setTimeout(this.onSnackBarTimeout, 3000);
      },

      
    // ----------------------------------------------------------------------
    //              [Axios]
    // 
    // ----------------------------------------------------------------------

      // ---------------------------------------------
      // DESCRIPTION:  Insert new device info:  
      //   <Add Device> --> table to obj --> call axiosPostDevInfoV1(obj)
      async axiosEmailSelfTest (xobj) {
        let vObj = {
          'method': 'POST',
          'url': this.$config.apiBaseUrl + this.axiosPathEmailSelfTest,
          'headers': {
            'X-Requested-With': 'XMLHttpRequest',
            'Content-Type': 'application/json',
            //'Authorization': 'Bearer ' + this.$cookies.get('kk')
            'Authorization': 'Bearer ' + cookies.get('kk')
          },
          'data': xobj
        }
        try {
          let resp = await axios.request(vObj)
          if (resp != null) {
            let vStatus = resp.data.status;
            if (vStatus === 'SUCCESS') {
              console.log('#axiosEmailSelfTest(0) OK');
              this.snackBarStart("primary", "Success");
            }  
          } else {
            console.log('#axiosEmailSelfTest(2) error: receive null data');
            this.snackBarStart("error", "No Response from Server");
          }
        } catch (error) {
          console.error('#axiosEmailSelfTest(3) error: ' + error.message);
          this.snackBarStart("error", error.message);
        }
      },

      async axiosEmailDevStatus (xobj) {
        let vObj = {
          'method': 'POST',
          'url': this.$config.apiBaseUrl + this.axiosPathEmailDevStatus,
          'headers': {
            'X-Requested-With': 'XMLHttpRequest',
            'Content-Type': 'application/json',
            //'Authorization': 'Bearer ' + this.$cookies.get('kk')
            'Authorization': 'Bearer ' + cookies.get('kk')
          },
          'data': xobj
        }

        //console.log('##dialogDevStatus', vObj.url, vObj.data);  //debug

        try {
          let resp = await axios.request(vObj)
          if (resp != null) {
            let vStatus = resp.data.status;
            if (vStatus === 'SUCCESS') {
              console.log('#axiosEmailDevStatus(0) OK');
              this.snackBarStart("primary", "Success");
            }  
          } else {
            console.log('#axiosEmailDevStatus(2) error: receive null data');
            this.snackBarStart("error", "No Response from Server");
          }
        } catch (error) {
          console.error('#axiosEmailDevStatus(3) error: ' + error.message);
          this.snackBarStart("error", error.message);
        }
      }      
    
    },

    mounted() {
      
      //let vObj = this.$cookies.get("us");
      if (!cookies.exist('us')) return;
      let str = cookies.get('us');
      let vObj = JSON.parse(str);
      if (vObj !== null) {       
        if (vObj.email !== null) { 
          this.emailTo = vObj.email;
          //this.aryEmail.push(vObj.email);
        }
        else {
          if (vObj.email1 !== null) this.aryEmail.push(vObj.email1);
          else {
            if (vObj.email2 !== null) this.aryEmail.push(vObj.email2);
          }
        }
      } 

    }  
  }
</script>

<style scoped>


.cBorder {
  border: 1px solid grey;
}
</style>
