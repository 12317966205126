<template>
  <v-card class="mt-1 pa-0">
    
    <DevTables3 v-if="tableItems === null" :tblHeaders="tableHeader" :tblItems="aryEmpty" tblHeight="calc(100vh - 220px)"/>
    <DevTables3 v-else :tblHeaders="tableHeader" :tblItems="tableItems" tblHeight="calc(100vh - 220px)"
      :ppSn="pSn" :ppDate1="pDate1" :ppDate2="pDate2" @tblEmit="onEmitTbl2" :trigger2="trigger"/> 

  </v-card>
</template>

<script>
import DevTables3 from '@/components/DevInfoPage/DevTables3'
//import * as Samples from "@/js/samples/data.js"
import * as Tbl from "@/js/tables.js"

  export default {
    name: 'TblEventRescue',

    components: {
      DevTables3
    },

    props: {
      tableItems: {type: Array},
      pSn: {type: String, default: ''},
      pDate1: {type: String, default: ''},
      pDate2: {type: String, default: ''},
      trigger: {type: Number, default: 0}
    },

    data () {
      return {
        tableHeader: Tbl.TblRescueHeaders2,
        aryEmpty: []
        // tableItems:  []
      }
    },

    methods: {
      onEmitTbl2(xstr) {
        this.$emit("tblDevEmit", xstr);
      }      
    },

    watch: {
      trigger: function(newVal) {
        //console.log("##TblEventResuce.vue");  //debug
      }
    },    
  }
</script>

<style>
</style>