






// ====================================================================
//          Device List
// ====================================================================
const DeviceData = 
[
	{
    "devSn": 'AE9-13000067',
    "devModel": 'BeneHeart C2A',
    "battCap": '40%',
    "PadsShelfLife": '2024-08-27',
    "InstLoc": 'Medisource-Asia',
    "CustName": 'Medisource-Asia',
    "WarrantyStatus": 'in warranty',
    "SelfTestSendPeriod": 1,
    "AssetSn": 'demo57',
    "DevStatus": 'Normal',
    "PadStatus": 'Fault',
    "InstTime": '2023-09-14',
    "DevSelfTestPeriod": 1,
    "WirelessStrength": '-47',
    "NetworkType": '4G',
    "DevAdmin": 'Irene',
    "ExtendedWarranty": '',
    "LastReportUploadTime": '2024-02-08',
    "RescueTimes": 0,
    "WarrantyExpireDate": '2027-01-01',
    "ICCID": 'XXXX-XXXXX-XXXXX',
    "LocAttr": 'fixed',
    "Map": 'Map'
	},
	{
    "devSn": 'AE7-94000043',
    "devModel": 'BeneHeart C1',
    "battCap": '20%',
    "PadsShelfLife": '2024-09-27',
    "InstLoc": 'Exhibition Centre',
    "CustName": 'McDonald',
    "WarrantyStatus": 'in warranty',
    "SelfTestSendPeriod": 1,
    "AssetSn": 'demo57',
    "DevStatus": 'Fault',
    "PadStatus": 'Warning',
    "InstTime": '2023-09-14',
    "DevSelfTestPeriod": 1,
    "WirelessStrength": '-50',
    "NetworkType": '4G',
    "DevAdmin": 'Donald T',
    "ExtendedWarranty": '',
    "LastReportUploadTime": '2024-02-08',
    "RescueTimes": 0,
    "WarrantyExpireDate": '2022-03-01',
    "ICCID": 'XXXX-XXXXX-XXXXX',
    "LocAttr": 'fixed',
    "Map": 'Map'
	},
	{
    "devSn": 'AE9-13000024',
    "devModel": 'BeneHeart C2 Fully automatic',
    "battCap": '40%',
    "PadsShelfLife": '2024-08-27',
    "InstLoc": '22.27017,114.13115',
    "CustName": 'Apple',
    "WarrantyStatus": 'in warranty',
    "SelfTestSendPeriod": 1,
    "AssetSn": 'demo57',
    "DevStatus": 'Warning',
    "PadStatus": 'Normal',
    "InstTime": '2023-09-14',
    "DevSelfTestPeriod": 1,
    "WirelessStrength": '-60',
    "NetworkType": '4G',
    "DevAdmin": 'Huckle',
    "ExtendedWarranty": '',
    "LastReportUploadTime": '2024-02-08',
    "RescueTimes": 0,
    "WarrantyExpireDate": '2023-04-04',
    "ICCID": 'XXXX-XXXXX-XXXXX',
    "LocAttr": 'mobile',
    "Map": 'Map'
	},
  {
    "devSn": 'AE9-95001013',
    "devModel": 'BeneHeart C2 Fully automatic',
    "battCap": '15%',
    "PadsShelfLife": '2024-08-27',
    "InstLoc": 'Sok Kwu Wan Pier No.2',
    "CustName": 'Sok Kwu Wan Pier',
    "WarrantyStatus": 'in warranty',
    "SelfTestSendPeriod": 1,
    "AssetSn": '12345678',
    "DevStatus": 'Offline',
    "PadStatus": 'Normal',
    "InstTime": '2023-09-14',
    "DevSelfTestPeriod": 1,
    "WirelessStrength": '-70',
    "NetworkType": '4G',
    "DevAdmin": 'Huckle',
    "ExtendedWarranty": '',
    "LastReportUploadTime": '2024-02-08',
    "RescueTimes": 0,
    "WarrantyExpireDate": '2024-01-01',
    "ICCID": 'XXXX-XXXXX-XXXXX',
    "LocAttr": 'mobile',
    "Map": 'Map'
  },
  {
    "devSn": 'AE9-95001014',
    "devModel": 'BeneHeart C2 Fully automatic',
    "battCap": '85%',
    "PadsShelfLife": '2026-01-01',
    "InstLoc": 'space museum',
    "CustName": 'Space Museum',
    "WarrantyStatus": 'in warranty',
    "SelfTestSendPeriod": 1,
    "AssetSn": '12345678',
    "DevStatus": 'Normal',
    "PadStatus": 'Normal',
    "InstTime": '2023-09-14',
    "DevSelfTestPeriod": 1,
    "WirelessStrength": '-80',
    "NetworkType": '4G',
    "DevAdmin": 'Hilary Clinton',
    "ExtendedWarranty": '',
    "LastReportUploadTime": '2024-02-08',
    "RescueTimes": 1,
    "WarrantyExpireDate": '2025-01-01',
    "ICCID": 'XXXX-XXXXX-XXXXX',
    "LocAttr": 'mobile',
    "Map": 'Map'
  },
  {
    "devSn": 'AE9-950TEST1',
    "devModel": 'BeneHeart D1',
    "battCap": '85%',
    "PadsShelfLife": '2026-03-01',
    "InstLoc": 'Wong Tai Sin Temple',
    "CustName": 'Wong Tai Sin',
    "WarrantyStatus": 'expired',
    "SelfTestSendPeriod": 1,
    "AssetSn": '12345677',
    "DevStatus": 'Normal',
    "PadStatus": 'Normal',
    "InstTime": '2024-03-01',
    "DevSelfTestPeriod": 1,
    "WirelessStrength": '-120',
    "NetworkType": '4G',
    "DevAdmin": 'Hilary Clinton',
    "ExtendedWarranty": '',
    "LastReportUploadTime": '2024-03-02',
    "RescueTimes": 1,
    "WarrantyExpireDate": '2023-09-01',
    "ICCID": 'XXXX-XXXXX-XXXXX',
    "LocAttr": 'mobile',
    "Map": 'Map'
  },
  {
    "devSn": 'AE9-950TEST2',
    "devModel": 'BeneHeart D1',
    "battCap": '85%',
    "PadsShelfLife": '2026-03-01',
    "InstLoc": 'Queen Mary Hospital',
    "CustName": 'Queen Mary Hospital',
    "WarrantyStatus": 'expired',
    "SelfTestSendPeriod": 1,
    "AssetSn": '12345677',
    "DevStatus": 'Normal',
    "PadStatus": 'Normal',
    "InstTime": '2024-03-01',
    "DevSelfTestPeriod": 1,
    "WirelessStrength": '-120',
    "NetworkType": '4G',
    "DevAdmin": 'Hilary Clinton',
    "ExtendedWarranty": '',
    "LastReportUploadTime": '2024-03-02',
    "RescueTimes": 1,
    "WarrantyExpireDate": '2025-01-01',
    "ICCID": 'XXXX-XXXXX-XXXXX',
    "LocAttr": 'mobile',
    "Map": 'Map'
  },
  {
    "devSn": 'AE9-950TEST3',
    "devModel": 'BeneHeart D1',
    "battCap": '50%',
    "PadsShelfLife": '2026-04-21',
    "InstLoc": 'Queen Mary Hospital',
    "CustName": 'Queen Mary Hospital',
    "WarrantyStatus": 'expired',
    "SelfTestSendPeriod": 1,
    "AssetSn": '12345678',
    "DevStatus": 'Normal',
    "PadStatus": 'Normal',
    "InstTime": '2024-01-01',
    "DevSelfTestPeriod": 1,
    "WirelessStrength": '-1150',
    "NetworkType": '4G',
    "DevAdmin": 'Hilary Clinton',
    "ExtendedWarranty": '',
    "LastReportUploadTime": '2024-03-02',
    "RescueTimes": 0,
    "WarrantyExpireDate": '2025-01-01',
    "ICCID": 'XXXX-XXXXX-XXXXX',
    "LocAttr": 'mobile',
    "Map": 'Map'
  }
]


// ====================================================================
//          Device Info - Detail
// ====================================================================

//-----------------------------------------------------
//      Device Management
//-----------------------------------------------------
const WarningData = 
[
	{ 
    "warnType": "loc Offset",
    "warnTime1": "2023-02-11 13:01:03",
    "warnTime2": "2023-04-23 15:02:02",
    "uploadTime": "2023-04-23 3:00:00"
  },
  { 
    "warnType": "4G Card Expire",
    "warnTime1": "2023-02-11 13:01:03",
    "warnTime2": "2023-04-23 15:02:02",
    "uploadTime": "2023-04-23 3:00:00"
  },
  { 
    "warnType": "Pads expire on 2023-06-01",
    "warnTime1": "2023-02-11 13:01:03",
    "warnTime2": "2023-04-23 15:02:02",
    "uploadTime": "2023-04-23 3:00:00"
  }	
]

const OfflineData = 
[
	{ 
    "offTime1": "2023-02-11 13:01:03",
    "offTime2": "2023-04-23 15:02:02",
    "uploadTime": "2023-04-23 3:00:00"
  },
  { 
    "offTime1": "2023-02-11 13:01:03",
    "offTime2": "2023-04-23 15:02:02",
    "uploadTime": "2023-04-23 3:00:00"
  }
]

const FaultData = 
[
	{ 
    "faultType": "Self Test failed",
    "errorCode": "234,115",
    "faultMsg": "circuit board fail, power supply fail",
    "firstFailure": "2023-02-11 13:01:03",
    "lastFailure": "2023-04-23 15:02:02",
    "uploadTime": "2023-04-23 3:00:00"
  },
  { 
    "faultType": "Self Test failed",
    "errorCode": "84",
    "faultMsg": "pads fail",
    "firstFailure": "2023-02-11 13:01:03",
    "lastFailure": "2023-04-23 15:02:02",
    "uploadTime": "2023-04-23 3:00:00"
  }
]

const SelfTestData = 
[
	{ 
    "selfTestTime": "2023-04-23 15:02:02",
    "selfTestPeriod": "1",
    "sendPeriod": "7",
    "selfTestType": "Daily",
    "testResult": "Pass",
    "allTestResult": "Pass",
    "faultCode": "",
    "faultMsg": "",
    "battSn": "HY86220502521",
    "uploadTime": "2023-04-23 3:00:00",    
  },
	{ 
    "selfTestTime": "2023-03-23 01:12:56",
    "selfTestPeriod": "1",
    "sendPeriod": "7",
    "selfTestType": "User Test",
    "testResult": "Fail",
    "allTestResult": "Fail",
    "faultCode": "242",
    "faultMsg": "",
    "battSn": "HY86220502521",
    "uploadTime": "2023-03-24 03:00:00"
  }  
]

const TblRescueSummaryHeaders = 
[
	{ 
    "rescueTime": "399",
    "Cpr": "251",
    "shocks": "2",
    "uploadTime": "2023-02-11 13:01:03"
  },
  { 
    "rescueTime": "370",
    "Cpr": "250",
    "shocks": "2",
    "uploadTime": "2023-02-21 14:11:03"
  },
  { 
    "rescueTime": "389",
    "Cpr": "250",
    "shocks": "3",
    "uploadTime": "2023-11-11 13:01:03"
  }
]

const TblRescueLogHeaders = 
[
	{ 
    "networkType": "4G",
    "signalLevel": "-78",
    "status": "PowerOff",
    "refreshTime": "2023-02-11 13:01:03",
    "uploadTime": "2023-02-11 13:01:03"
  },
  { 
    "networkType": "4G",
    "signalLevel": "-84",
    "status": "PowerOn",
    "refreshTime": "2023-02-11 13:01:03",
    "uploadTime": "2023-02-21 14:11:03"
  }
]

const TblOnOffHeaders = 
[
	{ 
    "status": "4G",
    "refreshTime": "2023-02-11 13:01:03",
    "uploadTime": "2023-02-11 13:01:03"
  },
  { 
    "status": "4G",
    "refreshTime": "2023-02-11 13:01:03",
    "uploadTime": "2023-02-21 14:11:03"
  }
]


//-----------------------------------------------------
//      Event Management
//-----------------------------------------------------
const FaultData2 = 
[
	{ 
    "faultType": "Self Test failed",
    "errorCode": "234,115",
    "faultMsg": "circuit board fail, power supply fail",
    "firstFailure": "2023-02-11 13:01:03",
    "lastFailure": "2023-04-23 15:02:02",
    "uploadTime": "2023-04-23 3:00:00",
    "CustName": "Medisource-Asia",
    "DevAdmin": "Irene",
    "InstLoc": "Medisource-Asia"
  },
  { 
    "faultType": "Self Test failed",
    "errorCode": "84",
    "faultMsg": "pads fail",
    "firstFailure": "2023-02-11 13:01:03",
    "lastFailure": "2023-04-23 15:02:02",
    "uploadTime": "2023-04-23 3:00:00",
    "CustName": "McDonald",
    "DevAdmin": "Donald T",
    "InstLoc": "Exhibition Centre"    
  }
]

const WarningData2 = 
[
	{ 
    "warnType": "loc Offset",
    "warnTime1": "2023-02-11 13:01:03",
    "warnTime2": "2023-04-23 15:02:02",
    "uploadTime": "2023-04-23 3:00:00",
    "CustName": "Medisource-Asia",
    "DevAdmin": "Irene",
    "InstLoc": "Medisource-Asia"
  },
  { 
    "warnType": "4G Card Expire",
    "warnTime1": "2023-02-11 13:01:03",
    "warnTime2": "2023-04-23 15:02:02",
    "uploadTime": "2023-04-23 3:00:00",
    "CustName": "McDonald",
    "DevAdmin": "Donald T",
    "InstLoc": "Exhibition Centre"    
  },
  { 
    "warnType": "Pads expire on 2023-06-01",
    "warnTime1": "2023-02-11 13:01:03",
    "warnTime2": "2023-04-23 15:02:02",
    "uploadTime": "2023-04-23 3:00:00",
    "CustName": "Joe Harper",
    "DevAdmin": "Joe P",
    "InstLoc": "22.27017,114.13115"    
  }	
]

const OfflineData2 = 
[
	{ 
    "offTime1": "2023-02-11 13:01:03",
    "offTime2": "2023-04-23 15:02:02",
    "uploadTime": "2023-04-23 3:00:00",
    "CustName": "Medisource-Asia",
    "DevAdmin": "Irene",
    "InstLoc": "Medisource-Asia"    
  },
  { 
    "offTime1": "2023-02-11 13:01:03",
    "offTime2": "2023-04-23 15:02:02",
    "uploadTime": "2023-04-23 3:00:00",
    "CustName": "McDonald",
    "DevAdmin": "Tom Sawyer",
    "InstLoc": "Exhibition Centre"  
  }
]


//-----------------------------------------------------
//      User Management
//-----------------------------------------------------
const UserData = 
[
	{ 
    "userName": "SEEtestuser",
    "userName2": "Serban",
    "systemRole": "Guest",
    "custName": "test",
    "email": "serban.necula@mindray.com",
    "creator": "mindraytest",
    "createTime": "2024-01-18 10:09:38",
    "validity": "One Week"
  },
  { 
    "userName": "mindraytestadmin",
    "userName2": "Eva",
    "systemRole": "Device Admin",
    "custName": "",
    "email": "eva.ssyuan@mindray.com",
    "creator": "mindraytest",
    "createTime": "2022-07-22 08:09:50",
    "validity": ""  
  },
  { 
    "userName": "Smindraytest",
    "userName2": "larry",
    "systemRole": "Device Admin",
    "custName": "",
    "email": "larry.bluan@mindray.com",
    "creator": "MRYFITS",
    "createTime": "2022-05-13 07:03:34",
    "validity": ""
  }	
]


//-----------------------------------------------------
//      Export
//-----------------------------------------------------

export { DeviceData, WarningData, OfflineData, FaultData, SelfTestData,
  TblRescueSummaryHeaders, TblOnOffHeaders,
  FaultData2, WarningData2, OfflineData2, UserData
};
