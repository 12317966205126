import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify'
import VueCookies from 'vue-cookies'
import axios from 'axios';

const fetchConfig = async () => {
  try {
    const response = await axios.get('/config.json');
    //console.log(response)
    return response.data;
  } catch (error) {
    console.error('Failed to load configuration:', error);
    return {};
  }
};

// Vue.use(VueCookies, { expires: '1d'});
Vue.use(VueCookies);
Vue.config.productionTip = false

// all devices info + their "LAST" test reports, formatted data
Vue.prototype.aeditems = []
// report data, RAW data
Vue.prototype.testrpts = []
Vue.prototype.emergrpts = []

Vue.prototype.software = {"version": "1.54.4", "date": "2024-09-12", "status": "aed1544"}

fetchConfig().then(config => {
  Vue.prototype.$config = config
  //console.log(config.COOKIE_EXPIRE)
  new Vue({
    router,
    vuetify,
    VueCookies,
    render: h => h(App)
  }).$mount('#app')
}).catch(() => {
  console.log("Configuration error, please check config.json")
});