<template>
  <v-card width="100%" min-width="900px" height="auto" class="ml-0 pa-0 mb-2" style="background-color: #37474F;">

    <!------------------------- 1st row:  Search filters ------------------>
    <v-card width="100%" class="mt-1 pa-1" color="transparent">
      <!-- row: w=auto h=47px / card w=305px h=40px -->
      <v-card width="auto" height="52px" class="mt-0 pa-0 d-flex flex-row rounded orange">

        <v-card width="30%" height="40px" class="ml-0 mt-1 pa-0 white border-0">
          <comboBox1 label="System Role" :items="aryUserRole" data="All" @msgComboBox1="onComboBox1"/>
        </v-card>

        <v-card width="30%" height="40px" class="ml-1 mt-1 transparent border-0">
          <inputBox1 label="Username/Name" :message="sUserName"  width1="50%" width2="50%" @msgInputBox1="onInputBox1" @click="onClickInput1"/>
        </v-card>


        <v-spacer></v-spacer>

        <v-btn icon class="mr-2 mt-2 elevation-5" style="background-color: #455A64; color: white;">
          <svg-icon type="mdi" :path="pathMagnify"></svg-icon></v-btn>
          
      </v-card>

    </v-card>

    <!------------------------- 2nd row ---------------------------------->
    <v-card class="mx-1 mt-1 pt-1">
    <DevTables :tblHeaders="tableHeader" :tblItems="tableItems"/>
    </v-card>

  </v-card>
</template>

<script>
import SvgIcon from '@jamescoyle/vue-icon';
import { mdiMagnify  } from '@mdi/js';

import inputBox1 from '../components/Common/InputBox1'
import comboBox1 from '../components/Common/ComboBox1'
import DevTables from '@/components/DevInfoPage/DevTables'
import * as Tbl from "@/js/tables.js"
import * as Samples from '@/js/samples/data.js'

  export default {
    name: 'UserManagement',

    components: {
      inputBox1, comboBox1, DevTables
    },

    data () {
      return {
        pathMagnify: mdiMagnify,
        aryUserRole: Tbl.UserRole,
        sUserName: '',
        sTabCapital: "transparent blue-grey--text text--darken-3  text-body-2 text-capitalize",
        tableHeader: Tbl.TblUsertHeader,
        tableItems:  Samples.UserData
      }
    },

    methods: {
      onInputBox1 (xstr) {
        console.log('onInputBox1', xstr);
      },

      onComboBox1 (xstr) {
        console.log('combobox', xstr);
      }

    },

    mounted () {
      this.$root.$emit('App_TopBar', true);
    }
  }
</script>

<style>
</style>
