<template>
  <!-- previous background color #37474F, change to #161616 at 240401 -->
  <v-card id="idCard1" width="100%" min-width="900px" class="ma-0">
    <!-- <h3 class="mx-0 mt-1 pa-0" style="color: orange; text-align: center">{{sTitlePrefix + sTitle}}</h3>  -->

    <!------------------------- 1st row:  Search filter ------------------>
    <v-card width="auto" color="transparent" class="mx-1">
      <!-- row: w=auto h=47px / card w=305px h=40px -->
      <v-card width="auto" height="50px" class="mt-1 d-flex flex-row rounded-b-0 rounded-t orange">
        <v-card width="20%" height="40px" class="ml-1 mt-1 transparent border-0">
          <inputBox1 label="Device SN" :message="sDevSn"  width1="58%" width2="42%" readonly @msgInputBox1="onInputBox1"/>
        </v-card>

        <v-card width="30%" height="40px" class="ml-1 mt-1 transparent border-0">
          <inputBox1 label="Model" :message="sModel"  width1="20%" width2="80%" readonly @msgInputBox1="onInputBox1"/>
        </v-card>

        <v-card width="150px" height="40px" class="ml-2 mt-1 amber accent-3 rounded elevation-5 text-h4">
          <DatePicker title="Start Date" :dayback="sDayBack" @DatePicker="onDateChange1"/>
        </v-card>

        <v-card width="150px" height="40px" class="ml-1 mt-1 amber accent-3 rounded elevation-5 text-h4">
          <DatePicker title="End Date" @DatePicker="onDateChange2"/>
        </v-card>

        <v-progress-circular v-if="showProgress" color="blue" :size="30" indeterminate class="mt-2 ml-3"></v-progress-circular>

        <v-spacer></v-spacer>

        <!-- <v-btn icon class="mr-2 mt-2 elevation-5" @click="onBtnRefresh" style="background-color: #455A64; color: white;">
          <svg-icon type="mdi" :path="pathReload"></svg-icon></v-btn>  -->
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon color="#37474F" class="mt-2 mr-1 ml-0 elevation-0 fontArialB" v-bind="attrs" v-on="on" @click="onBtnRefresh">
              <svg-icon type="mdi" :path="pathReload" size="32"></svg-icon>
            </v-btn>
          </template>
          <span class="yellow--text">Reload</span>
        </v-tooltip>          

        <!-- <dialogDevInfoDetail height="40" width="120" :devObj="DevObj" color="#455A64" class="mr-2 mt-3 pl-2" />  -->

        <!-- <v-btn icon class="mr-2 mt-2 elevation-5" @click="onBtnUploadPic" style="background-color: #455A64; color: white;">
          <v-tooltip activator="parent" location="start">Upload Picture</v-tooltip><v-icon>mdi-upload</v-icon></v-btn>  -->

        <!-- <v-btn icon class="mr-2 mt-2 elevation-5" style="background-color: #455A64; color: white;">
          <v-icon>mdi-magnify</v-icon></v-btn>  -->
      </v-card>
    </v-card>

    <!------------------------- 2nd row:  Tabs ---------------------------->    
    <v-card height="100%" width="auto"  fixed-header class="mt-1 mx-1 pa-0" color="transparent"
    style="height: calc(100vh - 110px);"> 

      <v-tabs dense align-with-title width="99%" height="30px" v-model="selectedTab" background-color="orange" slider-color="transparent" 
      class="ma-0 pa-0" active-class="orange darken-3 white--text" center-active>

        <v-tab href="#TblBasic" width="120" :class="sTabCapital" @click="onTab(1)">Basic Info</v-tab>
          <v-tab-item value="TblBasic"><tblBasic :devObj = "DevObj" :testInfo = "DevTestInfo"
            :isTstExist="isTestRpt" :trigger="triggerBasic" :transition="false"/>
          </v-tab-item>

        <v-tab href="#TblWarning" width="120" :class="sTabCapital" @click="onTab(2)">Warning</v-tab>
          <v-tab-item value="TblWarning" :transition="false">
            <tblWarning :tableItems="aryTestReport" :pDate1="vDate1" :pDate2="vDate2" :trigger="triggerTbl"/>
          </v-tab-item>

        <v-tab href="#TblOffline" width="120" :class="sTabCapital" @click="onTab(3)">Offline</v-tab>
          <v-tab-item value="TblOffline" :transition="false">
            <tblOffline :tableItems="aryTestReport" :pDate1="vDate1" :pDate2="vDate2" :trigger="triggerTbl"/>
          </v-tab-item>

        <v-tab href="#TblFault" width="120" :class="sTabCapital" @click="onTab(4)">Fault</v-tab>
          <v-tab-item value="TblFault" :transition="false">
            <tblFault :tableItems="aryTestReport" :pDate1="vDate1" :pDate2="vDate2" :trigger="triggerTbl"/>
          </v-tab-item>

        <v-tab href="#TblSelfTest" width="120" :class="sTabCapital" @click="onTab(5)">Self-test report</v-tab>
          <v-tab-item value="TblSelfTest" :transition="false">
            <tblSelfTest :tableItems="aryTestReport" :pDate1="vDate1" :pDate2="vDate2" :trigger="triggerTbl"/>
          </v-tab-item>

        <v-tab href="#TblRescueSummary" width="120" :class="sTabCapital" @click="onTab(6)">Rescue Summary</v-tab>
          <v-tab-item value="TblRescueSummary" :transition="false">
            <tblRescueSummary :tableItems="aryEmergReport" :pDate1="vDate1" :pDate2="vDate2" :trigger="triggerTbl"/>
          </v-tab-item>

        <v-tab href="#TblDevState" width="120" :class="sTabCapital" @click="onTab(7)">Power On/Off</v-tab>
          <v-tab-item value="TblDevState" :transition="false">
            <tblDevState :tableItems="aryDeviceState" :pDate1="vDate1" :pDate2="vDate2" :trigger="triggerTbl"/>
          </v-tab-item> 

        <!-- <v-tab href="#TblOffLog" width="120" :class="sTabCapital" @click="onTab(8)">Power-on/off Log</v-tab>
          <v-tab-item value="TblOffLog"><tblOffLog /></v-tab-item>  -->
      </v-tabs>

    </v-card>

    <v-snackbar right v-model="snackBar.on" :color="snackBar.color">{{ snackBar.message }}</v-snackbar>

  </v-card>
</template>

<script>
import axios from 'axios'
import moment from 'moment'
import cookies from '@/js/Cookie.js'
import sess from '@/js/SessionStorage.js'
import * as Ut from '@/js/ut.js'
import * as Tbl from '@/js/tables.js'
import * as TestInfo from '@/js/samples/TestInfo.js'
import * as Emergency from '@/js/samples/Emergency.js'

import inputBox1 from '../components/Common/InputBox1'
// import comboBox1 from '../components/Common/ComboBox1'
// import * as Samples from '../js/samples/data.js'
import DatePicker from '../components/Common/DatePicker'
import dialogDevInfoDetail from '../components/DevInfoPage/dialogDevInfoDetail'

import tblBasic from '@/views/viewsDevInfo/TblBasic.vue'
import tblWarning from '@/views/viewsDevInfo/TblWarning.vue'
import tblOffline from '@/views/viewsDevInfo/TblOffline.vue'
import tblFault from '@/views/viewsDevInfo/TblFault.vue'
import tblSelfTest from '@/views/viewsDevInfo/TblSelfTest.vue'
import tblRescueSummary from '@/views/viewsDevInfo/TblRescueSummary.vue'
import tblDevState from '@/views/viewsDevInfo/TblDevState.vue'

import SvgIcon from '@jamescoyle/vue-icon';
import { mdiReload } from '@mdi/js';

  export default {
    name: 'DevInfoDetail', 

    components: {
      inputBox1, DatePicker, dialogDevInfoDetail,
      tblBasic, tblWarning, tblOffline, tblFault, tblSelfTest, tblRescueSummary, tblDevState, SvgIcon
    },
    props:{
      sn: {
            type: String,
            default: ""
      }
    },
    data () {
      return {
        axiosPathTestReportSn:      '/api/v1/getTestReport',
        axiosPathEmergencySn:       '/api/v1/emergreports',
        axiosPathDeviceStateSn:     '/api/v1/getDevState',
        pathReload: mdiReload,

        sTabCapital: "transparent blue-grey--text text--darken-3 text-body-2  font-weight-bold text-capitalize fontArialN",
        sTitlePrefix: 'Device Detail',
        sTitle: '',
        sDevSn: '',
        sModel: '',
        DateRange: {"start": "", "end": ""},
        DevObj: {},
        DevTestInfo: {},  // single latest test report, raw data
        isTestRpt: false,
        selectedTab: "",
        aryCustomer: ['All', 'Microsoft', 'Macrosoft'],
        aryTestReport: [], // raw data
        aryEmergReport: [],
        aryDeviceState: [],
        aryPic: [],

        showProgress: false,
        snackBar: {"on": false, "color": "", "message": ""},
        snackTimeout: 3000,
        snackMsgLogout: "Login expired, please login again",
        tmrObj: {},

        vDate1: '',
        vDate2: '',
        iDayBack: -365 * 3,
        sDayBack: "-1095",
        triggerBasic: 0,
        triggerTbl: 0
      }
    },
    
    methods: {

      onInputBox1 (xstr) {
        //console.log('onInputBox1', xstr);
        return xstr;
      },

      onComboBox1 (xstr) {
        //console.log('combobox', xstr);
        return xstr;
      },

      // ReLoad reports, update tables
      onBtnRefresh () {
        if (this.showProgress) return;
        if (Ut.SysConf.SimData === false && this.sDevSn !== "") {
            // console.log("##selectedTab", this.selectedTab);
            this.showProgress = true;
            this.DevObj.tab = this.selectedTab;
            this.axiosGetTestReportSn(this.sDevSn);
            this.axiosGetEmergencySn(this.sDevSn);
            this.axiosGetDeviceStateSn(this.sDevSn);
            this.tblTriggerInfo();    //force DevTables refresh
          }
      },

      // DESCRIPTION:  handler of clicking the tab of TblBasic, TblWarning, TblFault, ....
      //  table:  TestInfo.js\createTestReportSn()      //not raw data
      onTab (xtab) {
        switch(xtab)
        {
          case 1: this.sTitle = " - Basic Info"; 
            this.tblTriggerBasic();  //force Basic Info update
            break;

          case 2: this.sTitle = " - Warning Report"; 
            for (let idx=0; idx < this.aryTestReport.length; idx++) {
              //console.log('Devinfo-warntype-',this.aryTestReport[idx].warnType, this.aryTestReport[idx].receiveTime);
              if (this.aryTestReport[idx].warnType === "") {
                this.aryTestReport[idx].hidden = true;
              } else {
                this.aryTestReport[idx].hidden = false;
              }
            }
            this.tblTriggerInfo();  //force DevTables to reload
            break;

          case 3:
            this.sTitle = " - Offline Report"; 
            for (let idx=0; idx < this.aryTestReport.length; idx++) {
              // console.log('Devinfo-offline-', this.aryTestReport[idx].offlineDays);
              if (this.aryTestReport[idx].offlineDays === 'Normal' || this.aryTestReport[idx].offlineDays === '/') {
                this.aryTestReport[idx].hidden = true;
              } else {
                this.aryTestReport[idx].hidden = false;
              }
            }
            this.tblTriggerInfo();
            break;

          case 4: this.sTitle = " - Fault Report"; 
            for (let idx=0; idx < this.aryTestReport.length; idx++) {
              if (Ut.getFaultStatus(this.aryTestReport[idx].testResult, this.aryTestReport[idx].errorCode)) {
                this.aryTestReport[idx].hidden = false;
              } else {
                this.aryTestReport[idx].hidden = true;
              }
            }
            this.tblTriggerInfo();
            break;

          case 5: this.sTitle = " - Self Test Report"; 
            for (let idx=0; idx < this.aryTestReport.length; idx++) {
              this.aryTestReport[idx].hidden = false;
            } 
            this.tblTriggerInfo();
            break;          

          case 6: this.sTitle = " - Rescue Summary"; 
            /* for (let idx=0; idx < this.aryEmergReport.length; idx++) {
              this.aryEmergReport[idx].hidden = false;
            } */        
            this.tblTriggerInfo();
            break;
            
          case 7: this.sTitle = " - Device State"; 
            this.tblTriggerInfo();
            break;
          case 8: this.sTitle = " - Power Off Log"; 
            this.tblTriggerInfo();
            break;
          default: this.sTitle = ""; break;
        }
      },

      // DESCRIPTION:  goto tab, if this.DevObj comes from Mgmt page
      // Parameters:   xSource = "TestInfo" or "Emergency"
      setTab(xSource) {
        if ("tab" in this.DevObj) {
          if (xSource === "TestInfo") {
            switch(this.DevObj.tab) {
              case "TblFault":
              case "TblEventFault": 
                this.onTab(4); 
                this.selectedTab = "TblFault";
                break;
              case "TblWarning":
              case "TblEventWarning": 
                this.onTab(2); 
                this.selectedTab = "TblWarning";
                break;
              case "TblOffline":  
              case "TblEventOffline": 
                this.onTab(3); 
                this.selectedTab = "TblOffline";
                break;
              case "TblSelfTest": 
              case "TblEventTestInfo": 
                this.onTab(5); 
                this.selectedTab = "TblSelfTest";
                break;
              default: break;
            }
            //console.log("##Detail-selectedTab", this.selectedTab);  //debug
          } else if (xSource === "Emergency") {
            if (this.DevObj.tab === "TblEventRescue" || this.DevObj.tab === "TblRescueSummary") {
              this.onTab(6); 
              this.selectedTab = "TblRescueSummary";              
            }
          }
        }
      },

      onDateChange1 (xDate) {
        this.vDate1 = xDate;
        this.tblTriggerInfo();  //trigger DevTables to update change
      },

      onDateChange2 (xDate) {
        this.vDate2 = xDate;
        this.tblTriggerInfo(); 
      },

      // DESCRIPTION:  upload single picture
      onBtnUploadPic () {

      },

      //------------------------------------------------------------------
      //              [Ut] 
      //------------------------------------------------------------------           
      clearArray (xAry) {
        if (xAry.length > 0) {
          xAry.splice(0);
          xAry.length = 0;        
        }
      },   

      // DESCRIPTION:  trigger Basic Info panel to update data
      tblTriggerBasic() {
        if (this.triggerBasic++ >= 60000) this.triggerBasic=0; 
      },

      // DESCRIPTION:  trigger tables to update data
      tblTriggerInfo() {
        if (this.triggerTbl++ >= 60000) this.triggerTbl=0; 
      },


      //------------------------------------------------------------------
      //              Load data
      //------------------------------------------------------------------  
      // DESCRIPTION:  load test report sim data with devSn
      loadTestReportSn (xSn) {
        //if (typeof(this.testreportSn) !== 'undefined') {
        //  this.clearArray(this.testreportSn);
        //}
        let ary = TestInfo.findAllDevSn(xSn);
        this.storeTestReportToTable(ary);
      },

      // DESCRIPTION:  load emergence report sim data with devSn
      loadEmergencyReportSn (xSn) {
        //if (typeof(this.emergSn) !== 'undefined') {
        //  this.clearArray(this.emergSn);
        //}
        let ary = Emergency.findAllDevSn(xSn);
        this.storeEmergToTable(ary);
      },

      //------------------------------------------------------------------
      //              [storeTestReportToTable]
      // DESCRIPTION:  load axios or sim data to this.aryTestReport[]
      //               Raw data to processed table data
      //------------------------------------------------------------------
      storeTestReportToTable (xSn, xAry) {
        if (typeof(xAry) === "undefined") return;
        this.clearArray(this.aryTestReport);

        // Sort array:  newest at top of table
        /* if (xAry.length > 1) {
          xAry.sort((a, b) => Ut.compareDates(a.datetime, b.datetime));
        } */

        let str, sWarn = "", warnType = "";
        for(let idx=0; idx < xAry.length; idx++) {
          let vObj2 = xAry[idx]; 
          let vObj = TestInfo.createTestReportSn();

          vObj.devSn          = vObj2.sn;
          vObj.firstTime      = "/";
          vObj.lastTime       = "/";
          vObj.receiveTime    = moment(vObj2.datetime).format("YYYY-MM-DD HH:mm:ss"); 
          //vObj.warnType       = sWarn;
          vObj.faultType      = "/";
          vObj.errorCode      = vObj2.testerrorcode;
          if (vObj.errorCode === "") vObj.errorCode = "/";
          vObj.faultMessage   = "/";
          vObj.netType        = vObj2.netinfo.NetType;
          vObj.signalStrength = vObj2.netinfo.SignalLevel;
          vObj.refreshTime    = "/";
          // Self Test
          //vObj.selfTestTime   = moment(vObj2.testtime).format("YYYY-MM-DD HH:mm:ss"); 
          vObj.selfTestTime   = moment.parseZone(vObj2.testtime).local(true).format("YYYY-MM-DD HH:mm:ss");
          vObj.selfTestPeriod = vObj2.netinfo.TestPeriod;
          if (vObj2.netinfo.TransmissionPeriod === 1) vObj.sendingPeriod = "Daily";
          else if (vObj2.netinfo.TransmissionPeriod === 7) vObj.sendingPeriod = "Weekly";
          else vObj.sendingPeriod = "Daily";
          // vObj.sendingPeriod  = vObj2.netinfo.TransmissionPeriod;
          vObj.selfTestType   = Tbl.getTblItem(Tbl.rptTestType, vObj2.testtype);
          vObj.testResult     = vObj2.testresult? "Pass" : "Fail";
          //vObj.testResultFinal = (vObj2.testresult + vObj2.relatedresult === 2)? "Pass" : "Fail";
          //vObj.offlineDays    = Ut.getOfflineStatus(vObj.receiveTime, vObj.sendingPeriod);
          vObj.rescueTime     = "/";
          vObj.battSn         = vObj2.testitems.batSn.result;
          vObj.hidden         = false;

          //(1) handling Fault
          if (vObj.errorCode === "/" || vObj.errorCode === "" ) {
            vObj.faultMessage = "/";
            if (vObj2.relatedresult === 0) {
              str = "";
              if (vObj2.relatedtest.key.result === 0) str = "key";
              if (vObj2.relatedtest.voice.result === 0) str = str + ",voice";
              if (vObj2.relatedtest.j200.result === 0) str = str + ",j320";
              if (vObj2.relatedtest.j360.result === 0) str = str + ",j360";
              if (str === "") vObj.faultMessage = "RelatedTest fail";
              else vObj.faultMessage = "RelatedTest : " + str + " fail";
            }
          } else {
            if ('errorMessage' in vObj2) {
              vObj.faultMessage = "";
              for(let idx2=0; idx2 < vObj2.errorMessage.length; idx2++) {
                if (Ut.SysConf.language === "eng") {
                  vObj.faultMessage = vObj.faultMessage + vObj2.errorMessage[idx2].message+ ",";
                } else if (Ut.SysConf.language === "chi")  {
                  vObj.faultMessage = vObj.faultMessage + vObj2.errorMessage[idx2].chiMessage+ ",";
                }
              }
            }
          }

          // (2) handling Warning
          //     Pads returns:  "Normal", "3", "12", "Expired"
          //     "Expired" is not handled in warning
          str = Ut.getPadsStatus(vObj2.testitems.padsExpiried.result,
            vObj2.testitems.padsExpiringDate.result);
          warnType = "";
          if (str !== "Normal") {
            if (str === "3") {
              warnType = "Pads to expire";
            } 
            /* else if (str === "Expired") {
              warnType = "Expired";
            } */
          }
          //  Batt.Status returns:  Normal, error, Batt Low
          str = Ut.getBattStatus(vObj2.testitems.batResidualCapacity.result,
            vObj2.testitems.batTotalCapacity.result).Status;
          if (str !== "Normal" ) {
            if (str === "error") str = str + "Batt error";
            if (warnType !== "") warnType = warnType +","+ str;
            else warnType = str;
          }
          warnType = warnType.replace("Pads undefined;", "");
          vObj.warnType = warnType;
         
          // (3) store in table
          this.aryTestReport.push(vObj);
          //console.log(vObj.devSn, vObj2.datetime);  //debug

        } // end of FOR() 1

        if (this.aryTestReport.length > 1) {
          this.aryTestReport.sort((a, b) => moment(new Date(b.receiveTime)).diff(moment(new Date(a.receiveTime)), 'hours') );
        }        

        // Calculate date difference for offlineDays
        let vDate1 = "";
        let timeDiff = 0;
        let vSendPeriod = 1;
        let hourOffset = Ut.SysConf.OfflineDays_OffsetHours; //usually 1  hour
        let vNow = moment().format("YYYY-MM-DD HH:mm:ss");
        let vDate2 = null;
        for(let idx=0; idx < this.aryTestReport.length; idx++) {
          // sendingPeriod now === Daily, Weekly
          if (this.aryTestReport[idx].sendingPeriod === "Daily") vSendPeriod=1;
          else if (this.aryTestReport[idx].sendingPeriod === "Weekly") vSendPeriod=7;
          else if (this.aryTestReport[idx].sendingPeriod === "Monthly") vSendPeriod=30; 
          else vSendPeriod=1; 
          // first record --> exit
          if (idx >= this.aryTestReport.length - 1) {
            this.aryTestReport[idx].offlineDays = "/";
            break;
          }
          vDate1 = this.aryTestReport[idx].receiveTime;
          // [0] - lastest record --> compare with today
          if (idx === 0) { 
            if (vDate1 !== "") {
              timeDiff = moment(vNow).diff(moment(vDate1), 'hours');
              this.aryTestReport[idx].offlineDays = Math.floor(timeDiff/24) + "";
            }
          }
          else {
            if (vDate1 !== '') {
              vDate2 = this.aryTestReport[idx+1].receiveTime;
              timeDiff = moment(vDate1).diff(moment(vDate2), 'hours');
              if (timeDiff >= (vSendPeriod * 24 + hourOffset)) {
                this.aryTestReport[idx].offlineDays = Math.floor(timeDiff/24) + "";
              }
              else this.aryTestReport[idx].offlineDays = 'Normal';              
            }
          }
          //console.log(vDate1, vDate2, timeDiff/24); 
        }
        // end of FOR() 2

        // set datepicker range
        if (this.aryTestReport.length > 0) {
          let yDate1 = this.aryTestReport[0].receiveTime;   //last
          let yDate2 = this.aryTestReport[this.aryTestReport.length - 1].receiveTime;  //first
          //this.iDayBack = moment(yDate2).diff(moment(yDate1), 'days'); 
          this.iDayBack = moment(yDate1).diff(moment(new Date()), 'days') - 1; 
          //this.sDayBack = this.iDayBack.toString();
        } 

        // get the last test report in RAW format (passed to TblBasic)
        if (xAry !== undefined && xAry !== null && xAry.length > 0) {
          this.DevTestInfo  = xAry[0];
          this.tblTriggerBasic();
        }

        // Set Tab filters
        if ("tab" in this.DevObj) {
          this.setTab("TestInfo");  // check this.DevObj.tab inside setTab()
        }
        this.tblTriggerInfo();

      },

      // DESCRIPTION:  load axios or sim data to this.aryEmergReport[]
      storeEmergToTable (xAry) {
        if (typeof(xAry) === "undefined") return;
        this.clearArray(this.aryEmergReport);
        for(let idx=0; idx < xAry.length; idx++) {
          let vObj2 = xAry[idx]; 
          let vObj = Emergency.createEmergReportSn();
          vObj.devSn          = vObj2.sn;
          vObj.receiveTime    = moment(vObj2.datetime).format("YYYY-MM-DD HH:mm:ss"); 
          vObj.rescueTime     = vObj2.totalduration;
          vObj.cprDuration    = vObj2.cprduration;
          vObj.shock          = vObj2.shock;
          vObj.testTime       = "/";
          vObj.state          = "Rescue";
          vObj.hidden         = false;

          this.aryEmergReport.push(vObj);
        }

        this.setTab("Emergency");
      },
      
      // DESCRIPTION:  load axios or sim data to this.aryEmergReport[]
      storeDevicesStateToTable (xAry) {
        if (typeof(xAry) === "undefined") return;
        this.clearArray(this.aryDeviceState);
        for(let idx=0; idx < xAry.length; idx++) {
          let vObj2 = xAry[idx]; 
          /* let vObj = Emergency.createEmergReportSn();
          vObj.devSn          = vObj2.sn;
          vObj.receiveTime    = moment(vObj2.datetime).format("YYYY-MM-DD HH:mm:ss"); 
          //vObj.testTime       = moment(vObj2.testtime).format("YYYY-MM-DD HH:mm:ss"); 
          vObj.testTime       = moment.parseZone(vObj2.testtime).local(true).format("YYYY-MM-DD HH:mm:ss"); 
          vObj.state          = vObj2.state;
          vObj.rescueTime     = 0;
          vObj.cprDuration    = 0;
          vObj.shock          = 0;
          vObj.hidden         = false;
          this.aryEmergReport.push(vObj); */

          let vObj = Emergency.createDevStateReportSn(); 
          vObj.receiveTime    = moment(vObj2.datetime).format("YYYY-MM-DD HH:mm:ss"); 
          vObj.testTime       = moment.parseZone(vObj2.testtime).local(true).format("YYYY-MM-DD HH:mm:ss"); 
          vObj.netType        = Tbl.getTblItem(Tbl.rptNetworkType, vObj2.nettype);
          vObj.signalLevel    = vObj2.signallevel;
          vObj.status         = vObj2.state;
          if (vObj.netType === "") vObj.netType = "/";
          this.aryDeviceState.push(vObj);
          //console.log("##devState-113", vObj);  //debug
        }

        // Sort array:  newest at top of table
        if (this.aryDeviceState.length > 1) {
          this.aryDeviceState.sort((a, b) => Ut.compareDates(a.receiveTime, b.receiveTime));
        }
      },


      // ----------------------------------------------------------------------
      //              [Axios] [axios]
      // 
      // ----------------------------------------------------------------------      

      // ---------------------------------------------
      // DESCRIPTION:  All Test Report with devSn
      async axiosGetTestReportSn (xSn) {
        let vObj = {
          'method': 'GET',
          'url': this.$config.apiBaseUrl + this.axiosPathTestReportSn + "/" + xSn,
          //timeout: this.connectionTimeout,
          'headers': {
            'X-Requested-With': 'XMLHttpRequest',
            'Content-Type': 'application/json',
            //'Authorization': 'Bearer ' + this.$cookies.get('kk')
            'Authorization': 'Bearer ' + cookies.get('kk')
          }
        }
        try {
          let resp = await axios.request(vObj)
          if (resp != null) {
            //alert(JSON.stringify(resp)) // {"data":{status: xxx, message: yyy, data: [{}, {}, ...] }
            let vStatus = resp.data.status;
            if (vStatus === 'SUCCESS') {
              if (resp.data.data !== null) {
                this.storeTestReportToTable(xSn, resp.data.data);
                this.isTestRpt = true;
                // this.setTab("TestInfo");  //goto tab if DevObj contains "tab" field
              } else {
                //console.log('#Detail-axiosGetTestReportSn(0b) null data');
                this.isTestRpt = false;  // test report not available
                this.tblTriggerBasic();
                Ut.clearArray(this.aryTestReport);
                this.DevTestInfo = null;
              }
            } else {
              console.log('##Detail-axiosGetTestReportSn(1) error: axios data request fail');
            }  
            this.showProgress = false;
          } else {
            console.log('#Detail-axiosGetTestReportSn(2) error: receive null data');
            this.showProgress = false;
          }
        } catch (error) {
          this.showProgress = false;
          console.error('#Detail-axiosGetTestReportSn(3) error: ' + error.message);
          if (error.message.indexOf('401') > 0) {
            this.snackStart(this.snackMsgLogout, 'error');
          } else if (error.message.indexOf('Network Error') > 0 || error.message === 'undefined') {
            this.snackStart("Cannot connect to Server", 'error');
        }
        }
      },

      // ---------------------------------------------
      // DESCRIPTION:  All Emergency(Rescue) Reports
      async axiosGetEmergencySn (xSn) {
        let vObj = {
          'method': 'GET',
          'url': this.$config.apiBaseUrl + this.axiosPathEmergencySn + "/" + xSn,
          //timeout: this.connectionTimeout,
          'headers': {
            'X-Requested-With': 'XMLHttpRequest',
            'Content-Type': 'application/json',
            //'Authorization': 'Bearer ' + this.$cookies.get('kk')
            'Authorization': 'Bearer ' + cookies.get('kk')
          }
        }
        try {
          let resp = await axios.request(vObj)
          if (resp != null) {
            let vStatus = resp.data.status;
            if (vStatus === 'SUCCESS') {
              if (resp.data.data !== null) {
                this.storeEmergToTable(resp.data.data);
                //this.setTab("Emergency");
                //console.log('#Detail-axiosGetEmergencySn(0) OK');
              } else {
                Ut.clearArray(this.aryEmergReport);
              }
            } else {
              console.log('#Detail-axiosGetEmergencySn(1) error: axios data request fail');
              //this.loadTestrpts();
            }  
          } else {
            console.log('#Detail-axiosGetEmergencySn(2) error: receive null data');
          }
        } catch (error) {
          console.error('#Detail-axiosGetEmergencySn(3) error: ' + error.message);
          //this.loadTestrpts();
        }
      },

            // ---------------------------------------------
      // DESCRIPTION:  All Device States, PowerOn PowerOff
      async axiosGetDeviceStateSn (xSn) {
        let vObj = {
          'method': 'GET',
          'url': this.$config.apiBaseUrl + this.axiosPathDeviceStateSn + "/" + xSn,
          //timeout: this.connectionTimeout,
          'headers': {
            'X-Requested-With': 'XMLHttpRequest',
            'Content-Type': 'application/json',
            //'Authorization': 'Bearer ' + this.$cookies.get('kk')
            'Authorization': 'Bearer ' + cookies.get('kk')
          }
        }
        try {
          let resp = await axios.request(vObj)
          if (resp != null) {
            let vStatus = resp.data.status;
            if (vStatus === 'SUCCESS') {
              if (resp.data.data !== null) {
                this.storeDevicesStateToTable(resp.data.data);
                //console.log('#Detail-axiosGetDeviceStateSn(0) OK');
              } else {
                Ut.clearArray(this.aryDeviceState);
              }
            } else {
              console.log('#Detail-axiosGetDeviceStateSn(1) error: axios data request fail');
            }  
          } else {
            console.log('#Detail-axiosGetDeviceStateSn(2) error: receive null data');
          }
        } catch (error) {
          console.error('#Detail-axiosGetDeviceStateSn(3) error: ' + error.message);
        }
      },

      // ---------------------------------------------
      //          snackBar
      // ---------------------------------------------
      snackStart(xmsg, xcolor) {
        if (this.snackBar.on) {
          this.snackStop();
        }
        this.snackBar.message = xmsg;
        this.snackBar.color = xcolor;
        this.snackBar.on = true;
        this.tmrObj = setTimeout(this.snackTimerEvent, this.snackTimeout);
      },

      snackTimerEvent() {
        this.snackBar.on = false;
        this.tmrObj = null;
        if (this.snackBar.message === this.snackMsgLogout) {
          this.snackBar.message = '';
          this.$root.$emit('App_Logout', "113");
        }
        this.snackBar.message = '';        
      },

      snackStop() {
        clearTimeout(this.tmrObj);
        this.snackBar.message = '';
        this.snackBar.on = false;
        this.tmrObj = null;
      }

    },

    mounted () {
      if (cookies.exist("aa") === false) {
        this.$router.push('/');
      } 
      this.$root.$emit('App_TopBar', true);
          
      // source refer to Devinfo.vue -> onClickDevSn()
      let vObj = this.$route.params;
      //console.log('##Detail-211', this.$route.query.sn, vObj);  // get sn from props

      if (vObj !== undefined && vObj !== null && "xobj" in vObj) {
        this.DevObj = vObj.xobj;

        if (typeof(this.DevObj.devSn) !== "undefined" && this.DevObj.devSn !== "") {          
          this.sDevSn = this.DevObj.devSn;
          this.sModel = this.DevObj.devModel; 

          if (this.sDevSn !== null && this.sDevSn !== undefined) {
            sess.set('dd', JSON.stringify(this.DevObj));
          }
          if (this.DevObj.InstLoc === "/") this.DevObj.InstLoc = "";

          // check if coming from mgmt tab
          if ("tab" in this.DevObj) {
            this.selectedTab = "";
          } 

          // Load all test reports related to sDevSn
          this.showProgress = true;
          this.axiosGetEmergencySn(this.sDevSn);
          this.axiosGetDeviceStateSn(this.sDevSn);
          this.axiosGetTestReportSn(this.sDevSn);
        }

      } else { // handle reload page
        let tmp = sess.get("dd"); 
        if (tmp !== null) {
          this.DevObj = JSON.parse(tmp);
          this.sDevSn = this.DevObj.devSn;
          this.sModel = this.DevObj.devModel; 

          // Load all test reports related to sDevSn
          this.showProgress = true;
          this.axiosGetEmergencySn(this.sDevSn);
          this.axiosGetDeviceStateSn(this.sDevSn);             
          this.axiosGetTestReportSn(this.sDevSn);       

          // check if coming from mgmt tab
          this.$root.$emit('App_Tab', "/DevInfo");  // change tab in top bar 

        }

        //this.iDayBack = -365 * 2;
      }
      // end of mounted()
    }

  }
</script>

<style scoped>

#idCard1 {
  margin-top: 0;
  margin-left: 0;
  margin-right: 0;
  /* background-color: #37474F;  */
  background-color: #161616;
  height: calc(100vh - 50px);
  overflow-y: scroll;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */    
}

#idCard1::-webkit-scrollbar {
    display: none;
}

.fontArialN {
  font-family: Arial; 
  font-style: normal; 
  font-weight: normal;   
  color: black;
}

.fontArialB {
  font-family: Arial; 
  font-style: normal; 
  font-weight: bold;   
  color: black;
}

</style>
