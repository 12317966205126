



// =============================================================================================
//             objects
// =============================================================================================


// for display in DevInfo.vue
// DevInfo.vue  object in main.js\aeditems[], 
function createDevInfo () {
  return {
    "id": 0,
    "devSn": '',                 
    "devModel": '',                 
    "battCap": '/',                 
    "PadsShelfLife": '/',            
    "InstLoc": '/',                   // replace Address
    "CustName": '/',                 
    "WarrantyStatus": '/',
    "SelfTestSendPeriod": 1,        
    //"AssetSn": '',                 
    "DevStatus": '/',          
    "PadStatus": '/',          
    "InstTime": '/',                 
    "DevSelfTestPeriod": 1,
    "WirelessStrength": '/',
    "NetworkType": '/',
    //"DevAdmin": '',                 
    //"TotalRepBatt": 0,              
    //"AssetOwner": '',               
    //"ExtendedWarranty": '',         
    "LastReportUploadTime": '/',
    //"TotalRepPads": 0,              
    //"RescueTimes": 0,               
    "WarrantyExpireDate": '/',       
    //"ICCID": '',                 
    "battSn": '/',
    "LocAttr": '/',             
    "BattStatus": '/',         
    "Offline": '/',
    //"Address": '',
    "Pic": [],
    "DeliveryAddr": '/',
    "MapLoc": '/',
    "PadType": '/',
    "Map": 'Map',                     
  };
}


// DevInfo.vue:  obj to store filter settings
function createFilters () {
  return {
    "devSn": '', "CustName": '', "DevStatus": '',
    "InstLoc": '', "battCap": '', "PadStatus": '',
    "InstTime": '', "devModel": ''
  };
}

function createUserInfo (xObj) {
  let strEmail = xObj.email;
  if (strEmail === "") strEmail =  xObj.email1;
  if (strEmail === "") strEmail =  xObj.email2;
  let strName = xObj.username;
  if (strName === "") strName = xObj.name;
  return {
    //"ID": xObj.username,
    "Username":   strName,
    "email":      strEmail
    //"email1":     xObj.email1,
    //"email2":     xObj.email2,
    //"phone1":     xObj.phone1,
    //"phone2":     xObj.phone2,
    //"userType":   xObj.userType
    //"Creaetd at": xObj.createdAt,
    //"Updated by": xObj.updatedBy
  }
}


// DevInfo.vue:  display the following column by default (aeditems[] Table)
const DialogOptionsDef = {
  "sn": true,
  "devModel": true,
  "battCap": true,
  "PadsShelfLife": true,
  "InstLoc": true,
  "CustName": true,
  "WarrantyStatus": true,
  "DevStatus": true,
  "PadStatus": true,
  "WirelessStrength": true,
  //"RescueTimes": true,
  "WarrantyExpireDate": true,
  "MapLoc": true,
  "Map": true
}

// =============================================================================================
//             [Table Headers]
// =============================================================================================

//-----------------------------------------------------
//      [Device List]
//-----------------------------------------------------
// DevList table headers definition:
// - defined in:  DevInfo.vue\cHeaderL - cHeaderL  OR  >>> table th { }  OR  here
const sClassl = "amber darken-3 black--text text-body-2 font-weight-bold ma-0 pr-2 border-l-sm";

const TblHeaders = [
  {text: 'Device Sn', value: 'devSn', filterable: true, class: sClassl},
  {text: 'Model', value: 'devModel', filterable: true, class: sClassl},
  {text: 'Device Status', value: 'DevStatus', filterable: true, class: sClassl},
  {text: 'Pads Status', value: 'PadStatus', filterable: true, class: sClassl},
  {text: 'Battery Capacity', value: 'battCap', filterable: true, class: sClassl},
  {text: 'Pads Shelf Life', value: 'PadsShelfLife', filterable: false, class: sClassl},
  {text: 'Warranty expire date', value: 'WarrantyExpireDate', filterable: false, class: sClassl},
  {text: 'Organization', value: 'CustName', filterable: true, class: sClassl},
  {text: 'Location', value: 'MapLoc', filterable: false, class: sClassl},
  {text: 'Install.Time', value: 'InstTime', filterable: true, class: sClassl},
  {text: 'Self Test report sending period (days)', value: 'SelfTestSendPeriod', filterable: false, class: sClassl},
  //{text: 'Asset SN', value: 'AssetSn'},
  //{text: 'Self Test Period', value: 'DevSelfTestPeriod', filterable: false, class: sClassl},
  {text: 'Wireless Signal Strength', value: 'WirelessStrength', filterable: false, class: sClassl},
  {text: 'Network Type', value: 'NetworkType', filterable: false, class: sClassl},
  //{text: 'Device Admin', value: 'DevAdmin', class: sClassl},
  //{text: 'No. of replaced battery', value: 'TotalRepBatt', filterable: false, class: sClassl},
  //{text: 'Asset Owner', value: 'AssetOwner', class: sClassl},
  //{text: 'Extended Warranty Period', value: 'ExtendedWarranty'},
  {text: 'Last report uploaded time', value: 'LastReportUploadTime', filterable: false, class: sClassl},
  //{text: 'No. of replaced pads', value: 'TotalRepPads', filterable: false, class: sClassl},
  //{text: 'Rescue times', value: 'RescueTimes'},
  //{text: 'ICCID', value: 'ICCID'},
  //{text: 'Location Attribute', value: 'LocAttr', filterable: false, class: sClassl},
  //{text: 'Address', value: 'Address', filterable: false, class: sClassl},
  {text: 'Warranty Months', value: 'WarrantyStatus', filterable: false, class: sClassl},
  {text: 'Instl.Location', value: 'InstLoc', filterable: true, class: sClassl},
  {text: 'Battery SN', value: 'battSn', filterable: true, class: sClassl},
  {text: 'Pads Type', value: 'PadType', filterable: true, class: sClassl},
  {text: 'Map', value: 'Map', filterable: false, class: sClassl}
]

//-----------------------------------------------------
//      [Device Management] - [Detail]
//-----------------------------------------------------
const sClassd = 'amber darken-3 black--text text-body-2 font-weight-bold';

// (2a) Device Detail - Warning Table: low batt, pads expire, 4G expire
const TblWarningHeaders = [
  { text: 'Received Time', value: 'receiveTime', width: '170px',class: sClassd },
  { text: 'Waring Type', value: 'warnType',class: sClassd}
  //{ text: 'First Warning Time', value: 'firstTime' },
  //{ text: 'Last Warning Time', value: 'lastTime' },
]

// (3a) Device Detail - Offline Table
const TblOfflineHeaders = [
  { text: 'Received Time', value: 'receiveTime', width: '170px',class: sClassd},
  { text: 'Offline in days ', value: 'offlineDays',class: sClassd}
  //{ text: 'First Offline Time', value: 'firstTime' },
  //{ text: 'Last Offline Time', value: 'lastTime' },
]

// (4a) Device Detail - Fault Table
const TblFaultHeaders = [
  { text: 'Received Time', value: 'receiveTime', width: '250px', class: sClassd},
  { text: 'Message', value: 'faultMessage', class: sClassd},
  //{ text: 'Fault Type', value: 'faultType' },
  //{ text: 'Error Code', value: 'errorCode', class: sClassd }
  //{ text: 'First Failure Time', value: 'firstTime' },
  //{ text: 'Last Failure Time', value: 'lastTime' },
]

// (5a) Device Detail - Self Test Table
const TblSelfTestHeaders = [
  { text: 'Received Time', value: 'receiveTime', width: '170px', class: sClassd},
  { text: 'Self Test Time', value: 'selfTestTime', width: '170px', class: sClassd},
  { text: 'Self Test Type', value: 'selfTestType', class: sClassd},
  { text: 'Test Result', value: 'testResult', class: sClassd},
  //{ text: 'Complete Test Result', value: 'testResultFinal', class: sClassd},  
  { text: 'Message', value: 'faultMessage', class: sClassd},
  //{ text: 'Fault Code', value: 'errorCode', class: sClassd},
  //{ text: 'Self Test Period', value: 'selfTestPeriod', class: sClassd},
  { text: 'Sending Period', value: 'sendingPeriod', class: sClassd},
  { text: 'Battery SN', value: 'battSn', class: sClassd}
]

// (6a) Device Detail - Rescue Summary Table
const TblRescueSummaryHeaders = [
  { text: 'Received Time', value: 'receiveTime', width: '170px', class: sClassd},
  //{ text: 'Test Time', value:'testTime', width: '170px', class: sClassd},
  { text: 'Total Duration(s)', value:'rescueTime', width: '160px', class: sClassd},
  { text: 'CPR Duration(s)', value:'cprDuration', width: '160px', class: sClassd},
  { text: 'Shocks(times)', value:'shock', class: sClassd},
]

// (7a) Device Detail - Device State
const TblDevStateHeaders = [
  { text: 'Received Time', value: 'receiveTime', width: '170px', class: sClassd},
  { text: 'Test Time', value:'testTime', width: '170px', class: sClassd},
  { text: 'Net Type', value:'netType', width: '130px', class: sClassd},
  { text: 'Signal Level', value:'signalLevel', width: '130px', class: sClassd},
  { text: 'Status', value:'status', class: sClassd},
]

//-----------------------------------------------------
//      [Event Management]
//-----------------------------------------------------
// HK time, (GMT removed)
// (4b) Fault Table Header
const TblFaultHeaders2 = [
  { text: 'Device SN', value: 'devSn', width: '140px', class: sClassd },
  { text: 'Received Time', value: 'receiveTime', width: '170px', class: sClassd},
  { text: 'Message', value: 'faultMessage', width: '340px', class: sClassd },
  { text: 'Fault Type', value: 'faultType', width: '80px', class: sClassd },
  //{ text: 'Fault Code', value: 'errorCode', width: '200px', class: sClassd },
  //{ text: 'First Failure Time', value: 'firstTime' },
  //{ text: 'Last Failure Time', value: 'lastTIme' },
  { text: 'Organization', value: 'CustName', class: sClassd },
  { text: 'Install Location', value: 'InstLoc', width: '150px', class: sClassd }
]

// (2b) Warning Table Header
const TblWarningHeaders2 = [
  { text: 'Device SN', value: 'devSn', width: '140px', class: sClassd},
  { text: 'Received Time', value: 'receiveTime', width: '170px', class: sClassd},
  { text: 'Message', value: 'warnMessage', width: '340px', class: sClassd},
  { text: 'Waring Type', value: 'warnType', width: '150px', class: sClassd},
  //{ text: 'First Warning Time', value: 'firstTime'},
  //{ text: 'Last Warning Time', value: 'lastTIme'},
  { text: 'Organization', value: 'CustName', class: sClassd},
  { text: 'Installation Loc', value: 'InstLoc', class: sClassd}
]

// (7b) Offline Table Header
const TblOfflineHeaders2 = [
  { text: 'Device SN', value: 'devSn', width: '140px', class: sClassd},
  { text: 'Received Time', value: 'receiveTime', width: '170px', class: sClassd},  
  //{ text: 'First Offline Time', value: 'firstTime' },
  //{ text: 'Last Offline Time', value: 'lastTIme' },
  { text: 'Offline in days', value: 'offlineDays', width: '150px', class: sClassd},  
  { text: 'Organization', value: 'CustName', class: sClassd },
  { text: 'Installation Loc', value: 'InstLoc', class: sClassd }
]

const TblRescueHeaders2 = [
  { text: 'Device SN', value: 'devSn', width: '140px', class: sClassd},
  { text: 'Received Time', value: 'receiveTime', width: '170px', class: sClassd},
  //{ text: 'First Offline Time', value: 'firstTime' },
  //{ text: 'Last Offline Time', value: 'lastTIme' },
  { text: 'Total Duration(s)', value:'rescueTime', width: '160px', class: sClassd},
  { text: 'CPR Duration(s)', value: 'cprDuration', width: '160px', class: sClassd },
  { text: 'Shock(times)', value: 'shock', width: '150px', class: sClassd },
  { text: 'Organization', value: 'CustName', class: sClassd },
  { text: 'Installation Loc', value: 'InstLoc', class: sClassd }
]

// (9) Self Test (TestInfo) Table Header
const TblTestInfoHeaders2 = [
  { text: 'Device SN', value: 'devSn', width: '140px', class: sClassd},
  { text: 'Received Time', value: 'receiveTime', width: '170px', class: sClassd},
  { text: 'Test Time', value: 'testTime', width: '170px', class: sClassd},  
  { text: 'Message', value: 'faultMessage', width: '340px', class: sClassd},
  { text: 'Fault Type', value: 'faultType', width: '150px', class: sClassd},
  //{ text: 'Fault Code', value: 'errorCode', width: '150px', class: sClassd },
  { text: 'Organization', value: 'CustName', width: '150px', class: sClassd},
  { text: 'Installation Loc', value: 'InstLoc', width: '150px', class: sClassd}
]


// event management - fault, Warning, offline (both come from TestReport)
function createEvnt () {
  return {
    "devSn": '',
    "devModel": '',
    "faultType": '',
    "errorCode": '',
    "faultMessage": '',
    "firstTime": '',
    "lastTIme": '',
    "receiveTime": '',
    "testTime": '',
    "CustName": '',
    "InstLoc": '',
    "warnType": '',
    "warnMessage": '',
    "offlineDays": '',
    "rescueTime": '',
    "clickable": true,
    "hidden": false
  };
}

// event management - Rescue/Emergency (come from emergence report)
function createEvntRescue () {
  return {
    "devSn": '',
    "devModel": '',
    "firstTime": '',
    "lastTIme": '',
    "receiveTime": '',
    "CustName": '',
    "InstLoc": '',
    "offlineDays": '',
    "cprDuration": '',
    "shock": 0,
    "hidden": false
  }
}

// faultType: All, Selft-test failed
// warnType:  All, 4G card expired, Loc. Offset, Pads to expire

//-----------------------------------------------------
//      User Management
//-----------------------------------------------------
const TblUsertHeader = [
  { text: 'Username', value: 'userName' },
  { text: 'Name', value: 'userName2' },
  { text: 'System Role', value: 'systemRole' },
  { text: 'Customer', value: 'custName' },
  { text: 'Email', value: 'email' },
  { text: 'Creator', value: 'creator' },
  { text: 'Creation Time', value: 'createTime' },
  { text: 'Validity', value: 'validity' }
]


// =============================================================================================
//             [Menu]  Menu items appear on GUI
// =============================================================================================
const ModelData = 
[
	"All",
  "BeneHeart C1",
  "BeneHeart C1 Fully Automatic",
  "BeneHeart C1A",
  "BeneHeart C1A Fully Automatic",
  "BeneHeart C2",
  "BeneHeart C2 Fully Automatic",
  "BeneHeart C2A",
  "BeneHeart C2A Fully Automatic",
  "BeneHeart D1",
  "BeneHeart S1",
  "BeneHeart S1 Fully Automatic",
  "BeneHeart S1A",
  "BeneHeart S1A Fully Automatic",
  "BeneHeart S2",
  "BeneHeart S2 Fully Automatic",
  "BeneHeart S2A",
  "BeneHeart S2A Fully Automatic",
  "Unknown"
]

// Device Info
const DeviceStatusData = ['All', 'Normal', 'Fault', 'Warning', 'Offline']

const BattStatusData = ['All', 'Normal', 'Low', 'Fault']

const PadsStatusData = ['All', 'Normal', 'Expired Soon', 'Expired']

// Event Management menu
const FaultTypes = ['All', 'Self Test Failed', 'Offline']

const WarningTypes = ['All', '4G Card Expired', 'Loc Offset', 'Pads to Expired']

// User Management
const UserRole = ['All', 'Device Admin', 'Guest']

// Networking
const menuNetwork = ['Wifi', '4G', 'N/A']


// =============================================================================================
//             [protocol]  protocol data
// =============================================================================================
const rptDeviceType = [
  'Unknown',
  'BeneHeart D1',
  'BeneHeart S1',
  'BeneHeart S2',
  'BeneHeart S1 Fully Automatic',  // 4
  'BeneHeart S2 Fully Automatic',
  'BeneHeart S1A',
  'BeneHeart S2A',
  'BeneHeart S1A Fully Automatic', // 8
  'BeneHeart S2A Fully Automatic',
  'BeneHeart C1',
  'BeneHeart C2',
  'BeneHeart C1 Fully Automatic', // 12
  'BeneHeart C2 Fully Automatic',
  'BeneHeart C1A',
  'BeneHeart C2A',
  'BeneHeart C1A Fully Automatic',
  'BeneHeart C2A Fully Automatic'  // 17
]

const rptTestType = [
  'Unknown',
  'Daily Test',
  'Weekly Test',
  'Monthly Test',
  'Season Test',
  'Battery Insert',
  'User Test'
]

const rptNetworkType = [
  'Wifi',
  '4G',
  'No Network'
]

const rptPacketType = [
  'Unknown',
  'Self Test Info',
  'Update Request',
  'Log',
  'Update Response',
  'Update Ack',           // 5
  'Device Info',
  'Request',
  'FTP Update File Info',
  'Date File Info',
  'Emergency Info',       // 10
  'Net Test Package',
  'Device COM Info',
  'Remote Upgrade State',
  'Remote Upgrade Task'
]

function getTblItem(xtbl, xidx) {
  if (xidx >= 0 && xidx < xtbl.length) {
    return xtbl[xidx];
  }
  return "";
}


// =============================================================================================
//             Dialog Tables
// =============================================================================================
//-----------------------------------------------------
//      Device List - Add Device table
//-----------------------------------------------------
const TblAddDevice = [
  { text: 'Username', value: 'userName' },
  { text: 'Name', value: 'userName2' },
  { text: 'System Role', value: 'systemRole' },
  { text: 'Customer', value: 'custName' },
  { text: 'Email', value: 'email' },
  { text: 'Creator', value: 'creator' },
  { text: 'Creation Time', value: 'createTime' },
  { text: 'Validity', value: 'validity' }
]







// =============================================================================================
//             Export
// =============================================================================================

export { DialogOptionsDef, TblHeaders, TblWarningHeaders, TblOfflineHeaders, TblFaultHeaders,
    TblSelfTestHeaders, TblRescueSummaryHeaders, TblDevStateHeaders,
    TblFaultHeaders2, TblWarningHeaders2, TblOfflineHeaders2, TblRescueHeaders2, TblTestInfoHeaders2,
    TblUsertHeader, createEvnt, createEvntRescue, createUserInfo,
    ModelData, DeviceStatusData, BattStatusData, PadsStatusData,
    FaultTypes, WarningTypes, UserRole, rptPacketType,
    rptDeviceType, rptTestType, rptNetworkType, getTblItem,
    TblAddDevice, createDevInfo, createFilters,
};

