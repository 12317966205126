

function createDevStateReportSn() {
  return {
    "receiveTime": "",
    "testTime": "",
    "netType": "",
    "signalLevel": "",
    "status": "",
  };
}


//-----------------------------------------------------
//  [createTestReportSn]  called from DevInfoDetail - TblFault, TblWarning, ....
//-----------------------------------------------------
function createEmergReportSn () {
  return {
    "devSn": '',
    "rescueTime": 0,
    "cprDuration": 0,
    "shock": 0,
    "receiveTime": '',
    "hidden": false,
    "testTime": '',
    "state": ''
  };
}

const TblEmergency = [
  {
    "id": 1,
    "datetime": "2024-01-25T03:22:14.000Z",
    "sn": "AE9-13000067",
    "devtype": 13,
    "packtype": 10,
    "version": "02.08.09.01",
    "totalduration": 69,
    "cprduration": 30,
    "shock": 1,
    "state": ""
  },
  {
    "id": 2,
    "datetime": "2024-01-13T03:22:14.000Z",
    "sn": "AE7-94000043",
    "devtype": 13,
    "packtype": 10,
    "version": "02.08.09.01",
    "totalduration": 69,
    "cprduration": 30,
    "shock": 1
  }
]


// DESCRIPTION:  find the last test report
function findDevSn (xdevSn) {
  for(let idx=0; idx < TblEmergency.length; idx++) {
    if (TblEmergency[idx].sn === xdevSn) return TblEmergency[idx];
  } 
  return null;
}

// DESCRIPTION:  find all reports with the same devSn
function findAllDevSn (xdevSn) {
  let ary = [];
  for(let idx=0; idx < TblEmergency.length; idx++) {
    if (TblEmergency[idx].sn === xdevSn) ary.push(TblEmergency[idx]);
  } 
  return ary;
}

// =============================================================================================
//             Export
// =============================================================================================

export { 
  TblEmergency, findDevSn, findAllDevSn, createEmergReportSn, createDevStateReportSn
};

