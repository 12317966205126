<!-- --------------------------------------------------------------------------------
                      dialogDevOptions.vue

DESCRIPTIONS
  - set table column options
---------------------------------------------------------------------------------- -->
<template>
  <div class="text-center">

      <v-dialog v-model="bDialog" scrollable content-class="my-custom-dialog" width="600px">
        <template  v-slot:activator="{ on, attrs }">
          <v-btn width="30px" color="transparent" v-bind="attrs" v-on="on" @click="btnOnDialog">
            <!-- <v-app-bar-nav-icon color="#37474F"></v-app-bar-nav-icon>  -->
            <svg-icon type="mdi" :path="pathMenu"></svg-icon>
          </v-btn>
        </template>

        <v-card width="100%" height="auto" class="rounded-lg overflow-hidden">
          <!-- <v-layout justify-center class="text-subtitle-1 primary--text font-weight-bold">Select table columns to display</v-layout> -->
          <!-- <v-card height="40px" class="border-0" style="background-color: #546E7A;">
            <v-layout justify-center class="transparent text-h6 white--text font-weight-bold">Add Device</v-layout>
          </v-card>  -->
          <v-card width="100%" height="40px" class="d-flex flex-row ma-0 py-auto rounded-t-lg rounded-b-0" style="background-color: #546E7A;">
            <v-sheet width="98%" height="100%" class="mt-1 text-center text-h5 white--text fontArialB" style="background-color: #546E7A;">Select fields to display</v-sheet>
            <v-btn icon color="black" class="mt-1" @click="onBtnClose" style="position: absolute; right: 0;">
              <!-- <v-icon>mdi-close</v-icon> -->
              <svg-icon type="mdi" :path="pathClose" size="32" class="mt-0"></svg-icon>
            </v-btn>

          </v-card>

          <v-divider></v-divider>

          <v-card class="d-flex flex-row">
            <v-card width="49%" height="100%" class="ml-2 pa-0 mt-2 border-0 elevation-0">
              <!-- <v-card dense class="pa-0 ma-1 border-0 elevation-0" height="32px" v-for="data,i in Object.keys(options1)" :key="i">
                <v-checkbox  :v-model="ary1" :label="data" :value="data"  @click="getCheckBox(data)"
                class="black--text pa-0 ma-0" style="font-family: Arial; font-style: normal; font-weight: bold;"></v-checkbox>
              </v-card> -->
              <!-- <v-checkbox v-model="ary1" label="devSn" value="devSn"></v-checkbox>
              <v-checkbox v-model="ary1" label="devModel" value="devModel"></v-checkbox>
              <v-checkbox v-model="ary1" label="battCap" value="battCap"></v-checkbox> -->
              <v-checkbox dense v-model="ary1" v-for="(item, i) in Object.keys(options1)" :key="i" :label="item" :value="item" 
              @click="getCheckBox(item)" height="18px" class="ma-0 pa-0 fontArialN"></v-checkbox>
            </v-card>

            <v-card width="49%" height="100%" class="ml-2 pa-0 mt-2 border-0 elevation-0">
              <!-- <v-card class="pa-0 ma-1 border-0 elevation-0" height="32px" v-for="data,i in Object.keys(options2)" :key="i">
                <v-checkbox  :v-model="ary2" :label="data" :value="data"  @click="getCheckBox(data)"
                class="black--text pa-0 ma-0" style="font-family: Arial; font-style: normal; font-weight: bold;"></v-checkbox>
              </v-card>  -->
              <v-checkbox dense v-model="ary2" v-for="(item, i) in Object.keys(options2)" :key="i" :label="item" :value="item" 
              @click="getCheckBox(item)" height="18px" class="ma-0 pa-0 fontArialN"></v-checkbox>
            </v-card>

          </v-card>

          <!--------------------------- Exit Buttons ---------------------------------->
          <!-- <v-layout justify-center>  -->
          <v-card-actions>
            <v-btn small color="primary" width="120px" class="text-capitalize fontArialB" @click="onBtnSelectAll">Select All</v-btn>
            <v-btn small color="primary" width="120px" class="text-capitalize fontArialB" @click="onBtnSelectDefault">Select Default</v-btn>
            <v-btn small color="primary" width="120px" class="text-capitalize fontArialB" @click="onBtnSelectClear">Clear All</v-btn>
            <v-spacer></v-spacer>
            <v-btn small color="primary" width="80px" class="text-capitalize fontArialB" @click="onBtnClose">Cancel</v-btn>
            <v-btn small color="primary" width="80px" class="text-capitalize fontArialB" @click="sendEmit">Save</v-btn>
            <!-- <v-btn small color="primary" width="80px" @click="bDialog = false">Cancel</v-btn> -->
          </v-card-actions>

        </v-card>
      </v-dialog>

  </div>
</template>

<script>
import * as Tbl from '@/js/tables.js'

import SvgIcon from '@jamescoyle/vue-icon';
import { mdiMenu, mdiClose  } from '@mdi/js';

  export default {
    components: {
      SvgIcon
    },

    data () {
      return {
        bDialog: false,
        pathMenu: mdiMenu,
        pathClose: mdiClose,
        // first start use default options
        bFirstStart: true,
        ary1: [],
        ary2: [],
        // to turn on individual header edit tables.js\TblHeaders, DialogOptionsDef (display)
        options: {
          "devSn": true,
          "devModel": false,
          "DevStatus": false,
          "PadStatus": false,
          "battCap": false,         
          "PadsShelfLife": false,
          "WarrantyExpireDate": false,
          "CustName": false,
          "MapLoc": false,
          "InstTime": false,
          "SelfTestSendPeriod": false,
          "WirelessStrength": false,
          "NetworkType": false,
          "LastReportUploadTime": false,
          "WarrantyStatus": false,
          "InstLoc": false,
          "battSn": false,
          "PadType": false,
          //"AssetSn": false,
          //"DevSelfTestPeriod": false,
          //"DevAdmin": false,
          //"TotalRepBatt": false,
          //"AssetOwner": false,
          //"ExtendedWarranty": false,
          //"Networking": false,
          //"TotalRepPads": false,
          //"RescueTimes": false,
          //"Branch": false,
          //"ICCID": false,
          //"LocAttr": false,
          "Map": true
        },

        options1: {
          "devSn": true,
          "devModel": false,
          "DevStatus": false,
          "PadStatus": false,
          "battCap": false,         
          "PadsShelfLife": false,
          "WarrantyExpireDate": false,
          "CustName": false,
          "MapLoc": false,
          "InstTime": false
        },

        options2: {
          "SelfTestSendPeriod": false,
          "WirelessStrength": false,
          "NetworkType": false,
          "LastReportUploadTime": false,
          "WarrantyStatus": false,
          "InstLoc": false,
          "battSn": false,
          "PadType": false,
          "Map": true
        },

        optionsDef: Tbl.DialogOptionsDef

      }
    },

    methods: {
      // DESCRIPTION:  first function to call when entering this component
      btnOnDialog () {
        if (!this.bFirstStart) {
          //console.log('btnOnDialog-not first time, not def'); //debug
          //this.loadOptions();    // bugs
        } else {
          //console.log('btnOnDialog-first time, load def'); //debug
          // first start
          this.bFirstStart = false;
          this.onBtnSelectDefault();
        }
      },

      // DESCRIPTION:  handle CLOSE button
      onBtnClose () {
        this.bDialog = false;
      },

      // DESCRIPTION:  handle SELECT ALL options button
      onBtnSelectAll () {
        this.clearArray(this.ary1);
        this.clearArray(this.ary2);
        this.setObj(this.options1, this.ary1, true);
        this.setObj(this.options2, this.ary2, true);
        let vAry = Object.keys(this.options);
        for(let idx=0; idx < vAry.length; idx++) {
          this.options[vAry[idx]] = true;
        }
      },

      // DESCRIPTION:  clear all options
      onBtnSelectClear () {
        this.clearArray(this.ary1);
        this.clearArray(this.ary2);
        let vAry = Object.keys(this.options1);
        for(let idx=0; idx < vAry.length; idx++) {
          this.options1[vAry[idx]] = false;
        }
        vAry = Object.keys(this.options2);
        for(let idx=0; idx < vAry.length; idx++) {
          this.options2[vAry[idx]] = false;
        }
        vAry = Object.keys(this.options);
        for(let idx=0; idx < vAry.length; idx++) {
          this.options[vAry[idx]] = false;
        }
        this.defaultCheckBox();
      },

      // DESCRIPTION:  handle SELECT DEFAULT options button
      onBtnSelectDefault () {
        this.clearArray(this.ary1);
        this.clearArray(this.ary2);
        this.clearOptions();
        let vAry = Object.keys(this.optionsDef);
        let vKey = '';
        for (let idx = 0; idx < vAry.length; idx++) {
          vKey = vAry[idx];
          if (vKey in this.options1) {
            this.options1[vKey] = true;
            this.ary1.push(vKey);
          } else if (vKey in this.options2) {
            this.options2[vKey] = true;
            this.ary2.push(vKey);
          }
          if (vKey in this.options) {
            this.options[vKey] = true;
          }
        }
        this.defaultCheckBox();
      },

      // DESCRIPTION:  set devSn, Map checkbox
      defaultCheckBox () {
        this.options["devSn"] = true;
        this.options["Map"] = true;
        this.options1["devSn"] = true;
        this.ary1.push("devSn");
        this.options2["Map"] = true;
        this.ary2.push("Map");
      },

      loadOptions () {
        this.clearArray(this.ary1);
        this.clearArray(this.ary2);
        let vAry = Object.keys(this.options);
        let vKey = '';
        for (let idx = 0; idx < vAry.length; idx++) {
          vKey = vAry[idx];
          if (vKey in this.options1) {
            this.options1[vKey] = true;
            this.ary1.push(vKey);
          } else if (vKey in this.options2) {
            this.options2[vKey] = true;
            this.ary2.push(vKey);
          }
        }
      },

      // DESCRIPTION:  emit options to parent
      sendEmit () {
        this.$emit('dialogEmit', this.options);
        this.bDialog = false;
      },

      // DESCRIPTION:  toggle checkbox values, used in <v-checkbox>
      getCheckBox (xdata) {
        if (!this.options[xdata]) {
          this.options[xdata] = true;
        } else {
          this.options[xdata] = false;
        }
      },

      clearArray (xary) {
        xary.splice(0);
        xary.length = 0;
      },

      setObj (xobj, xary, xval) {
        let vAry = Object.keys(xobj);
        for(let idx=0; idx < vAry.length; idx++) {
          if (xval) {
            xary.push(vAry[idx]);
            xobj[vAry[idx]] = xval;
          } else {
            xary.pop(vAry[idx]);
            xobj[vAry[idx]] = xval;            
          }
        }
      },

      clearOptions () {
        let vAry = Object.keys(this.options);
        for (let idx = 0; idx < vAry.length; idx++) {
          if (vAry[idx] in this.options) {
            this.options[vAry[idx] ] = false;
          }
        }
        this.options['devSn'] = true;
        this.options1['devSn'] = true;
      }
    }
  }
</script>

<style scoped>


.cBorder {
  border: 1px solid grey;
}

.fontArialN {
  font-family: Arial; 
  font-style: normal; 
  font-weight: normal;   
  color: black;
}

.fontArialB {
  font-family: Arial; 
  font-style: normal; 
  font-weight: bold;   
  color: black;
}



</style>
