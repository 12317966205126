


// =============================================================================================
//             
// =============================================================================================

//-----------------------------------------------------
//  [createTestReportSn]  called from DevInfoDetail - TblFault, TblWarning, ....
//-----------------------------------------------------
function createTestReportSn () {
  return {
    "devSn": '',
    "firstTime": '',
    "lastTime": '',
    "receiveTime": '',
    "warnType": '',
    "faultType": '',
    "errorCode": '',
    "faultMessage": '',
    "cprDuration": 0,
    "signalStrength": 0,
    "statusRefreshTime": '',
    "selfTestTime": '',
    "selfTestPeriod": 1,
    "sendingPeriod": 1,
    "selfTestType": 0,
    "testResult": 0,
    "testResultFinal": 0,
    "offlineDays": 0,
    "rescueTime": "",
    "battSn": '',
    "hidden": false
  };
}



//-----------------------------------------------------
//         [TblTestInfo]
//-----------------------------------------------------
const TblTestInfo = [
  {
    "id": 1, "datetime": "2024-04-03T08:07:44.000Z", "testtime": "2024-01-25T03:00:14.000Z",
    "sn": "AE9-13000067", "testresult": 0, "relatedresult": 0, 
    "testerrorcode": "454,416,151", "testtype": 6,
    "devinfo": {"devtype": 13, "version": "02.08.09.01","packtype": 1},
    "netinfo": {"NetType": 1,  "TestPeriod": 1, "SignalLevel": 0, "TransmissionPeriod": 1},
    "testitems": {
      "ecg":   {"des": "", "name": "ECG",  "result": 1},
      "pads":  {"des": "Pads Expires: 2022/5", "name": "Pads", "result": 0},
      "joule": {"des": "", "name": "360J", "result": 1},
      "battery": {"des": "Bat Capacity: 0 mAh","name": "Battery","result": 0},
      "therapy": {"des": "", "name": "Therapy", "result": 1},
      "mainboard": {"des": "", "name": "MainBoard", "result": 1},
      "padsExpiried": {"des": "", "name": "PadsExpiried", "result": 1},
      "padsExpireSoon": {"des": "", "name": "PadsExpireSoon", "result": 0},
      "batTotalCapacity": {"des": "", "name": "BatTotalCapacity", "result": 4200},
      "padsExpiringDate": {"des": "", "name": "PadsExpiringDate", "result": "2022- 5- 7"},
      "batResidualCapacity": {"des": "","name": "BatResidualCapacity","result": 0}
    },
    "relatedtest": {
      "key":   {"des": "","name": "Key","result": 1},
      "j200":  {"des": "","name": "200J","result": 1},
      "j360":  {"des": "","name": "360J","result": 1},
      "voice": {"des": "","name": "Voice","result": 1}
    },
    "fourg": null    
  },
  {
    "id": 2, "datetime": "2024-04-02T08:07:44.000Z", "testtime": "2024-01-25T03:14:15.000Z",
    "sn": "AE7-94000043", "testresult": 0, "relatedresult": 0, 
    "testerrorcode": "454,151", "testtype": 6,
    "devinfo": {"devtype": 13, "version": "02.08.09.01","packtype": 1},
    "netinfo": {"NetType": 1,  "TestPeriod": 1, "SignalLevel": -115, "TransmissionPeriod": 1},
    "testitems": {
      "ecg":   {"des": "", "name": "ECG",  "result": 1},
      "pads":  {"des": "", "name": "Pads", "result": 1},
      "joule": {"des": "", "name": "360J", "result": 1},
      "battery": {"des": "Bat Capacity: 0 mAh","name": "Battery","result": 0},
      "therapy": {"des": "", "name": "Therapy", "result": 1},
      "mainboard": {"des": "", "name": "MainBoard", "result": 1},
      "padsExpiried": {"des": "", "name": "PadsExpiried", "result": 0},
      "padsExpireSoon": {"des": "", "name": "PadsExpireSoon", "result": 0},
      "batTotalCapacity": {"des": "", "name": "BatTotalCapacity", "result": 4200},
      "padsExpiringDate": {"des": "", "name": "PadsExpiringDate", "result": "2024- 6- 2"},
      "batResidualCapacity": {"des": "","name": "BatResidualCapacity","result": 210}
    },
    "relatedtest": {
      "key":   {"des": "","name": "Key","result": 1},
      "j200":  {"des": "","name": "200J","result": 1},
      "j360":  {"des": "","name": "360J","result": 1},
      "voice": {"des": "","name": "Voice","result": 1}
    },
    "fourg": null    
  },
  {
    "id": 3, "datetime": "2024-04-01T08:07:44.000Z", "testtime": "1970-01-20T17:55:12.000Z",
    "sn": "AE9-13000024", "testresult": 1, "relatedresult": 1, 
    "testerrorcode": "/", "testtype": 6,
    "devinfo": {"devtype": 13, "version": "02.08.09.01","packtype": 1},
    "netinfo": {"NetType": 1,  "TestPeriod": 1, "SignalLevel": -108, "TransmissionPeriod": 1},
    "testitems": {
      "ecg":   {"des": "", "name": "ECG",  "result": 1},
      "pads":  {"des": "", "name": "Pads", "result": 1},
      "joule": {"des": "", "name": "360J", "result": 1},
      "battery": {"des": "Bat Capacity: 0 mAh","name": "Battery","result": 0},
      "therapy": {"des": "", "name": "Therapy", "result": 1},
      "mainboard": {"des": "", "name": "MainBoard", "result": 1},
      "padsExpiried": {"des": "", "name": "PadsExpiried", "result": 0},
      "padsExpireSoon": {"des": "", "name": "PadsExpireSoon", "result": 0},
      "batTotalCapacity": {"des": "", "name": "BatTotalCapacity", "result": 4200},
      "padsExpiringDate": {"des": "", "name": "PadsExpiringDate", "result": "2024- 7- 2"},
      "batResidualCapacity": {"des": "","name": "BatResidualCapacity","result": 630}
    },
    "relatedtest": {
      "key":   {"des": "","name": "Key","result": 1},
      "j200":  {"des": "","name": "200J","result": 1},
      "j360":  {"des": "","name": "360J","result": 1},
      "voice": {"des": "","name": "Voice","result": 1}
    },
    "fourg": null    
  },
  {
    "id": 4, "datetime": "2024-03-31T08:07:44.000Z", "testtime": "1970-01-20T17:55:12.000Z",
    "sn": "AE9-95001013", "testresult": 1, "relatedresult": 1, 
    "testerrorcode": "", "testtype": 6,
    "devinfo": {"devtype": 13, "version": "02.08.09.01","packtype": 1},
    "netinfo": {"NetType": 0,  "TestPeriod": 1, "SignalLevel": -95, "TransmissionPeriod": 1},
    "testitems": {
      "ecg":   {"des": "", "name": "ECG",  "result": 1},
      "pads":  {"des": "", "name": "Pads", "result": 1},
      "joule": {"des": "", "name": "360J", "result": 1},
      "battery": {"des": "Bat Capacity: 0 mAh","name": "Battery","result": 0},
      "therapy": {"des": "", "name": "Therapy", "result": 1},
      "mainboard": {"des": "", "name": "MainBoard", "result": 1},
      "padsExpiried": {"des": "", "name": "PadsExpiried", "result": 0},
      "padsExpireSoon": {"des": "", "name": "PadsExpireSoon", "result": 0},
      "batTotalCapacity": {"des": "", "name": "BatTotalCapacity", "result": 4200},
      "padsExpiringDate": {"des": "", "name": "PadsExpiringDate", "result": "2024- 5-11"},
      "batResidualCapacity": {"des": "","name": "BatResidualCapacity","result": 1050}
    },
    "relatedtest": {
      "key":   {"des": "","name": "Key","result": 1},
      "j200":  {"des": "","name": "200J","result": 1},
      "j360":  {"des": "","name": "360J","result": 1},
      "voice": {"des": "","name": "Voice","result": 1}
    },
    "fourg": null    
  },
  {
    "id": 5, "datetime": "2024-03-30T08:07:44.000Z", "testtime": "1970-01-20T17:55:12.000Z",
    "sn": "AE9-95001014", "testresult": 1, "relatedresult": 1, 
    "testerrorcode": "/", "testtype": 6,
    "devinfo": {"devtype": 13, "version": "02.08.09.01","packtype": 1},
    "netinfo": {"NetType": 0,  "TestPeriod": 1, "SignalLevel": -90, "TransmissionPeriod": 1},
    "testitems": {
      "ecg":   {"des": "", "name": "ECG",  "result": 1},
      "pads":  {"des": "", "name": "Pads", "result": 1},
      "joule": {"des": "", "name": "360J", "result": 1},
      "battery": {"des": "Bat Capacity: 0 mAh","name": "Battery","result": 0},
      "therapy": {"des": "", "name": "Therapy", "result": 1},
      "mainboard": {"des": "", "name": "MainBoard", "result": 1},
      "padsExpiried": {"des": "", "name": "PadsExpiried", "result": 0},
      "padsExpireSoon": {"des": "", "name": "PadsExpireSoon", "result": 0},
      "batTotalCapacity": {"des": "", "name": "BatTotalCapacity", "result": 4200},
      "padsExpiringDate": {"des": "", "name": "PadsExpiringDate", "result": "2024-10- 2"},
      "batResidualCapacity": {"des": "","name": "BatResidualCapacity","result": 1890}
    },
    "relatedtest": {
      "key":   {"des": "","name": "Key","result": 1},
      "j200":  {"des": "","name": "200J","result": 1},
      "j360":  {"des": "","name": "360J","result": 1},
      "voice": {"des": "","name": "Voice","result": 1}
    },
    "fourg": null    
  },
  {
    "id": 6, "datetime": "2024-03-29T08:07:44.000Z", "testtime": "1970-01-20T17:55:12.000Z",
    "sn": "AE9-950TEST1", "testresult": 1, "relatedresult": 1, 
    "testerrorcode": "/", "testtype": 6,
    "devinfo": {"devtype": 13, "version": "02.08.09.01","packtype": 1},
    "netinfo": {"NetType": 0,  "TestPeriod": 1, "SignalLevel": -80, "TransmissionPeriod": 1},
    "testitems": {
      "ecg":   {"des": "", "name": "ECG",  "result": 1},
      "pads":  {"des": "", "name": "Pads", "result": 1},
      "joule": {"des": "", "name": "360J", "result": 1},
      "battery": {"des": "Bat Capacity: 0 mAh","name": "Battery","result": 0},
      "therapy": {"des": "", "name": "Therapy", "result": 1},
      "mainboard": {"des": "", "name": "MainBoard", "result": 1},
      "padsExpiried": {"des": "", "name": "PadsExpiried", "result": 0},
      "padsExpireSoon": {"des": "", "name": "PadsExpireSoon", "result": 0},
      "batTotalCapacity": {"des": "", "name": "BatTotalCapacity", "result": 4200},
      "padsExpiringDate": {"des": "", "name": "PadsExpiringDate", "result": "2025- 4- 2"},
      "batResidualCapacity": {"des": "","name": "BatResidualCapacity","result": 2730}
    },
    "relatedtest": {
      "key":   {"des": "","name": "Key","result": 1},
      "j200":  {"des": "","name": "200J","result": 1},
      "j360":  {"des": "","name": "360J","result": 1},
      "voice": {"des": "","name": "Voice","result": 1}
    },
    "fourg": null    
  },
  {
    "id": 7, "datetime": "2024-03-28T08:07:44.000Z", "testtime": "1970-01-20T17:55:12.000Z",
    "sn": "AE9-950TEST2", "testresult": 1, "relatedresult": 1, 
    "testerrorcode": "", "testtype": 6,
    "devinfo": {"devtype": 13, "version": "02.08.09.01","packtype": 1},
    "netinfo": {"NetType": 2,  "TestPeriod": 1, "SignalLevel": -46, "TransmissionPeriod": 1},
    "testitems": {
      "ecg":   {"des": "", "name": "ECG",  "result": 1},
      "pads":  {"des": "", "name": "Pads", "result": 1},
      "joule": {"des": "", "name": "360J", "result": 1},
      "battery": {"des": "Bat Capacity: 0 mAh","name": "Battery","result": 0},
      "therapy": {"des": "", "name": "Therapy", "result": 1},
      "mainboard": {"des": "", "name": "MainBoard", "result": 1},
      "padsExpiried": {"des": "", "name": "PadsExpiried", "result": 0},
      "padsExpireSoon": {"des": "", "name": "PadsExpireSoon", "result": 0},
      "batTotalCapacity": {"des": "", "name": "BatTotalCapacity", "result": 4200},
      "padsExpiringDate": {"des": "", "name": "PadsExpiringDate", "result": ""},
      "batResidualCapacity": {"des": "","name": "BatResidualCapacity","result": 4200}
    },
    "relatedtest": {
      "key":   {"des": "","name": "Key","result": 1},
      "j200":  {"des": "","name": "200J","result": 1},
      "j360":  {"des": "","name": "360J","result": 1},
      "voice": {"des": "","name": "Voice","result": 1}
    },
    "fourg": null    
  },
  {
    "id": 8, "datetime": "2024-03-24T08:07:44.000Z", "testtime": "1970-01-20T17:55:12.000Z",
    "sn": "AE9-13000937", "testresult": 1, "relatedresult": 1, 
    "testerrorcode": "", "testtype": 6,
    "devinfo": {"devtype": 13, "version": "02.08.09.01","packtype": 1},
    "netinfo": {"NetType": 2,  "TestPeriod": 1, "SignalLevel": -60, "TransmissionPeriod": 1},
    "testitems": {
      "ecg":   {"des": "", "name": "ECG",  "result": 1},
      "pads":  {"des": "", "name": "Pads", "result": 1},
      "joule": {"des": "", "name": "360J", "result": 1},
      "battery": {"des": "Bat Capacity: 0 mAh","name": "Battery","result": 0},
      "therapy": {"des": "", "name": "Therapy", "result": 1},
      "mainboard": {"des": "", "name": "MainBoard", "result": 1},
      "padsExpiried": {"des": "", "name": "PadsExpiried", "result": 0},
      "padsExpireSoon": {"des": "", "name": "PadsExpireSoon", "result": 0},
      "batTotalCapacity": {"des": "", "name": "BatTotalCapacity", "result": 4200},
      "padsExpiringDate": {"des": "", "name": "PadsExpiringDate", "result": ""},
      "batResidualCapacity": {"des": "","name": "BatResidualCapacity","result": 1274}
    },
    "relatedtest": {
      "key":   {"des": "","name": "Key","result": 1},
      "j200":  {"des": "","name": "200J","result": 1},
      "j360":  {"des": "","name": "360J","result": 1},
      "voice": {"des": "","name": "Voice","result": 1}
    },
    "fourg": null    
  }            
]

// DESCRIPTION:  find the last test report
function findDevSn (xdevSn) {
  for(let idx=0; idx < TblTestInfo.length; idx++) {
    if (TblTestInfo[idx].sn === xdevSn) return TblTestInfo[idx];
  } 
  /* TblTestInfo.forEach((vobj) => {
    if (vobj.Sn === xdevSn) return vobj;
  }); */
  return null;
}

// DESCRIPTION:  find all reports with the same devSn
function findAllDevSn (xdevSn) {
  let ary = [];
  for(let idx=0; idx < TblTestInfo.length; idx++) {
    if (TblTestInfo[idx].sn === xdevSn) ary.push(TblTestInfo[idx]);
  } 
  return ary;
}

function calcBattLevel (xdevSn) {
  let vobj = findDevSn(xdevSn);
  if (vobj !== null) {
    if (vobj.batTotalCapacity.result <= 0) return 0;
    return (vobj.batResidualCapacity.result * 100)/vobj.batTotalCapacity.result;
  }
  return -1; // devSn not found
}


// =============================================================================================
//             Export
// =============================================================================================

export { 
  TblTestInfo, findDevSn, findAllDevSn, calcBattLevel, createTestReportSn
};

