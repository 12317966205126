<!-- --------------------------------------------------------------------------------
                      DialogAbout.vue

DESCRIPTIONS
  - Display Messages:  Company Info, link to AED official website

USAGE:
  <DialogAbout :message="sMessage" :bActivate="bAct" @DialogClose="onMsgClose"/>
  open:    bAct = true
  close:   at onMsgClose() { bAct=false }
---------------------------------------------------------------------------------- -->
<template>
  <div class="text-center">
    <v-dialog v-model="bDialog" width="600px" height="640px" color="transparent">

      <v-card  class="ma-0 rounded-xl elevated-5 overflow-hidden">
        
        <v-card-title class="ma-0 pa-0 justify-center text-h6 white--text fontArialB" style="background-color: #546E7A;">About
          <v-btn icon small color="white" class="mt-1 fontArialN" @click="onBtnClose" style="position: absolute; right: 0;">
            <svg-icon type="mdi" :path="pathClose"></svg-icon></v-btn>          
        </v-card-title>

        <v-img :width="286"  :src="companyIcon" class="mt-2 mx-2" @click="onClickImg"></v-img>

        <p class="mx-2 mt-5 pa-0 text-caption fontArialN">Medisource Supply (Asia) Co. Ltd. is a Hong Kong company founded in 2003. We are supplying various health care products, medical consumables and devices to local clinics, hospitals, associations, NGOs, etc.</p>

        <v-sheet class="ml-2 mt-10 mb-2 pa-0 fontArialB">INFORMATION</v-sheet>

        <v-sheet width="100%" class="mx-0 mt-5 pa-1 rounded-0 elevation-0">
          <v-row width="100%" class="my-0 ml-4">
            <v-col cols="1" class="ml-0 pa-0 my-auto"><svg-icon type="mdi" :path="pathPhone"></svg-icon></v-col>
            <v-col cols="6" class="pa-0 ma-0 fontArialN">Tel: {{ aboutTel }}</v-col>
          </v-row>
          <v-row class="my-0 ml-4">
            <v-col cols="1" class="ml-0 pa-0 my-auto"><svg-icon type="mdi" :path="pathFax"></svg-icon></v-col>
            <v-col cols="6" class="pa-0 ma-0 fontArialN">Fax:  {{ aboutFax }}</v-col>
          </v-row>
          <v-row class="my-0 ml-4">
            <v-col cols="1" class="ml-0 pa-0 my-auto"><svg-icon type="mdi" :path="pathEmail"></svg-icon></v-col>
            <v-col cols="6" class="pa-0 ma-0 fontArialN">Email: {{ aboutEmail }}</v-col>
          </v-row>
          <v-row class="my-0 ml-4">
            <v-col cols="1" class="ml-0 pa-0 my-auto"><svg-icon type="mdi" :path="pathMapMarker"></svg-icon></v-col>
            <v-col cols="10" class="pa-0 ma-0 fontArialN">Address:  {{ aboutAddress }}</v-col>
          </v-row>
          <v-row class="my-0 ml-4">
            <v-col cols="1" class="ml-0 pa-0 my-auto"><svg-icon type="mdi" :path="pathFacebook"></svg-icon></v-col>
            <!-- <v-col cols="6" class="pa-0 ma-0">Facebook: medisource</v-col>  -->
            <v-col cols="6" class="pa-0 ma-0">
              <!-- <v-btn text href="https://www.facebook.com/medisource" target="_blank" class="ml-0 pl-0 text-capitalize">Facebook: medisource</v-btn>  -->
              <v-btn text href="https://www.facebook.com/medisource" target="_blank" class="ml-0 pl-0 text-capitalize blue--text fontArialN">Facebook: medisource</v-btn>
            </v-col>
          </v-row>
        </v-sheet>

        <v-row class="mt-5">
        <v-col cols="12">
        <v-card class="ma-0 pa-0 transparent elevation-0" align="center">
          <v-btn text href="https://www.hk-aed.com/" target="_blank" 
          class="ma-0 pa-0 text-caption blue--text text-lowercase fontArialN">{{ sLinks }}</v-btn>
        </v-card>
        </v-col>
        </v-row>

        <v-row v-if="isInfo===true" class="mt-0 pa-0">
          <v-col cols="12">
            <v-sheet class="ma-0 pa-0 text-caption black--text text-lowercase fontArialN" align="center">{{ sysInfo }}</v-sheet>
          </v-col>
        </v-row>

      </v-card>

      <!-- <div width="98%" height="500px" class="transparent">
        <v-card width="99%" height="40px" class="d-flex flex-row ma-0 py-auto rounded-t-lg rounded-b-0 transparent">
          <v-sheet width="100%" height="100%" class="my-auto text-center text-h6 grey lighten-2">  {{title}}</v-sheet>
          <v-btn icon x-small color="black" class="mt-1" @click="onBtnClose" style="position: absolute; right: 0;">
            <v-icon>mdi-close</v-icon></v-btn>
        </v-card>

        <v-divider></v-divider>

        <v-card width="99%" height="100%" class="ml-0 mt-0 py-1 border-0 elevation-0 rounded-t-0 roundded-b-lg">
          <v-card class="pa-0 ma-1 elevation-0" height="23px" v-for="data, i in Object.entries(messages)" :key="i">
            <v-row class="pl-2 py-auto ma-0 d-flex black--text cText" style="border-bottom: 1px solid #E0E0E0;">
              <v-col cols="5" class="pa-0 ma-0">{{ data[0] }}</v-col>
              <v-col cols="7" class="pa-0 ma-0">{{ data[1] }}</v-col>
            </v-row>
          </v-card>
        </v-card>  
      </div>  -->

    </v-dialog>
  </div>
</template>

<script>
import SvgIcon from '@jamescoyle/vue-icon';
import { mdiPhone, mdiFax, mdiEmail, mdiMapMarker, mdiFacebook, mdiHome, mdiClose } from '@mdi/js';
import * as Ut from '@/js/ut.js'

  export default {
    name: 'DialogAbout',

    components: {
      SvgIcon
    },

    props: {
      title: {type: String, default: ''},
      messages: {type: Object, default: null},
      pAct: {type: Number, default: 0}
    },

    data () {
      return {
        bDialog: false,
        //companyIcon: require("@/assets/Images/Background/Company Logo2.png"),
        companyIcon: require("@/assets/Images/Background/Company Logo3.png"),
        aboutTel: Ut.SysPara.aboutTel,
        aboutFax: Ut.SysPara.aboutFax,
        aboutEmail: Ut.SysPara.aboutEmail,
        aboutAddress: Ut.SysPara.aboutAddress,
        aboutFacebook: Ut.SysPara.aboutFacebook,
        pathPhone: mdiPhone,
        pathFax: mdiFax,
        pathEmail: mdiEmail,
        pathMapMarker: mdiMapMarker,
        pathFacebook: mdiFacebook,
        pathHome: mdiHome,
        pathClose: mdiClose,
        sysInfo: "",
        isInfo: false,
        sLinks: "for more products visit: https://www.hk-aed.com/",
        clickCount: 0
      }
    },

    methods: {
      onBtnClose () {
        // console.log('DialogKeyValue','onBtnClose');
        this.$emit('DialogKeyValueClose');
        this.isInfo = false;
        this.bDialog = false;
      },

      onClickOutside () {
        this.onBtnClose();
      },

      onClickImg (){
        this.clickCount++;
        if (this.clickCount >= 10) {
          this.isInfo = true;
          this.clickCount = 0;
        }
      }
    },

    watch: {
      pAct (newVal) {
        this.bDialog = true; 
        this.sysInfo = "";
        for (const [key, value] of Object.entries(this.messages)) {
          //console.log(`${key}: ${value}`);
          this.sysInfo += `${key}: ${value}` + "    ";
        }
      },

      bDialog: function(newVal) {
        if (!newVal) {
          this.$emit('DialogKeyValueClose');
        }
      }
    }

  }
</script>

<style scoped>

.fontArialN {
  font-family: Arial; 
  font-style: normal; 
  font-weight: normal;   
  color: black;
}

.fontArialB {
  font-family: Arial; 
  font-style: normal; 
  font-weight: bold;   
  color: black;
}


.cBorder {
  border: 1px solid grey;
}
</style>
