<template>
  <div id="idBackGnd" width="100%" min-width="900px" class="d-flex" style="margin: 0; padding: 0; 
  background-color: #161616; height: calc(100vh - 80px);">

    <!----------------------- Left Pane ----------------------------->
    <v-container color="transparent" style="width: 65%;">
      <v-sheet width="99%" height="90px" class="ma-0 d-flex transparent" >
        <!-- <DevStatistics title="Total" message="12" :width="statW" :height="statH" class="ml-1 mt-1" @onClickCard="onClickTotal"/>
        <DevStatistics title="Normal" message="8" :width="statW" :height="statH" class="ml-1 mt-1" @onClickCard="onClickNormal"/>  -->
        <DevStatistics title="Fault" :value="alarmStatus.fault"  :unit="alarmUnit.fault" :size="statSize" class="ml-2 mt-2" @onClickCard="onClickFault"/>
        <DevStatistics title="Warning" :value="alarmStatus.warn" :unit="alarmUnit.warn"  :size="statSize" class="ml-2 mt-2" @onClickCard="onClickWarning"/>
        <DevStatistics title="Offline" :value="alarmStatus.offline" :unit="alarmUnit.offline" :size="statSize" class="ml-2 mt-2" @onClickCard="onClickOffline"/>
        <DevStatistics title="Rescue" :value="alarmRescue.value" :unit="alarmRescue.unit" :size="statSize" class="ml-2 mt-2" @onClickCard="onClickRescue"/>
      </v-sheet>

      <v-sheet width="100%" class="ma-0 pa-0 d-flex transparent">
        <v-sheet class="ml-8 mt-0 transparent fontArialB">Warranty</v-sheet>
        <v-spacer></v-spacer>
        <v-progress-circular v-if="showProgress" color="blue" :size="30" indeterminate class="mt-1"></v-progress-circular>
        <v-btn x-small class="mt-1 blue--text transparent text-capitalize fontArialN" @click="onBtnRefresh">
          <!-- <v-icon color="blue">mdi-reload</v-icon>Refresh</v-btn>  -->
          <svg-icon type="mdi" :path="pathReload"></svg-icon>Refresh</v-btn>
      </v-sheet>

      <v-sheet width="100%" min-width="200px" height="90px" class="ml-1 mt-0 d-flex transparent">
        <DevStatus title="Remaining Warranty" message="" :width="statusW" :height="statusH" :levels="aryWarranty" 
          :bgcolor="statusC" class="mt-1 ml-2"/>         
        <DevStatus title="Battery Capacity" message="" :width="statusW" :height="statusH" :levels="aryBatt" 
          :bgcolor="statusC" class="mt-1 ml-2"/>          
        <DevStatus title="Pads Shelf Life" message="" :width="statusW" :height="statusH" :levels="aryPads" 
          :bgcolor="statusC" class="mt-1 mx-2"/>
      </v-sheet>

      <v-sheet width="100%" class="ml-2 mt-15 pa-0 transparent">
        <v-sheet class="ml-8 mt-0 transparent white--text fontArialB">Recent Installation</v-sheet>
      </v-sheet>

      <!-- <div width="99%" class="mx-2 mt-0 pa-1 rounded-xl" style="background-color: #282828; height: calc(100vh - 300px);"> -->
      <div width="99%" class="mx-2 mt-0 pa-1 rounded-xl" style="background-color: #282828; height: calc(100vh - 380px);">  
        <v-sheet class="mx-0 mt-1 d-flex rounded-t-xl cBorder2" style="background-color: #161616;">
          <v-col cols="3"><v-sheet class="white--text transparent">Device SN</v-sheet></v-col>
          <v-col cols="3"><v-sheet class="white--text transparent">Install Date</v-sheet></v-col>
          <v-col cols="7"><v-sheet class="white--text transparent">Install Location</v-sheet></v-col>
        </v-sheet>

        <!-- <v-list id="idList" dense height="300" class="rounded-b-xl" style="background-color: #161616; ">  -->
        <!-- <v-list id="idList" dense class="rounded-b-xl" style="background-color: #161616; height: calc(100vh - 385px)">  -->
        <v-list id="idList" dense class="rounded-b-xl" style="background-color: #161616; height: calc(100vh - 450px)">   
          <v-list-item-group>
            <v-list-item  v-for="(item, i) in aeditems2" :key="i" class="pa-0 my-0" 
              @click="getLocation(i, item)" :color="getColor(i)">
              <v-list-item-content class="pa-0 ml-2 cBorder">
                <v-row no-gutters class="d-flex flex-row pa-0 ml-1 my-0">
                  <v-col class="my-0" cols="3"><v-sheet class="my-0 white--text transparent fontArialN">{{ item.devSn }}</v-sheet></v-col>
                  <v-col class="my-0" cols="3"><v-sheet class="my-0 white--text transparent fontArialN">{{ item.InstTime }}</v-sheet></v-col>
                  <v-col v-if="item.deliveryAddress !== ''" class="my-0" cols="6"><v-sheet class="my-0 white--text transparent nowrap fontArialN">{{ item.deliveryAddress }}</v-sheet></v-col>
                  <v-col v-else class="my-0" cols="6"><v-sheet class="my-0 white--text transparent nowrap fontArialN">{{ item.maploc }}</v-sheet></v-col> 
                  <v-divider light></v-divider>
                </v-row>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>

      </div>

    </v-container>

    <!----------------------- Right Pane (Map) ----------------------------->
    <!-- <v-card width="35%" height="auto%" class="mt-2 ml-1 pa-1 rounded-xl" style="background-color: #282828; height: calc(100vh - 145px);">  -->
    <v-container fluid class="mt-2 ml-1 pa-1" style="background-color: #282828; width: 35%;">      

      <v-sheet width="99%" height="35px" class="mt-2 pa-0 d-flex rounded-t-lg" style="background-color: #181818;">
        <v-sheet width="30%" height="100%" class="ml-2 transparent white--text text-h6 fontArialN">Search SN</v-sheet>
        <v-sheet width="70%" height="100%" class="ml-2 pa-0 transparent white--text">
          <v-text-field dense dark type="input" regular width="100%" height="20px" v-model="keybuff"
            @keyup="onKeyUp" class="ml-2 mt-1 text-decloration-none transparent fontArialN" 
            :spellcheck="false" prepend-icon="mdi-magnify">
          </v-text-field> 
        </v-sheet>
      </v-sheet>        

      <v-sheet width="99%" height="35px" class="mt-2 pa-0 d-flex" style="background-color: #181818;">
        <v-sheet width="30%" height="100%" class="ml-2 transparent white--text text-h6 fontArialN">Location :</v-sheet>
        <v-sheet width="70%" height="100%" class="ml-2 mt-1 transparent white--text text-body-2 fontArialN">{{ map.location }}</v-sheet>
      </v-sheet>

      <!-- Maps embedded API -->
      <v-sheet width="100%" class="mt-3 pa-0" color="transparent" style="height: calc(100vh - 190px);">
        <iframe v-if="showMap" ref="refMap" width="99%" height="100%" frameborder="1" style="background-color: #37474F;" 
          referrerpolicy="no-referrer-when-downgrade" class="rounded-xl">
        </iframe>
      </v-sheet>       

    </v-container>

    <v-snackbar right v-model="snackBar.on" :color="snackBar.color">{{ snackBar.message }}</v-snackbar>

  </div>
</template>

<script>
import axios from 'axios'
import moment from 'moment'
import cookies from '@/js/Cookie.js'
import DevStatistics from '@/components/HomePage/DevStatistics'
import DevStatus from '@/components/HomePage/DevStatus'
import PlotInstall from '@/components/HomePage/PlotInstall'
import * as Tbl from '@/js/tables.js'
import * as Ut from '@/js/ut.js'
import * as Samples from '@/js/samples/data.js'
import * as TestInfo from '@/js/samples/TestInfo.js'
import * as Emergency from '@/js/samples/Emergency.js'

import SvgIcon from '@jamescoyle/vue-icon'
import { mdiReload, mdiMagnify, mdiCheckboxIntermediateVariant } from '@mdi/js';

export default {
  name: 'HomePage',

  components: {
    SvgIcon, DevStatistics, DevStatus, PlotInstall
  },

  data () {
    return {
      pathReload: mdiReload,
      pathMagnify: mdiMagnify,
      // this.$config.apiBaseUrl
      //axiosPathGetDevInfoV1: '/client/api/v1/device',
      axiosPathGetDevInfoV1: '/client/api/v1/aedDeviceRecord',
      axiosPathLatestTestReport: '/api/v1/latesttestreports',
      axiosPathLatestEmergency:   '/api/v1/latestemergreports',
      axiosPathTestReports:       '/api/v1/testreports',
      connectionTimeout:  5000,
      axiosLoad: {"aeditems": false, "testrpts": false, "emergrpts": false},
      // aeditems2: this.aeditems,
      aeditems2: [],
      dashbrd: {},
      testrpts: [],
      showMap: true,
      showProgress: false,
      snackBar: {"on": false, "color": "", "message": ""},
      snackTimeout: 3000,
      snackMsgLogout: "Login expired, please login again",
      tmrObj: {},

      statSize: {"width": '25%', "height": "80px"},
      statusW: '49%',
      statusH: '110',
      statusC: '#455A64',
      keybuff: '',
      keybuffMax: 30,
      axiosRetry: 3,

      alarmStatus: {"total": 0, "normal": 0, "fault": 0, "warn": 0, "offline": 0},
      alarmUnit:   {"total": "   ", "normal": "   ", "fault": "   ", "warn": "   ", "offline": "   "},
      alarmRescue: {"text": "Rescue", "value": 0, unit: "   ", limit: 7},
      aryPads: [
        {text:'Expired', value:'', limit: 0}, 
        {text:'≤ 3 months', value:'', limit: 90}, 
        {text:'≤ 12 months', value:'', limit: 365}
      ],
      aryBatt: [
        {text: 'Critical low', value:'', limit:10}, // <= 10%
        {text:'≤ 20%', value:'', limit:20}, 
        {text:'≤ 35%', value:'', limit:35}
      ],
      aryWarranty: [
        {text:'Expired', value:'', limit: 0}, 
        {text:'≤ 1 year', value:'', limit: 365}, 
        {text:'> 1 year', value:'', limit: 365}
      ],
      //aryPadsSt3mon: [],

      // ------- Google Map -----------
      // map modes:  place / view X / directions X / streetview X / search
      mapQueryPrefix: 'https://www.google.com/maps/embed/v1/place?key=' + this.$config.GOOGLE_MAP_API_KEY,
      map: {"search": "", "location": "", "selectedIndex": 0},

      aryX: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
      aryY: [20, 14, 25, 16, 18, 22, 19, 15, 12, 16, 14, 17]
    }
  },

  methods: {
    onClickTotal () {
      //console.log('HomePage-->onClickTotal()');
      this.$router.push('/DevInfo');
    },
    onClickNormal () {
      //console.log('HomePage-->onClickNormal()');
      this.$router.push('/DevInfo');
    },
    onClickFault () { 
      // console.log('HomePage-->onClickFault()');
      this.$router.push({name: "EventManagement", params: {"tab": "Fault", "value": 0}});
    },
    onClickWarning () {
      // console.log('HomePage-->onClickWarning()');
      this.$router.push({name: "EventManagement", params: {"tab": "Warning", "value": 0}});
    },
    onClickOffline () {
      // console.log('HomePage-->onClickOffline()');
      this.$router.push({name: "EventManagement", params: {"tab": "Offline", "value": 0}});
    },
    onClickRescue () {
      // console.log('HomePage-->onClickRescue()');
      this.$router.push({name: "EventManagement", params: {"tab": "Rescue", "value": 0}});
    },

    onBtnRefresh () {
      if (this.showProgress) return;  //not allow to refresh if last refresh not finished
      this.initPage();
    },

    // DESCRIPTION:  set map location, data provided by Calvin's dashboard interface - DeviceList
    // xitem: {devSn, InstTime, deliveryAddress, maploc}         // null, undefined
    getLocation (xidx, xitem) {
      if (xitem.maploc !== null && xitem.maploc !== undefined && xitem.maploc !== '' && xitem.maploc !== '/') {
        this.setMapAddr(xitem.maploc);
        this.map.location = xitem.maploc;
      } else {
        if (xitem.deliveryAddress !== null && xitem.deliveryAddress !== undefined  && xitem.deliveryAddress !== '' && xitem.deliveryAddress !== '/') {
          this.setMapAddr(xitem.deliveryAddress);
          this.map.location = xitem.deliveryAddress;
        } else {
          this.setMapAddr("Hong Kong");
          this.map.location = "";
        }
      }
      this.map.selectedIndex = xidx;
    },

    isFieldEmpty(xstr) {
      if (xstr === undefined || xstr === null || xstr === "" || xstr === "/") {
        return null;
      }
      return xstr;
    },

    getColor (xidx) {
      if (xidx === this.map.selectedIndex) {
        return "yellow";
      }
      return "transparent";
    },

    onMapSearch (xstr) {
      this.map.search = xstr;
    },

    setMapAddr (xaddr) {
      if (xaddr === null) {
        return;
      }
      // this.$refs.refMap.src = this.mapQueryPrefix + "&q=New+York";
      // this.$refs.refMap.src = this.mapQueryPrefix + "&q=22.34167,114.19387";
      // &zoom=22, kai tak 22.33060,114.19954 
      if (this.$refs.refMap !== undefined) {
        // this.$refs.refMap.src = this.mapQueryPrefix + "&q=" + xaddr;
        this.$refs.refMap.src = this.mapQueryPrefix + "&q=" + xaddr;
      }
    },

    clearArray (xary) {
      if (xary.length > 0) {
        xary.splice(0); 
        xary.length = 0;
      }
    },

    snackStart(xmsg, xcolor) {
      if (this.snackBar.on) {
        this.snackStop();
      }
      this.snackBar.message = xmsg;
      this.snackBar.color = xcolor;
      this.snackBar.on = true;
      this.tmrObj = setTimeout(this.snackTimerEvent, this.snackTimeout);
    },

    snackTimerEvent() {
      this.snackBar.on = false;
      this.tmrObj = null;
      if (this.snackBar.message === this.snackMsgLogout) {
          this.snackBar.message = '';
          this.$root.$emit('App_Logout', "115");
      }
      this.snackBar.message = '';
    },

    snackStop() {
      clearTimeout(this.tmrObj);
      this.snackBar.message = '';
      this.snackBar.on = false;
      this.tmrObj = null;
    },

    onKeyUp (evn) {
      if (this.keybuff.length >= this.keybuffMax + 1) {
          this.keybuff = this.keybuff.substring(0, this.keybuffMax);
          return;
      }
      if (evn.keyCode === 13) {
        this.map.search = this.keybuff.trim();
        if (this.map.search.length > 0) {
          for (let idx=0; idx < this.aeditems2.length; idx++) {
            if (this.aeditems2[idx].devSn.toUpperCase() === this.map.search) {
              this.map.selectedIndex = idx;
              if (this.aeditems2[idx].maploc !== null && this.aeditems2[idx].maploc !== "" && this.aeditems2[idx].maploc !== "/") {
                  this.map.location = this.aeditems2[idx].maploc;
                  this.setMapAddr(this.map.location);
              } else if (this.aeditems2[idx].deliveryAddress !== null && this.aeditems2[idx].deliveryAddress !== "" && this.aeditems2[idx].deliveryAddress !== "/") {
                  this.map.location = this.aeditems2[idx].deliveryAddress;
                  this.setMapAddr(this.map.location);
              } else {
                this.map.location = "";
                this.setMapAddr("test1 site");
              }
              return;
            }
          }
        }
        return;
      }
      this.keybuff = this.keybuff.toUpperCase();
    },

    // DESCRIPTION:  expiry date - date now, return <= 0 if expired
    getDateDiff (xdate) {
      return (new Date(xdate) - new Date())/(1000 * 3600 * 24);
    },

    findSnFromDeviceReport (xdevSn) {
      for(let idx=0; idx < this.aeditems.length; idx++) {
        if (this.aeditems[idx].devSn === xdevSn) return this.aeditems[idx];
      } 
      return null;
    },


    // ----------------------------------------------------------------------
    //              [Axios] [axios]
    // 
    // ----------------------------------------------------------------------

    // ---------------------------------------------
    // DESCRIPTION:  Get deivce info, V1 (with token)
    async axiosGetDashboard () {
      let vObj = {
        'method': 'GET',
        'url': this.$config.apiBaseUrl + '/dashboard',
        timeout: 3000,
        'headers': {
          'X-Requested-With': 'XMLHttpRequest',
          'Content-Type': 'application/json',
          // 'Authorization': 'Bearer ' + this.$cookies.get('kk')
          'Authorization': 'Bearer ' + cookies.get('kk')
        }
      }

      try {
        let resp = await axios.request(vObj.url, vObj);
        if (resp != null) {
          let vStatus = resp.data.status;
          if (vStatus === 'SUCCESS') {
            if (resp.data.data !== null) {
              //this.setDashBoard(resp.data.data);
              return resp.data.data;
            } 
          } else {
            console.log('#axiosGetDashboard(1) error: axios data request fail');
          }  
          this.showProgress = false;
        } else {
          console.log('#axiosGetDashboard(2) error: receive null data');
          this.showProgress = false;
        }
      } catch (error) {
        this.showProgress = false;
        console.error('#axiosGetDashboard(3) error: ', error.message);
        if (error.message.indexOf('401') > 0) {
          this.snackStart(this.snackMsgLogout, 'error');
          // this.$root.$emit('App_Logout', "115");
        } else if (error.message.indexOf('Network Error') > 0 || error.message === 'undefined') {
          this.snackStart("Cannot connect to Server", 'error');
        }
      }
      return null;
    },

   // ---------------------------------------------
    // DESCRIPTION:  Latest Test Report 
    async axiosGetTestReportLatest () {    
      let vObj = {
        'method': 'GET',
        'url': this.$config.apiBaseUrl + this.axiosPathLatestTestReport,
        //timeout: this.connectionTimeout,
        'headers': {
          'X-Requested-With': 'XMLHttpRequest',
          'Content-Type': 'application/json',
          //'Authorization': 'Bearer ' + this.$cookies.get('kk')
          'Authorization': 'Bearer ' + cookies.get('kk')
        }
      }

      try {
        let resp = await axios.request(vObj)
        if (resp != null) {
          //alert(JSON.stringify(resp)) // {"data":{status: xxx, message: yyy, data: [{}, {}, ...] }
          let vStatus = resp.data.status;
          if (vStatus === 'SUCCESS') {
            if (resp.data.data !== null) {
              //Ut.copyArray(this.testrpts, resp.data.data);
              //this.processTestReportLatest();
              //if (this.axiosFlags.device === false) this.showProgress = false;
              return resp.data.data;
            }
          } else {
            console.log('#axiosGetTestReportLatest(1) error: axios data request fail');
          }  
        } else {
          console.log('#axiosGetTestReportLatest(2) error: receive null data');
        }
      } catch (error) {
        console.error('#axiosGetTestReportLatest(3) error: ' + error.message);
        if (error.message.indexOf('401') > 0) {
          this.snackStart(this.snackMsgLogout, 'error');
        } else if (error.message.indexOf('Network Error') > 0 || error.message === 'undefined') {
          this.snackStart("Cannot connect to Server", 'error');
        }
      }
      return null;
    },



    // ----------------------------------------------------------------------
    //              [Process]
    // 
    // ----------------------------------------------------------------------

    // ---------------------------------------------
    // DESCRIPTION:  display Dashboard data, after axios 
    async setDashBoard () {
      let str = "";
      //this.dashbrd = JSON.parse(JSON.stringify(xdata));  //copy dashboard data
      
      // debug ------------------------------------------------- debug data
      // (1) PadsShelfLife warning
      /* this.dashbrd.warning.quantity++;
      this.dashbrd.warning.warnSN.push("AE9-44009715");
      this.dashbrd.PadsShelfLife.no.st3mon++;
      this.dashbrd.PadsShelfLife.details.st3mon.push(
          {"sn": "AE9-44009715", "padExpiry": "2024-10-01"}
      );  */ 

      // (2) batt low:  critical / st20 / st35
      /* this.dashbrd.warning.quantity++;
      this.dashbrd.warning.warnSN.push("AE9-44009715");
      this.dashbrd.BatteryCapacity.no.critical++;
      this.dashbrd.BatteryCapacity.details.critical.push( 
        {"sn": "AE9-44009715", "batterySN": "HY86204200086", "batteryPercent": 20}
      ); */

      // (3) Fault:  
      /* this.dashbrd.Fault.faultSN.push("AE9-44009715");
      this.dashbrd.Fault.quantity++;  */

      // (4) Rescue:  
      //this.dashbrd.rescue.rescueSN.push("AE9-44009715");
      //this.dashbrd.rescue.totalRescueTime++;  

      // --------- Top row ----------------
      // (1) Fault alarm
      this.alarmStatus.fault = this.dashbrd.Fault.faultSN.length;
      // (2) Warning alarm
      this.alarmStatus.warn = this.dashbrd.warning.quantity;
      // (3) Offline alarm
      this.alarmStatus.offline = this.dashbrd.Offline.quantity;
      // (4) Rescue alarm
      if (this.dashbrd.rescue.rescueSN !== null) {
        this.alarmRescue.value = this.dashbrd.rescue.rescueSN.length;
      } else {
        this.alarmRescue.value = 0;
      }
      // save warning and fault from Master Record
      Ut.writePadsSt3mon(this.dashbrd.PadsShelfLife.details.st3mon);
      Ut.writePadsExpire(this.dashbrd.PadsShelfLife.details.Expired);
      Ut.writeBatt35(this.dashbrd.BatteryCapacity.details.st35, this.dashbrd.BatteryCapacity.details.st20, this.dashbrd.BatteryCapacity.details.critical);
      Ut.writeAryFault(this.dashbrd.Fault.faultSN);
      Ut.writeAryRescue(this.dashbrd.rescue.rescueSN);
 

      // --------- 2nd row ----------------
      // (1) Remaining Warranty
      str = JSON.stringify(this.dashbrd.remainingWarranty.no);
      str = str.replace("1yrs", "oneyr");
      let vObj = JSON.parse(str);
      this.aryWarranty[0].value = vObj.Expired + " ";
      this.aryWarranty[1].value = vObj.oneyr + " ";
      this.aryWarranty[2].value = vObj.Large1yrs + " ";

      // (2) Battery Capacity
      this.aryBatt[0].value = this.dashbrd.BatteryCapacity.no.critical + " pcs";
      this.aryBatt[1].value = this.dashbrd.BatteryCapacity.no.st20 + " pcs";
      this.aryBatt[2].value = this.dashbrd.BatteryCapacity.no.st35 + " pcs";

      // (3) Pads Shelf Life
      this.aryPads[0].value = this.dashbrd.PadsShelfLife.no.Expired + " ";
      this.aryPads[1].value = this.dashbrd.PadsShelfLife.no.st3mon + " ";
      this.aryPads[2].value = this.dashbrd.PadsShelfLife.no.st12mon + " ";

      // --------- 3rd row ----------------
      Ut.clearArray(this.aeditems2);
      for(let idx=0; idx < this.dashbrd.DeviceList.length; idx++) {

        let vObj = {
          "devSn": this.dashbrd.DeviceList[idx].sn.trim(), 
          "InstTime": moment(this.dashbrd.DeviceList[idx].instTime).format("YYYY-MM-DD"), 
          "deliveryAddress": "", "maploc": ""
        };

        if (vObj.InstTime === "Invalid date") vObj.InstTime = "/";
        str = this.dashbrd.DeviceList[idx].maploc;
        if (str !== null && str !== undefined && str !== "") vObj.maploc = str.trim();
        else vObj.maploc = "/";
        str = this.dashbrd.DeviceList[idx].deliveryAddress
        if (str !== null && str !== undefined && str !== "") vObj.deliveryAddress = str.trim();
        else vObj.deliveryAddress = "/";

        this.aeditems2.push(vObj);
      }

      // go to 1st location after loading
      if (this.aeditems2 !== null && this.aeditems2.length > 0) {
        this.getLocation(0, this.aeditems2[0]);
      }

    },

    // ---------------------------------------------
    // DESCRIPTION:  check if testinfo - padsLife & batt level should overwrite dashboard data
    async processTestReportLatest () {
      let vT = {};
      let expireDate="", sPads="", sn="";
      let expired=0, battTot=0, battRes=0, battPerc=0;     

      for(let idx=0; idx < this.testrpts.length; idx++) {
        vT = this.testrpts[idx];
        sn = vT.sn;

        //(A) Testinfo-Pads Expiry Date
        expireDate = vT.testitems.padsExpiringDate.result;
        expired = vT.testitems.padsExpiried.result;         
        if (expireDate === null || expireDate === "") continue; //expiry Date absent
        sPads = Ut.getPadsStatus(expired, expireDate); //"3", "12", "Expired", "Normal"

        //(B) Testinfo-Battery Level
        battPerc = 0;
        battTot = vT.testitems.batTotalCapacity.result;
        battRes = vT.testitems.batResidualCapacity.result;
        if (battTot > 0) {
          battPerc = Math.floor((battRes * 100)/battTot);
          battPerc = Math.abs(battPerc);
        }

        //(C) Dashboard-PadsShelfLife
        if (this.dashbrd.PadsShelfLife.no.Expired > 0) {  //(C1) pads Expired
          for(let idx=0; idx < this.dashbrd.PadsShelfLife.details.Expired.length; idx++) {
           
            if (sn === this.dashbrd.PadsShelfLife.details.Expired[idx].sn) {
              if (sPads !== "Expired") {
                if (sPads === "3") {
                  this.dashbrd.PadsShelfLife.no.st3mon++;
                  this.dashbrd.PadsShelfLife.details.st3mon.push(this.dashbrd.PadsShelfLife.details.Expired[idx]);
                } else if (sPads === "12") {
                  this.dashbrd.PadsShelfLife.no.st12mon++;
                  this.dashbrd.PadsShelfLife.details.st12mon.push(this.dashbrd.PadsShelfLife.details.Expired[idx]);                  
                }
                this.dashbrd.PadsShelfLife.no.Expired--;
                this.dashbrd.PadsShelfLife.details.Expired[idx].sn = "delete";
              }
            }
          }
        }
        if (this.dashbrd.PadsShelfLife.no.st3mon > 0) {  //(C2) pads st3mon
          for(let idx=0; idx < this.dashbrd.PadsShelfLife.details.st3mon.length; idx++) {
            if (sn === this.dashbrd.PadsShelfLife.details.st3mon[idx].sn) {
              if (sPads !== "3") {
                if (sPads === "Expired") {
                  this.dashbrd.PadsShelfLife.no.Expired++;
                  this.dashbrd.PadsShelfLife.details.Expired.push(this.dashbrd.PadsShelfLife.details.st3mon[idx]);
                } else if (sPads === "12") {
                  this.dashbrd.PadsShelfLife.no.st12mon++;
                  this.dashbrd.PadsShelfLife.details.st12mon.push(this.dashbrd.PadsShelfLife.details.st3mon[idx]);                  
                }                
                this.dashbrd.PadsShelfLife.no.st3mon--;
                this.dashbrd.PadsShelfLife.details.st3mon[idx].sn = "delete";
              }
            }
          }          
        }
        if (this.dashbrd.PadsShelfLife.no.st12mon > 0) {  //(C3) pads st12mon
          for(let idx=0; idx < this.dashbrd.PadsShelfLife.details.st12mon.length; idx++) {
            if (sn === this.dashbrd.PadsShelfLife.details.st12mon[idx].sn) {
              if (sPads !== "12") {
                if (sPads === "Expired") {
                  this.dashbrd.PadsShelfLife.no.Expired++;
                  this.dashbrd.PadsShelfLife.details.Expired.push(this.dashbrd.PadsShelfLife.details.st12mon[idx]);
                } else if (sPads === "3") {
                  this.dashbrd.PadsShelfLife.no.st3mon++;
                  this.dashbrd.PadsShelfLife.details.st3mon.push(this.dashbrd.PadsShelfLife.details.st12mon[idx]);                  
                }                 
                this.dashbrd.PadsShelfLife.no.st12mon--;
                this.dashbrd.PadsShelfLife.details.st12mon[idx].sn = "delete";
              }
            }
          } 
        }
        this.dashbrd.PadsShelfLife.details.Expired = this.dashbrd.PadsShelfLife.details.Expired.filter(e => e.sn !== 'delete'); 
        this.dashbrd.PadsShelfLife.details.st3mon = this.dashbrd.PadsShelfLife.details.st3mon.filter(e => e.sn !== 'delete'); 
        this.dashbrd.PadsShelfLife.details.st12mon = this.dashbrd.PadsShelfLife.details.st12mon.filter(e => e.sn !== 'delete'); 
        // console.log("##chk111", this.dashbrd.PadsShelfLife.details.Expired);

        let batt_critical = 10;
        let batt_st20 = 20;
        let batt_st35 = 35;
        //(D) Dashboard-Battery
        if (this.dashbrd.BatteryCapacity.no.critical > 0) {  //(D1) batt critical
          for(let idx=0; idx < this.dashbrd.BatteryCapacity.details.critical.length; idx++) {
            if (sn === this.dashbrd.BatteryCapacity.details.critical[idx].sn) {
              if (battPerc > batt_critical) {
                if (battPerc > batt_critical && battPerc <= batt_st20) {
                  this.dashbrd.BatteryCapacity.no.st20++;
                  this.dashbrd.BatteryCapacity.details.st20.push(this.dashbrd.BatteryCapacity.details.critical[idx]);
                } else if (battPerc <= batt_st35) {
                  this.dashbrd.BatteryCapacity.no.st35++;
                  this.dashbrd.BatteryCapacity.details.st35.push(this.dashbrd.BatteryCapacity.details.critical[idx]);
                }
                this.dashbrd.BatteryCapacity.no.critical--;
                this.dashbrd.BatteryCapacity.details.critical[idx].sn = "delete";
              }
            }
          }
        }
        if (this.dashbrd.BatteryCapacity.no.st20 > 0) {  //(D1) batt != 20
          for(let idx=0; idx < this.dashbrd.BatteryCapacity.details.st20.length; idx++) {
            if (sn === this.dashbrd.BatteryCapacity.details.st20[idx].sn) {
              if (battPerc <= batt_critical || (battPerc > batt_st20 && battPerc <= batt_st35)) {
                if (battPerc <= batt_critical) {
                  this.dashbrd.BatteryCapacity.no.critical++;
                  this.dashbrd.BatteryCapacity.details.critical.push(this.dashbrd.BatteryCapacity.details.st20[idx]);                  
                } else if (battPerc > batt_st20 && battPerc <= batt_st35) {
                  this.dashbrd.BatteryCapacity.no.st35++;
                  this.dashbrd.BatteryCapacity.details.st35.push(this.dashbrd.BatteryCapacity.details.st20[idx]);                    
                }
                this.dashbrd.BatteryCapacity.no.st20--;
                this.dashbrd.BatteryCapacity.details.st20[idx].sn = "delete";
              }
            }
          }
        }
        if (this.dashbrd.BatteryCapacity.no.st35 > 0) {  //(D2) batt != 35
          for(let idx=0; idx < this.dashbrd.BatteryCapacity.details.st35.length; idx++) {
            if (sn === this.dashbrd.BatteryCapacity.details.st35[idx].sn) {
              if (battPerc <= batt_st20 || battPerc > batt_st35) {
                if (battPerc <= batt_critical) {
                  this.dashbrd.BatteryCapacity.no.batt_critical++;
                  this.dashbrd.BatteryCapacity.details.critical.push(this.dashbrd.BatteryCapacity.details.st35[idx]);                    
                }
                else if (battPerc <= batt_st20) {
                  this.dashbrd.BatteryCapacity.no.st20++;
                  this.dashbrd.BatteryCapacity.details.st20.push(this.dashbrd.BatteryCapacity.details.st35[idx]);                    
                }
                this.dashbrd.BatteryCapacity.no.st35--;
                this.dashbrd.BatteryCapacity.details.st35[idx].sn = "delete";
              }
            }
          }
        }
        this.dashbrd.BatteryCapacity.details.critical = this.dashbrd.BatteryCapacity.details.critical.filter(e => e.sn !== 'delete'); 
        this.dashbrd.BatteryCapacity.details.st20 = this.dashbrd.BatteryCapacity.details.st20.filter(e => e.sn !== 'delete'); 
        this.dashbrd.BatteryCapacity.details.st35 = this.dashbrd.BatteryCapacity.details.st35.filter(e => e.sn !== 'delete'); 

      } // end of for()

    },

    // DESCRIPTION:  search sn in testinfo and return its object
    searchSnFromTestInfo (xSn) {
      if (xSn === null || xSn === "") return null;
      for(let idx=0; idx < this.testrpts.length; idx++) {
        if (xSn === this.testrpts[idx].sn) return this.testrpts[idx];
      }
      return null;  //not found
    },

    // ---------------------------------------------
    // DESCRIPTION: reload Home Page
    initPage () {
      //this.setMapAddr('Medisource-Asia');   
      this.showProgress = true;

      //this.axiosGetDashboard();   
      this.axiosGetDashboard().then(xdata1 => {
        if (xdata1 !== null) {
          //this.dashbrd = JSON.parse(JSON.stringify(xdata1)); //copy dashboard data
          this.dashbrd = xdata1;
            //this.setDashBoard(xdata1);

            this.axiosGetTestReportLatest().then(xdata2 => {
              if (xdata2 !== null && xdata2.length > 0) {
                  Ut.copyArray(this.testrpts, xdata2);
                  //this.processTestReportLatest();
                  this.setDashBoard();
                  this.showProgress = false;
                }
              }).catch((err2) => {
                console.log("##Dashboard err2", err2);
                this.showProgress = false;
              });
          }
      }).catch((err1) => {
         console.log("##Dashboard err1", err1);
         this.showProgress = false;
      });
    }

  },
    
  mounted () {
    if (cookies.exist("aa") === false) {
      this.$router.push('/');
    }

    this.$root.$emit('App_TopBar', true);
    this.initPage();
  }

}
</script>

<style scoped>

.cTitle {
  font-family: Arial; 
  font-style: normal; 
  font-weight: bold;  
  font-size: 1.2em; 
  color: white;
}

/* HomePage background */
#idBackGnd {
  overflow-y: scroll;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */  
}

#idBackGnd::-webkit-scrollbar {
    display: none;
}


.cBorder {
  border-bottom: 1px solid #383838;
}

.cBorder2 {
  border-bottom: 2px solid #383838;
}


/* ListBox */
.v-list-item {
  min-height: 30px;
  font-size: 1.0em; 
}

#idList {
  /* hide scrollbar in list, scroll by mouse wheel */
  overflow-y: scroll;
  -ms-overflow-style: none;  
  scrollbar-width: none;  
}

#idList::-webkit-scrollbar {
    display: none;
}

/* v-text-field */
.v-text-field >>> .v-input__slot::before  { 
  border-color: blue !important;
}

/* text color */
.v-text-field >>> .v-text-field__slot input {
  color: white;
}

.fontArialN {
  font-family: Arial; 
  font-style: normal; 
  font-weight: normal;   
  color: white;
}

.fontArialB {
  font-family: Arial; 
  font-style: normal; 
  font-weight: bold;   
  color: white;
}

</style>
