n<template>
  <!-- <v-card width="100%" class="ma-0 pa-0" style="background-color: #161616; height: calc(100vh - 150px);"> -->
  <v-card width="100%" class="ma-0 pa-0" style="background-color: #161616;">

    <v-card width="100%" height="100%" class="ma-0 pa-0 d-flex transparent">

      <!------------------ LHS -------------------------------->
      <v-card width="65%" height="90%" class="ma-0 pa-0 transparent">

        <!------------------ 1st row ------------------------>
        <v-card width="100%" class="ma-0 pa-0 d-flex transparent">
          
          <!---------------- BasicInfo ---------------------->
          <v-card width="35%" min-height="370px" height="62vh" class="mx-0 mt-2 pa-0 transparent">
            <DevStatus2BasicInfo title="Basic Info" :width="statusW2" height="100%" :levels="aryBasic"
            :bgcolor="boxBgColor" :DevObj="devObj" class="mt-1 ml-0 transparent"/>
          </v-card>

          <!---------------- Carousel (image) --------------->
          <!-- <v-sheet width="63%" height="380px" class="ml-3 mt-2 pa-0 rounded-xl" :color="boxBgColor"> -->
          <v-sheet  width="63%" min-height="370px" height="62vh" class="ml-3 mt-2 pa-0 rounded-xl" :color="boxBgColor">
            <!-- <v-carousel width="100%" height="100%" class="ma-0 pa-0 rounded-xl">
              <v-carousel-item v-for="(item,i) in aryPic" :key="i" :src="item.src">
              </v-carousel-item>
            </v-carousel> -->
            <v-carousel width="100%" height="100%" class="ma-0 pa-0 rounded-xl">
              <v-sheet v-if="bImageExist"  width="100%" height="100%" class="ma-0 pa-0">
              <v-carousel-item v-for="(item,i) in aryPic" :key="i">
                <v-img :src="item.src" contain height="100%"></v-img>
              </v-carousel-item>
              </v-sheet>
              <v-sheet v-else width="80%" height="80%" class="ma-auto">
                <v-img :src="picNoImage" contain width="100%" height="100%"></v-img>
              </v-sheet>
            </v-carousel>

           <!-- <v-carousel><v-carousel-item :src="vPic" cover></v-carousel-item></v-carousel> -->

           <!-- <v-file-input dense dark accept="image/*" label="Upload PO forcast form" 
            v-model="fileUpload" prepend-icon="mdi-file-upload" @change="onChangeUpload"></v-file-input> -->

            <!-- from Calvin
            <v-file-input label="Upload PO forcast form" outlined dense dark capture="environment"
               accept="image/*" v-model="fileUpload" @change="onChangeUpload"></v-file-input> -->

            <!-- <v-btn x-small width="100%" :color="boxBgColor" class="white--text text-capitalize Rounded" @click="onBtnUpload">
              <v-icon color="blue">mdi-file-upload</v-icon>Upload Image</v-btn>  -->
          </v-sheet>

        </v-card>

        <!------------------ 2nd row ------------------------>
        <v-card width="100%" height="100%" class="mx-0 mt-2 pa-0 transparent d-flex" style="padding-top: 1200px;">
          <DevStatus2 title="Battery Status" :width="statusW" :height="statusH" :levels="aryBatt"
          :bgcolor="boxBgColor" iconType="battery" :iconLevel="iconBattLevel" :iconColor="iconBattColor" class="my-1 ml-1"/>   

          <DevStatus2 title="Pads Status" :width="statusW" :height="statusH" :levels="aryPads"
          :bgcolor="boxBgColor" iconType="generic" :iconLevel="iconPadsLevel" :iconColor="iconPadsColor" class="mt-1 ml-2"/>
          
          <DevStatus2 title="Device Status" :width="statusW" :height="statusH" :levels="aryDev"
          :bgcolor="boxBgColor" iconType="generic" :iconLevel="iconDevStatusLevel" :iconColor="iconDevStatusColor" class="mt-1 ml-2"/>  

          <DevStatus2Nwk :title="netTitle"  :width="statusW" :height="statusH" :levels="aryNetwork"
          :bgcolor="boxBgColor" class="mt-2 ml-2" :iconType="iconNwkType" :iconLevel="iconNwkLevel" 
          :iconColor="iconNwkColor"/>
        </v-card>

      </v-card>

      <!------------------ Map RHS ----------------------------->
      <v-card width="35%" min-height="540px" height="80vh" :color="mapBkColor" class="mt-2 mb-1 pa-1">
        <v-card width="99%" height="35px" :color="mapBkColor" class="mt-1 pa-0 d-flex">
          <v-sheet width="30%" height="100%" :color="mapBkColor" class="ml-2 mt-0 transparent white--text text-subtitle-1">Location :</v-sheet>
          <v-sheet width="70%" height="100%" :color="mapBkColor" class="ml-2 mt-0 transparent white--text text-subtitle-1">{{ map.location }}</v-sheet>
        </v-card>

        <!-- <GoogleMap width="100%" height="100%" class="ma-0" :class="getMapClass" :location="devObj.InstLoc"/> -->
        <GoogleMap width="100%" height="80vh" class="ma-0 rounded-xl" :class="getMapClass" :location="getLocation(devObj)"/>
      </v-card>

    </v-card>
    
  </v-card>
</template>

<script>
import axios from 'axios'
import cookies from '@/js/Cookie.js'
import * as Ut from '@/js/ut.js'
// import devTable from '@/components/Common/DeviceTable.vue'
import GoogleMap from '@/components/Common/GoogleMap'
import DevStatus2 from '@/components/DevInfoPage/DevStatus2'
import DevStatus2Nwk from '@/components/DevInfoPage/DevStatus2Nwk'
import DevStatus2BasicInfo from '@/components/DevInfoPage/DevStatus2BasicInfo'

import SvgIcon from '@jamescoyle/vue-icon';
import { mdiToothbrushElectric } from '@mdi/js';

  export default {
    name: 'TblBasic',

    components: {
      GoogleMap, SvgIcon, DevStatus2, DevStatus2Nwk, DevStatus2BasicInfo
    },

    props: {
      devObj: {type: Object},
      testInfo: {type: Object},
      isTstExist: {type: Boolean, default: false},
      trigger: {type: Number, default: 0}
    },

    data () {
      return {
        statusW: '25%',
        statusH: '150',
        statusW2: '100%',
        statusH2: '400px',
        // boxBgColor: '#455A64',
        boxBgColor: '#282828',
        boxTextColor: 'grey--text text--lighten-4',
        boxIconColor: 'green--text',
        mapBkColor: '#303030',
        colorNormal: 'green--text',
        colorFail: 'red--text',
        fileUpload: {},

        iconBattLevel: 0,
        iconBattColor: "green",
        iconPadsLevel: 0,
        iconPadsColor: 'green',
        iconDevStatusLevel: 0,
        iconDevStatusColor: 'green',
        iconNwkType: 0,
        iconNwkLevel: 0,
        iconNwkColor: "green",

        // last row, set text="message", "value" displayed as a long message
        aryBasicLast: "Last PM",
        aryBasic: [
          {text: "Serial #", value: this.devObj.devSn, valueColor: 'white--text'},
          {text: "Model", value: this.devObj.devModel, valueColor:  'white--text'},
          {text: "Location", value: this.devObj.InstLoc, valueColor:  'white--text'},
          {text: "Install Date", value: this.devObj.InstTime, valueColor:  'white--text'},
          {text: "", value: "", valueColor: 'transparent'},
          {text: "Customer", value: this.devObj.CustName, valueColor:  'white--text'},
          {text: "Battery SN", value: this.devObj.battSn, valueColor:  'white--text'},
          {text: "Last PM", value: "", valueColor:  'white--text'}
        ],        
        aryDev: [{text:'Status', value: '', valueColor:  'white--text'},
            {text:'Error', value: '', valueColor:  'red--text'}
        ],
        aryBatt: [{text:'Status', value: '', valueColor:  this.boxTextColor}, 
            {text:'Remaining Capacity', value: '', valueColor:  this.boxTextColor}
        ],
        aryPads: [{text:'Status', value: '', valueColor:  this.boxTextColor},
            {text:'Expiry Date', value: '', valueColor:  this.boxTextColor}
        ],
        aryLoc: [{text:'Status', value: '', valueColor:  this.boxTextColor}
        ],
        aryNetwork: [{text:'Level', value: '', valueColor:  this.boxTextColor}, 
            {text:'Type', value: "", valueColor:  this.boxTextColor}
        ],                
        
        devStatusIcon: {icon: 'mdi-check-circle', color:  this.colorNormal},
        battIcon: {icon: 'mdi-battery-charging', color:  this.colorNormal},
        padsIcon: {icon: 'mdi-check-circle', color:  this.colorNormal},
        locIcon: {icon: 'mdi-map-marker-outline', color:  this.colorNormal},
        nwkIcon: {icon: 'mdi-signal', color:  'red'},


        netTitle: 'Network Status',

        map: {"location": this.devObj.InstLoc},
 
        picNoImage: require("@/assets/Images/App/No_Image.png"),
        bImageExist: false,
        bImageExist2: false,
        image: '',
        // imageD1: require("@/assets/Images/Models/D1.jpg"),
        /* aryPic: [
          {src: require("@/assets/Images/Installation/pic001.jpg")},
          {src: require("@/assets/Images/Installation/pic002.jpg")},
        ] */
        vPic: 'https://cdn.vuetifyjs.com/images/cards/docks.jpg',
        aryPic: []
      }
    },

    methods: {

      // DESCRIPTION:  handler of pic upload button
      onBtnUpload () {

      },

      onChangeUpload () {

      },

      getMapClass() {
        //console.log("#getMapClass", this.devObj.InstLoc);
        if (this.devObj.InstLoc === "/" || this.devObj.InstLoc === "") {
          return "d-none";
        }
        return "";
      },

      // DESCRIPTION:  for map
      // return MapLoc --> InstLoc --> DeliveryAddr --> CustName  (Address Obsoleted)
      getLocation (xitem) {
        /*let bFlag = false;
        if (xitem.Address !== undefined && xitem.Address !== null && xitem.Address !== '' && xitem.Address !== '/') bFlag = true;
        if (xitem.InstLoc !== undefined && xitem.InstLoc !== null && xitem.InstLoc !== '' && xitem.InstLoc !== '/') {
          if (bFlag) this.map.location = xitem.Address;
          else this.map.location = xitem.DeliveryAddr;          
          return xitem.InstLoc;
        } else if (xitem.DeliveryAddr !== undefined && xitem.DeliveryAddr !== null && xitem.DeliveryAddr !== '' && xitem.DeliveryAddr !== '/') {
          if (bFlag) this.map.location = xitem.Address;
          else this.map.location = xitem.DeliveryAddr;  
          return xitem.DeliveryAddr;
        } else if (bFlag) {
          this.map.location = xitem.Address;
          return xitem.Address;
        }
        this.map.location = xitem.CustName;  
        return xitem.CustName; */

        let bFlag = false;
        if (xitem.DeliveryAddr !== undefined && xitem.DeliveryAddr !== null && xitem.DeliveryAddr !== '' && xitem.DeliveryAddr !== '/') bFlag=true;
        if (xitem.MapLoc !== undefined && xitem.MapLoc !== null && xitem.MapLoc !== '' && xitem.MapLoc !== '/') {
          if (bFlag) this.map.location = xitem.DeliveryAddr;
          else this.map.location = xitem.InstLoc;          
          return xitem.MapLoc;
        } else if (xitem.InstLoc !== undefined && xitem.InstLoc !== null && xitem.InstLoc !== '' && xitem.InstLoc !== '/') {
          if (bFlag) this.map.location = xitem.DeliveryAddr;
          else this.map.location = xitem.InstLoc;  
          return xitem.InstLoc;
        } else if (bFlag) {
          this.map.location = xitem.DeliveryAddr; 
          return xitem.DeliveryAddr;
        }
        if (this.map.location === "") this.map.location = xitem.CustName;
        return xitem.CustName;
      },

      // DESCRIPTION:  for aryBasic[] only
      getLocation2(xitem) {
        let bFlag = false;
        if (xitem.DeliveryAddr !== undefined && xitem.DeliveryAddr !== null && xitem.DeliveryAddr !== '' && xitem.DeliveryAddr !== '/') bFlag=true;
        if (xitem.MapLoc !== undefined && xitem.MapLoc !== null && xitem.MapLoc !== '' && xitem.MapLoc !== '/') {      
          return xitem.MapLoc;
        } else if (xitem.InstLoc !== undefined && xitem.InstLoc !== null && xitem.InstLoc !== '' && xitem.InstLoc !== '/') {
          return xitem.InstLoc;
        } else if (bFlag) {
          return xitem.DeliveryAddr;
        }
        return "";
      },

      getImage (xmodel) {
        if (xmodel === 'BeneHeart D1') return this.imageD1;
        if (xmodel === 'BeneHeart C1') return this.imageC1;
        if (xmodel === 'BeneHeart C2A') return this.imageC2A;
        if (xmodel === 'BeneHeart C2 Fully automatic') return this.imageC2auto;
        return this.imageD1;
      },

      // DESCRIPTION:  expiry date - date now, return <= 0 if expired
      getDateDiff (xdate) {
        return (new Date(xdate) - new Date())/(1000 * 3600 * 24);
      },

      setIcon (xIcon, xtype, xcolor) {
        xIcon.icon = xtype;
        xIcon.color = xcolor;
      },

      // ---------------------------------------------
       // DESCRIPTION:  download picture and stored into array aryPic[]
      async axiosGetImage (xPic) {
        let config = {
            // url: 'http://192.168.253.199:7003/uploads/aedImg/C1_20240503030445.jpeg',
            url:  this.$config.apiBaseUrl + '/' + xPic,
            method: 'GET',
            responseType: 'blob',
            'headers': {
              'Authorization': 'Bearer ' + cookies.get('kk')
            }
        }

        axios(config)
          .then((response) => {
              let reader = new FileReader();
              reader.readAsDataURL(response.data); 
              reader.onload = () => {
                  //this.vPic = reader.result;
                  this.aryPic.push({src: reader.result});
              }
          });
      },

      // ---------------------------------------------
      // DESCRIPTION:  check battery status
      checkBatteryStatus (xlevel) {
        if (xlevel === 0) {
          this.aryBatt[0].value = "Fault";
          this.aryBatt[0].valueColor = "red--text";
          //this.setIcon(this.battIcon, "mdi-battery-alert", "red");
          this.iconBattLevel = 0;
          this.iconBattColor = "red";
        }        
        else if (xlevel < 10) {
          this.aryBatt[0].value = "Low Battery";
          this.aryBatt[0].valueColor = "red--text";
          //this.setIcon(this.battIcon, "mdi-battery-alert", "red");
          this.iconBattLevel = 1;
          this.iconBattColor = "red";
        }
        else if (xlevel < 20) {
          this.aryBatt[0].value = "Low Battery";
          this.aryBatt[0].valueColor = "yellow--text";
          //this.setIcon(this.battIcon, "mdi-battery-10", "yellow");
          this.iconBattLevel = 1;
          this.iconBattColor = "yellow";          
        }
        else if (xlevel < 40) {
          this.aryBatt[0].value = "Normal";
          this.aryBatt[0].valueColor = this.boxTextColor;
          //this.setIcon(this.battIcon, "mdi-battery-20", "green");
          this.iconBattLevel = 2;
          this.iconBattColor = "green";
        }
        else if (xlevel < 60) {
          this.aryBatt[0].value = "Normal";
          this.aryBatt[0].valueColor = this.boxTextColor;
          //this.setIcon(this.battIcon, "mdi-battery-40", "green");
          this.iconBattLevel = 3;
          this.iconBattColor = "green";          
        }
        else if (xlevel < 80) {
          this.aryBatt[0].value = "Normal";
          this.aryBatt[0].valueColor = this.boxTextColor;
          //this.setIcon(this.battIcon, "mdi-battery-60", "green");
          this.iconBattLevel = 4;
          this.iconBattColor = "green";          
        }
        else if (xlevel == 100) {
          this.aryBatt[0].value = "Normal";
          this.aryBatt[0].valueColor = this.boxTextColor;
          //this.setIcon(this.battIcon, "mdi-battery", "green");
          this.iconBattLevel = 5;
          this.iconBattColor = "green";          
        }
        else {
          //this.setIcon(this.battIcon, "mdi-battery-charging", "green");
          this.iconBattLevel = 0;
          this.iconBattColor = "black";          
        }

        // coherent to Dashboard Limits
        if (xlevel <= Ut.Limits.batt.value2 && xlevel > Ut.Limits.batt.value0) {
            this.aryBatt[0].value = "Low Battery";
            this.aryBatt[0].valueColor = "yellow--text";
        }
      },

      // ---------------------------------------------
      // DESCRIPTION:  display network status & icons, xtype=0 wifi, =1 4G, 2=NB?
      checkNetworkStatus (xlevel, xtype) {
        if (xtype === 1) {
          this.aryNetwork[1].value = "4G";
          this.iconNwkType = 1;
        }
        else if (xtype === 0) {
          this.aryNetwork[1].value = "Wifi";
          this.iconNwkType = 0;
        }
        else {
          //this.netTitle = "Network Status (N/A)";
          this.aryNetwork[1].value = "No Network";
          this.iconNwkType = 2;
        }
        this.aryNetwork[1].valueColor = "white--text";        

        if (xlevel === 0) {
          this.aryNetwork[0].value = "Receiver Fail";
          this.aryNetwork[0].valueColor = "red--text";
          this.iconNwkLevel = 0;
          this.iconNwkColor = "red";
          if (xtype===1) this.iconNwkLevel = 4;
          return 'Fault';
        } 
        else if ((xtype===1 && xlevel <= -111) || (xtype===0 && xlevel <= -90)) {          
          this.aryNetwork[0].value = xlevel.toString() + " dBm";
          this.aryNetwork[0].valueColor = this.boxTextColor;
          this.iconNwkLevel = 0;
          this.iconNwkColor = "red";          
          return 'Normal';
        } 
        else if ((xtype===1 && xlevel <= -103) || (xtype===0 && xlevel <= -80)) {    
          this.aryNetwork[0].value = xlevel.toString() + " dBm";
          this.aryNetwork[0].valueColor = this.boxTextColor;
          this.iconNwkLevel = 1;
          this.iconNwkColor = "green";          
          return 'Normal';
        } 
        else if ((xtype===1 && xlevel <= -85) || (xtype===0 && xlevel <= -67)) {    
          this.aryNetwork[0].value = xlevel.toString() + " dBm";
          this.aryNetwork[0].valueColor = this.boxTextColor;
          this.iconNwkLevel = 2;
          this.iconNwkColor = "green";          
          return 'Normal';          
        } 
        else {
          if (xtype===1 || (xtype===0 && xlevel > -67)) {  
            this.aryNetwork[0].value = xlevel.toString() + " dBm";
            this.aryNetwork[0].valueColor = this.boxTextColor;
            this.iconNwkLevel = 3;
            this.iconNwkColor = "green";
            if (xtype===0 && xlevel >= -30) this.iconNwkLevel = 4;
          }
        }
        return 'Normal';
      },

      //-----------------------------------------------------
      //             [setStatus] called in mounted()
      //-----------------------------------------------------
      setStatus () {
        // devObj, testInfo
        //(3) Battery:    table.js battStatusData = ['All', 'Normal', 'Fault']
        if (this.testInfo === null || !("testitems" in this.testInfo)) {
          //console.log('#TblBasic-setStatus error: testInfo or testitems null');
          /* if ( this.aryBasic.length > 0) {
            let vObj = this.aryBasic[this.aryBasic.length - 1];
          } */
          this.turnOffIcons();
          return;
        }
        let j2 = 0;
        //console.log('#TblBasic-setStatus',this.testInfo );  //debug
        let netType = this.testInfo.netinfo.NetType;     // 0=wifi 1=4G, 2=NB?
        let battTot = this.testInfo.testitems.batTotalCapacity.result;
        let battRes = this.testInfo.testitems.batResidualCapacity.result;
        if (battTot === 0) {
          this.aryBatt[1].value = "0";
          this.aryBatt[0].value = "Fault";
          this.aryBatt[0].valueColor = "red--text";
          this.aryDev[0].value = 'Fault';
        }
        else {
          j2 = Math.floor((battRes * 100)/battTot);
          try {
            this.aryBatt[1].value = j2.toString() + "%";
            this.aryBatt[1].valueColor = this.boxTextColor;
          } catch(err) {
            this.aryBatt[1].value = "";
          }
          this.checkBatteryStatus(j2); 
        }

      //(4) Pads:     table.js PadsStatusData = ['All', 'Normal', 'Expired Soon', 'Expired']
      let expireDate = this.testInfo.testitems.padsExpiringDate.result;
      let expired = this.testInfo.testitems.padsExpiried.result;
        let dateDiff = this.getDateDiff(expireDate);
        //console.log('TblBasic-mount', expireDate, 'dateDiff=', dateDiff);
        this.aryPads[1].value = expireDate;
        this.aryPads[1].valueColor = this.boxTextColor;
        if (expired > 0 || dateDiff <= 0) {
          this.aryPads[0].value = "Expired";
          this.aryPads[0].valueColor = "red--text";
          //this.setIcon(this.padsIcon, "mdi-close-circle", "red");
          this.iconPadsLevel = 0;
          this.iconPadsColor = "red";
        } else {
          if (dateDiff < 30 && dateDiff > 0) {  // 30 days
            this.aryPads[0].value = "Warning";
            this.aryPads[0].valueColor = "red--text";
            this.aryDev[0].value = 'Warning';
            this.aryDev[0].valueColor = 'yellow--text';
            // this.setIcon(this.padsIcon, "mdi-check-circle", "red");
            this.iconPadsLevel = 0;
            this.iconPadsColor = "red";
          } else if (dateDiff < 90 && dateDiff > 0) {  // 90 days
            this.aryPads[0].value = "Warning";
            this.aryPads[0].valueColor = "yellow--text";
            this.aryDev[0].value = 'Warning';
            this.aryDev[0].valueColor = 'yellow--text';
            // this.setIcon(this.padsIcon, "mdi-check-circle", "yellow");
            this.iconPadsLevel = 1;
            this.iconPadsColor = "yellow";
          } else {
            this.aryPads[0].value = "Normal";
            this.aryPads[0].valueColor = this.boxTextColor;
            // this.setIcon(this.padsIcon, "mdi-check-circle", "green");
            this.iconPadsLevel = 1;
            this.iconPadsColor = "green";
          }
        }

        //(5) Location:      Normal or Warning
        this.aryLoc[0].value = "Normal";
        //this.setIcon(this.locIcon, "mdi-map-marker-outline", "green");

        //(6) Network:  TestInfo.js TblTestInfo[objs]
        let nwkLvl = this.testInfo.netinfo.SignalLevel;
        this.checkNetworkStatus(nwkLvl, netType);

        //(2) Device Status     // this.testInfo.testresult? 'Normal':'Fail';   
        //                   table.js DeviceStatusData ['All', 'Normal', 'Fault', 'Warning', 'Offline']
        let errcode = this.testInfo.testerrorcode;
        errcode = errcode.replaceAll("/", "");
        errcode = errcode.trim();
        if (this.testInfo.testresult === 0 || errcode != "") {
          this.aryDev[0].value = "Fault";
          this.aryDev[0].valueColor = "red--text";
          if (errcode != "") {
            this.aryDev[1].text = "Error";
            this.aryDev[1].value = errcode;
            this.aryDev[1].valueColor = this.boxTextColor;
          }
          //this.setIcon(this.devStatusIcon, "mdi-close-circle", "red");
          this.iconDevStatusLevel = 0;
          this.iconDevStatusColor = 'red';
        }
        else {
            this.aryDev[0].value = "Normal";
            this.aryDev[0].valueColor = "white--text";
            this.aryDev[1].text = "";
            this.aryDev[1].value = "";
            this.aryDev[1].valueColor = "white--text";
            this.setIcon(this.devStatusIcon, "mdi-check-circle", "green");
            // this.devStatusIcon.icon = 'mdi-check-circle';
            this.iconDevStatusLevel = 1;
            this.iconDevStatusColor = 'green';            
        }

        //end of setStatus()
      },

      // DESCRIPTION:  
      setErrorMsg() {
        if (this.testInfo !== null && 'errorMessage' in this.testInfo) {
          this.deleteBasicInfo();

          let err = this.testInfo.errorMessage;
          this.aryBasic.push({text:'', value: "", valueColor:  'black--text'});  // empty line
          let vObj = {};
          let str = "";
          for(let idx=0; idx < err.length; idx++) {
            if (idx === 0) str = "Fault";
            else str = "";
            if (Ut.SysConf.language === 'eng') {
              //vObj = {text:'code ' + err[idx].code, value: err[idx].message, valueColor:  'yellow--text'};
              vObj = {text: str, value: err[idx].message, valueColor:  'yellow--text'};
            }
            else {
              //vObj = {text:'code ' + err[idx].code, value: err[idx].chiMessage, valueColor:  'yellow--text'};
              vObj = {text: str, value: err[idx].chiMessage, valueColor:  'yellow--text'};
            }
            this.aryBasic.push(vObj);
          }
        }
      },

      turnOffIcons() {
        this.iconBattLevel = 0;
        this.iconBattColor = 'grey';
        this.iconPadsLevel = 0;
        this.iconPadsColor = 'grey';
        this.iconDevStatusLevel = 0;
        this.iconDevStatusColor = 'grey';
        this.iconNwkLevel = 0;
        this.iconNwkColor = 'grey';

        this.aryBatt[0].value = "N/A"; this.aryBatt[0].valueColor = "grey--text";
        this.aryBatt[1].value = "N/A"; this.aryBatt[1].valueColor = "grey--text";
        this.aryPads[0].value = "N/A"; this.aryPads[0].valueColor = "grey--text";
        this.aryPads[1].value = "N/A"; this.aryPads[1].valueColor = "grey--text";
        this.aryDev[0].value = "N/A"; this.aryDev[0].valueColor = "grey--text";
        this.aryDev[1].value = "N/A"; this.aryDev[1].valueColor = "grey--text";
        this.aryNetwork[0].value = "N/A"; this.aryNetwork[0].valueColor = "grey--text";
        this.aryNetwork[1].value = "N/A"; this.aryNetwork[1].valueColor = "grey--text";                        

        if (!this.searchBasicInfo("Test Report unavailable")) {
          this.aryBasic.push({text:'', value: "", valueColor:  'black--text'});  // empty line
          this.aryBasic.push({text:'', value: "Test Report unavailable", valueColor:  'white--text'});  // empty line
        }
      },

      searchBasicInfo(xValue) {
        if (this.aryBasic === undefined || this.aryBasic === null) return false;
        for(let idx=0; idx < this.aryBasic.length; idx++) {
          if (this.aryBasic[idx].value === xValue) return true;
        }
        return false;
      },

      deleteBasicInfo() {
        if (this.aryBasic === undefined || this.aryBasic === null) return;
        for(let idx=0; idx < this.aryBasic.length; idx++) {
          if (this.aryBasic[idx].text === this.aryBasicLast) {
            this.aryBasic = this.aryBasic.splice(0, idx + 1);
            return;
          }
        }
      }

    },

    mounted () {

      /*   // DONE in Watch
      if (this.testInfo === null || this.testInfo === undefined)  {
        // turn off icons if test report not available
        this.turnOffIcons();        
      } else {
        // set icon levels and display
        this.setStatus();
        this.setErrorMsg();
      } */

        // Load picture to Carousel
      if (this.devObj.Pic !== undefined && this.devObj.Pic !== null) {
        for(let idx=0; idx < this.devObj.Pic.length; idx++) {
          this.axiosGetImage(this.devObj.Pic[idx]);
        }
        if (this.devObj.Pic.length === 0) {
          this.bImageExist = false;
        } else {
          this.bImageExist = true;
        }

      } else {
        this.bImageExist = false;
      }
       
    },

    watch: {
      trigger: function(newVal) {
        if (this.testInfo === null || this.testInfo === undefined)  {
          // turn off icons if test report not available
          this.turnOffIcons();        
        } else {
          // set icon levels and display
          this.setStatus();
          this.setErrorMsg();
          this.aryBasic[2].value = this.getLocation(this.devObj);
        } 
      }
    }

  }
</script>

<style>

</style>
