<!-- --------------------------------------------------------------------------------
                      PlotInstall.vue

DESCRIPTIONS
  - Plot 'Cumulative Installation' vs Month or Year

INSTALL
  - npm install vue-plotify

Parameters:
  - levels = [{text: x1, value: y1}, {text: x2, value: y2}, {text: x3, value: y3}]

---------------------------------------------------------------------------------- -->
<template>
  <v-card id="idCard" width="100%" height="100%" class="pt-0 rounded-lg">
    <plotly :data="data" :layout="layout" :display-mode-bar="false"/>
  </v-card>
</template>

<script>
import { Plotly } from 'vue-plotly'

  export default {
    name: 'PlotInstall',
    components: {
      Plotly
    },

    props: {
      title: {type: String, default: ''},
      message: {type: String, default: ''},
      dataX: {type: Array},
      dataY: {type: Array}
    },

    data () {
      return {
        // ------- Plot -----------
        // https://plotly.com/javascript/bar-charts/
        data: [
          { 
            //x: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
            //y: [20, 14, 25, 16, 18, 22, 19, 15, 12, 16, 14, 17],
            x: this.dataX,
            y: this.dataY,
            type: 'bar',
            name: 'Primary Product',
            textposition: 'auto',
            text: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
            width: 0.7,
            // color: ['blue', 'red', 'blue', ....],
            hoverinfo: 'none',
            marker: {
              color: 'rgb(49,130,189)',
              opacity: 0.9,
            }
          }    
        ],

        layout: {
          autosize: true,
          // barmode: 'stack',
          title: {
            text: "Cumulative Installation",
            font: {color: '#FAFAFA', size: 16},
            automargin: true
          },
          plot_bgcolor: '#455A64',
          paper_bgcolor: '#455A64',
          colorway: ['#42A5F5', 'lime', '#4DD0E1', 'yellow', 'white'],
          // margin: {l: 30, r: 1, b: 30, t: 2},
          margin: {l: 30, r: 20, t: 31},
          showlegend: false,
          legend: {
            orientation: "h",
            font: {color: 'yellow', size: 12},
            bgcolor: '#555555',
            bordercolor: '#666666',
            borderwidth: 1,
            x: 0, y: 18,
            xanchor: 'auto', yanchor: 'auto'
          },
          xaxis: { 
            title: { text: 'date', font: {size: 12, color: 'white'}},
            zeroline: false, 
            autotick: true, 
            color: 'white',
            linecolor: 'white',
            linewidth: 2,
            label: 'month',
          },
          yaxis: { 
            title: { text: 'pcs', font: {size: 12, color: 'white'}},
            zeroline: false, 
            autotick: true, 
            color: 'white',
            gridcolor: 'grey',
            linecolor: 'white',
            linewidth: 2
          }
        },

        config: {
        }
      }
    },

    methods: {
    },

    watch: {
    }

  }
</script>

<style scoped>
.cBorder {
  border: 1px solid grey;
}

#idCard {
  background-image: linear-gradient(to top left, #37474F, #455A64, #546E7A);
}


</style>
