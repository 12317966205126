import { render, staticRenderFns } from "./InputBox2.vue?vue&type=template&id=67d6f5df&scoped=true"
import script from "./InputBox2.vue?vue&type=script&lang=js"
export * from "./InputBox2.vue?vue&type=script&lang=js"
import style0 from "./InputBox2.vue?vue&type=style&index=0&id=67d6f5df&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "67d6f5df",
  null
  
)

export default component.exports